import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Bar} from "react-chartjs-2";
import {FibonacciCardValues} from "../../model/PokerCard";
import PokerTable, {VotingType} from "../../model/PokerTable";
import {PokerTheme} from "../../model/PokerTheme";
import {cardValueAsNumber} from "../Cards/Card";

interface Props {
    table: PokerTable
    pokerTheme: PokerTheme
}

export const ChartComponent: React.FC<Props> = (props) => {
    const {table, pokerTheme} = props

    const barColors = useMemo(() => {
        const barColorsDarkBackground = [
            'rgba(60,248,123, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(224, 69, 69, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(127, 215, 62, 0.6)',
            'rgba(229, 26, 69, 0.6)',
            'rgba(218, 86, 255, 0.6)',
            'rgba(75, 192, 122, 0.6)',
            'rgba(19, 236, 243, 0.6)',
            'rgba(209,19,243,0.6)',
            'rgba(243,142,19,0.6)',
            'rgba(19,243,30,0.6)',
            'rgba(19,94,243,0.6)',
            'rgba(31,150,161,0.6)',
            'rgba(132,7,104,0.6)',
        ]

        const barColorsLightBackground = [
            'rgb(12,219,53)',
            'rgb(14,109,235)',
            'rgb(255,201,38)',
            'rgb(222,18,11)',
            'rgb(104,66,179)',
            'rgb(73,193,11)',
            'rgb(183,5,39)',
            'rgb(170,6,214)',
            'rgb(6,136,67)',
            'rgb(217,7,152)',
            'rgb(217,7,7)',
            'rgb(179,217,7)',
            'rgb(7,217,185)',
            'rgb(46,7,217)',
            'rgb(29,160,119)',
            'rgb(74,2,102)',
        ]
        if (pokerTheme.table.hasLightColoredBackground)
            return barColorsLightBackground
        else
            return barColorsDarkBackground
    }, [pokerTheme])

    const [freeColors, setFreeColors] = useState<string[]>(barColors)
    const [freeCardsToColors, setFreeCardsToColors] = useState(new Map<number, string>([]))

    const getCardCount = useCallback(
        (key) => {
            const valuesWithCount = table.getCardValuesWithCount();
            const count = valuesWithCount.get(key);
            return !count ? 0 : count;
        },
        [table]
    )

    const fibonacciChartData = useMemo(
        () => {
            return FibonacciCardValues.map(value => getCardCount(value));
        },
        [getCardCount]
    )

    const fibonacciData = useMemo(
        () => {
            return {
                labels: ['0', '1', '2', '3', '5', '8', '13', '21', '?', '∞'],
                datasets: [
                    {
                        data: fibonacciChartData,
                        backgroundColor: barColors
                    }
                ]
            };
        },
        [fibonacciChartData, barColors]
    )

    const cardValuesWithCount = useMemo(() => {
        try {
            return (
                [...table.getCardValuesWithCount().entries()]
                    .sort((a, b) => cardValueAsNumber(a[0]) - cardValueAsNumber(b[0]))

            )
        } catch (error) {
            console.log("Chart konnte nicht erzeugt werden. Fehler: " + error?.toString())
        }
    }, [table])

    const freeNumberLabels = useMemo(() => (
        cardValuesWithCount ? cardValuesWithCount.map(entry => +entry[0] / 100) : []
    ), [cardValuesWithCount])

    const freeNumberChartData = useMemo(() => (
        cardValuesWithCount ? cardValuesWithCount.map(entry => entry[1]) : []
    ), [cardValuesWithCount])

    useEffect(() => {
        let colors: string[] = []
        let cardToColor = new Map<number, string>(freeCardsToColors)
        for (const [key, value] of freeCardsToColors) {
            if (!freeNumberLabels.includes(+key)) {
                colors.push(value)
                cardToColor.delete(+key)
            }
        }
        colors = [...freeColors, ...colors]
        freeNumberLabels.forEach(freeNumber => {
            if (!freeCardsToColors.has(freeNumber)) {
                cardToColor.set(freeNumber, colors[0])
                colors.shift()
            }
        })
        setFreeColors(colors)
        //Map Object returns entries in their insertion order, but because the chart needs to display them in ascending order, they need to be sorted first
        setFreeCardsToColors(new Map([...cardToColor.entries()].sort((a, b) => cardValueAsNumber(a[0]) - cardValueAsNumber(b[0]))))
    }, [freeNumberLabels])

    const freeNumberData = useMemo(
        () => {
            return {
                labels: freeNumberLabels,
                datasets: [
                    {
                        data: freeNumberChartData,
                        backgroundColor: [...freeCardsToColors.values()]
                    }
                ]
            };
        },
        [freeNumberLabels, freeNumberChartData, freeCardsToColors]
    )

    const options = useMemo(
        () => {
            return {
                legend: {
                    display: false
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            fontColor: "black",
                            beginAtZero: true,
                            stepSize: 1,
                            callback: function (value: any) {
                                return value >= 10 ? value : `  ${value}`;
                            }
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            fontColor: "black"
                        },
                        maxBarThickness: 40
                    }]

                }
            }
        },
        []
    )

    return (
        <Bar
            data={table.votingType === VotingType.FIBONACCI ? fibonacciData : freeNumberData}
            options={options}
        />
    )
}
