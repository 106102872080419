import {TableState} from "../../../../model/PokerTable";

enum TableCommand {
    REVEAL_CARDS = "REVEAL_CARDS",
    START_VOTING = "START_VOTING",
    FINISH_VOTING = "FINISH_VOTING",
    PLAY_REMINDER = "PLAY_REMINDER",
    TOGGLE_CHANGE_MIND = "TOGGLE_CHANGE_MIND",
    TOGGLE_RANDOMIZE_FREE_VOTE = "TOGGLE_RANDOMIZE_FREE_VOTE",
}

export default class TableCommandRequest {

    tableID: string
    command: string
    adminToken: string

    private constructor(tableID: string, command: string, adminToken: string) {
        this.tableID = tableID;
        this.command = command;
        this.adminToken = adminToken
    }

    static newRevealCardsCommand(tableID: string, adminToken: string) {
        return new TableCommandRequest(tableID, TableCommand.REVEAL_CARDS, adminToken);
    }

    static newUpdateTableStateCommand(tableID: string, state: TableState, adminToken: string): TableCommandRequest {
        return new TableCommandRequest(tableID, tableStateToTableCommand(state), adminToken);
    }

    static newPlayReminderSoundCommand(tableID: string, adminToken: string) {
        return new TableCommandRequest(tableID, TableCommand.PLAY_REMINDER, adminToken);
    }

    static newToggleChangeOpenCard(tableID: string, adminToken: string) {
        return new TableCommandRequest(tableID, TableCommand.TOGGLE_CHANGE_MIND, adminToken);
    }

    static newToggleChangeRandomizeFreeVote(tableID: string, adminToken: string) {
        return new TableCommandRequest(tableID, TableCommand.TOGGLE_RANDOMIZE_FREE_VOTE, adminToken);
    }
}

function tableStateToTableCommand(state: TableState): TableCommand {
    let command: TableCommand
    switch (state) {
        case TableState.VOTING_ENABLED:
            command = TableCommand.START_VOTING;
            break;
        case TableState.VOTING_DISABLED:
            command = TableCommand.FINISH_VOTING;
            break;
        default:
            throw Error("Invalid state!");
    }
    return command;
}
