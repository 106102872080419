export const STATUS_OK = 200;
export const STATUS_CREATED = 201;

export const STATUS_NOTFOUND = 404;

export type Headers = {[key: string]: string}

export function submitPOSTRequest(url: string, data: any, headers?: Headers): Promise<Response> {
    return fetch(url, {
        method: 'POST',
        headers: Object.assign({}, {'Content-Type': 'application/json'}, headers),
        body: JSON.stringify(data)
    });
}

export function submitGETRequest(url: string, headers?: Headers): Promise<Response> {
    return fetch(url, {headers: Object.assign({}, headers)});
}

