import theme from "./defaultTheme.json"
import * as t from "io-ts"
import JSZip from 'jszip';
import {getImageAsDataUrl} from "./PokerThemeArchives";
import {localDefaultTheme, ThemeDefinition} from "../service/ThemeService";


const invalidUrl = "NotAProtocol://|not a theme|"
export const CONFIG_NOT_FOUND_ERROR_MESSAGE = "Error: Config not found"
export const IMAGE_NOT_FOUND_ERROR_MESSAGE = "Error: Image(s) not found"
export const CONFIG_INVALID_ERROR_MESSAGE = "Error: Config invalid"

/*
 * Helper exception. Get's thrown when arguments to a function are not valid.
 */
class InvalidArgumentError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'InvalidArgumentError'
    }
}

/*
 * Helper exception. Get's thrown when the project is not configured correctly.
 */
class InvalidConfigurationError extends Error {
    constructor(message: any) {
        super(message);
        this.name = 'InvalidConfigurationError'
    }
}

/**
 * Helper error message for the Color class to avoid copy pasting it
 */
// const error_message = " has to be between 0.0 and 1.0"

const TColor = t.type({
    _tag: t.literal("Color"),
    red: t.number,
    green: t.number,
    blue: t.number,
    alpha: t.number
})


/**
 * Represents a colour in rgba(r,g,b,a) format with
 * (r,g,b,a) = (red, green, blue, alpha) with 0.0 <= x in {r,g,b,a} <= 1.0
 */
/* function ColorFunction(red: number, green: number, blue: number, alpha: number): Color {
    if (red < 0.0 || red > 1.0) {
        throw new InvalidArgumentError("red" + error_message)
    }
    if (green < 0.0 || red > 1.0) {
        throw new InvalidArgumentError("green" + error_message)
    }
    if (blue < 0.0 || red > 1.0) {
        throw new InvalidArgumentError("blue" + error_message)
    }
    if (alpha < 0.0 || red > 1.0) {
        throw new InvalidArgumentError("alpha" + error_message)
    }

    return {
        _tag: "Color",
        red,
        green,
        blue,
        alpha
    }
} */

export type Color = t.TypeOf<typeof TColor>

/**
 * Conversion function to turn a Color into a CSS style Color tag
 * @param c: Color
 */
export function asCssRGBAString(c: Color) {
    return "rgba(" + c.red * 255 + "," + c.green * 255 + "," + c.blue * 255 + "," + c.alpha + ")"
}

const TUrl = t.type({
    _tag: t.literal("Url"),
    url: t.string
})

/**
 * Represents a URL to a resource, usually an image.
 *
 * Consider to replace / extend this to do more sanity checks over the url link other
 * than just a !== undefined evaluation
 */
export function Url(url: string): Url {
    if (url === undefined)
        throw new InvalidArgumentError("url needs to be specified but got '" + url + "'")

    return {
        _tag: "Url",
        url
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Url = t.TypeOf<typeof TUrl>


const TIconName = t.type({
    _tag: t.literal("IconName"),
    iconName: t.string
})

/**
 * Represents an icon name.
 */
export function IconName(iconName?: string): IconName {
    if (iconName === undefined)
        throw new InvalidArgumentError("iconName needs to be specified but got '" + iconName + "'")

    return {
        _tag: "IconName",
        iconName: iconName
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type IconName = t.TypeOf<typeof TIconName>

const TStroke = t.type({
    _tag: t.literal("Stroke"),
    width: t.number,
    color: t.string
})

/**
 * Represents a stroke for icon.
 */
export function Stroke(width?: number, color?: string): Stroke {
    if (width === undefined || color === undefined) {
        return {
            _tag: "Stroke",
            width: 0,
            color: 'black'
        }
    } else {
        return {
            _tag: "Stroke",
            width: width,
            color: color
        }
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Stroke = t.TypeOf<typeof TStroke>

export function asIconStrokeColor(s: Stroke) {
    return s.color;
}

export function asIconStrokeWidth(s: Stroke) {
    return s.width;
}

/**
 * Conversion function to turn a Url into a CSS style url tag
 * @param url: Url
 */
export function asCssUrl(url: Url) {
    return "url(" + url.url + ")"
}

const TBackground = t.union([TUrl, TColor], "Background")
export type Background = t.TypeOf<typeof TBackground>

const TPokerStatusTheme = t.type({
    _tag: t.literal("PokerStatusTheme"),
    approve: TColor,
    reject: TColor
})
export type PokerStatusTheme = t.TypeOf<typeof TPokerStatusTheme>

const TPokerHeaderTheme = t.type({
    _tag: t.literal("PokerHeaderTheme"),
    background: TColor,
    text: TColor,
    status: TPokerStatusTheme
})
export type PokerHeaderTheme = t.TypeOf<typeof TPokerHeaderTheme>

const TPokerGeneralTheme = t.type({
    _tag: t.literal("PokerGeneralTheme"),
    preview: TUrl,
    background: TBackground,
    header: TPokerHeaderTheme
})
export type PokerGeneralTheme = t.TypeOf<typeof TPokerGeneralTheme>

const TPokerTableTheme = t.type({
    _tag: t.literal("PokerTableTheme"),
    table: TUrl,
    hasLightColoredBackground: t.boolean,
    title: TColor,

    playerIcon: TUrl,
    currentPlayerIcon: TUrl,

    playerName: TColor,
    currentPlayerName: TColor,

    playerNameBackground: TColor,
    currentPlayerNameBackground: TColor,


    // player: TColor,
    // currentPlayer: TColor,
    // currentPlayerIconStroke: TStroke,
    // playerIconStroke: TStroke,
    //playerIconName: TIconName
})
export type PokerTableTheme = t.TypeOf<typeof TPokerTableTheme>

const TPokerPanelTheme = t.type({
    _tag: t.literal("PokerPanelTheme"),
    background: TColor,
    text: TColor,
    button: TColor
})
export type PokerPanelTheme = t.TypeOf<typeof TPokerPanelTheme>

const TPokerCardTheme = t.type({
    _tag: t.literal("PokerCardTheme"),
    backgroundFront: TColor,
    backgroundBack: TColor,
    highlight: TColor,
    highlightSelected: TColor,
    shadow: TColor,
    text: TColor
})
export type PokerCardTheme = t.TypeOf<typeof TPokerCardTheme>

const TPokerTheme = t.type({
    _tag: t.literal("PokerTheme"),
    general: TPokerGeneralTheme,
    table: TPokerTableTheme,
    card: TPokerCardTheme,
    panel: TPokerPanelTheme
})
export type PokerTheme = t.TypeOf<typeof TPokerTheme>

// Abuses the defaulting behavior of the Provider
let defaultPokerThemeURL: Url = Url(invalidUrl)

const eitherTheme = TPokerTheme.decode(theme)
if (eitherTheme._tag === "Left") {
    throw new InvalidConfigurationError(JSON.stringify(eitherTheme.left))
}
export const defaultPokerTheme: PokerTheme = eitherTheme.right

export enum ThemeImageLocations {
    PREVIEW = "Bilder/preview.png",
    BACKGROUND = "Bilder/background.png",
    TABLE = "Bilder/table.png",
    PLAYER_ICON = "Bilder/playerIcon.png",
    CURRENT_PLAYER_ICON = "Bilder/currentPlayerIcon.png"
}

/**
 * Convenience function to generate a themeProvider with a preset theme.
 * Abuses the defaulting behavior of the Provider
 * @param theme: The Theme to preset
 */
//export function pokerThemeProviderFromPokerTheme(theme: PokerTheme): PokerThemeProvider {
//    return new PokerThemeProvider(Url(invalidUrl), theme)
//}

/**
 * A Monad that holds a theme and provides the map(m: (theme: PokerTheme) => any)
 * function.
 *
 * It will default to the "defaultPokerTheme" or a specified alternative in case the
 * themeDefinition is invalid.
 */
export class PokerThemeProvider {
    // @ts-ignore
    private theme: Promise<PokerTheme>

    constructor(themeDefinition: ThemeDefinition, defaultTheme?: ThemeDefinition) {
        if (defaultTheme)
            this.superConstructor(themeDefinition, defaultTheme)
        else
            this.superConstructor(themeDefinition, null)
    }

    private superConstructor(themeDefinition: ThemeDefinition, defaultTheme: ThemeDefinition | null) {
        this.theme = PokerThemeProvider.fetchTheme(themeDefinition)
            .catch(async () => PokerThemeProvider.fetchTheme(defaultTheme === null ? await localDefaultTheme() : defaultTheme))
            .then(thm => PokerThemeProvider.validateTheme(thm))
    }

    static validateTheme(thm: any): PokerTheme {
        const result = TPokerTheme.decode(thm)
        if (result._tag === "Left") {
            // error
            console.error("Failed to parse theme: " + JSON.stringify(result.left))
            //throw result.left
            throw new Error(CONFIG_INVALID_ERROR_MESSAGE)
        }
        return result.right
    }

    /**
     * private helper function that does the actual fetching of the theme
     *
     * @private
     */
    static async fetchTheme(def: ThemeDefinition): Promise<PokerTheme> {
        const zip = new JSZip()
        return zip.loadAsync(def.file)
            .then(zip => zip.file("theme.json"))
            .then(zipObject => zipObject!.async("string"))
            .catch(() => {throw new Error(CONFIG_NOT_FOUND_ERROR_MESSAGE)})
            .then(jsonString => JSON.parse(jsonString) as PokerTheme)
            .then(async (thm) => {
                let preview = getImageAsDataUrl(zip, Url(ThemeImageLocations.PREVIEW))
                    .then(url => thm.general.preview=Url(url))
                let background = getImageAsDataUrl(zip, Url(ThemeImageLocations.BACKGROUND))
                    .then(url => thm.general.background=Url(url))
                let table = getImageAsDataUrl(zip, Url(ThemeImageLocations.TABLE))
                    .then(url => thm.table.table=Url(url))
                let playerIcon = getImageAsDataUrl(zip, Url(ThemeImageLocations.PLAYER_ICON))
                    .then(url => thm.table.playerIcon=Url(url))
                let currentPlayerIcon = getImageAsDataUrl(zip, Url(ThemeImageLocations.CURRENT_PLAYER_ICON))
                    .then(url => thm.table.currentPlayerIcon=Url(url))
                await Promise.all([preview, background, table, playerIcon, currentPlayerIcon])
                return thm
            }).catch((err) => {
                if(err.message === CONFIG_NOT_FOUND_ERROR_MESSAGE) {throw err}
                else {throw new Error(IMAGE_NOT_FOUND_ERROR_MESSAGE)}
            })
    }
    /**
     * Returns a new PokerTheme with the selected theme. This will overwrite
     * previous maps.
     */
    withTheme(themeDefinition: ThemeDefinition): PokerThemeProvider {
        return new PokerThemeProvider(themeDefinition)
    }

    /**
     * Standard map function for monads. Returns a new PokerThemeMonad with m applied
     * to the theme
     * @param m
     */
    map(m: (prms: Promise<PokerTheme>) => Promise<PokerTheme>): PokerThemeProvider {
        return Object.assign({}, this, {theme: m(this.theme)}) 
    }

    /**
     * Maps the current theme using m. This function provides access to the actual theme
     *
     * @param m: (theme: PokerTheme) => any
     */
    use(m: (theme: PokerTheme) => any) {
        this.theme.then(
            thm => m(thm)
        )
    }
}

export default defaultPokerThemeURL


// parsedConfig["table"]["playerIcon"] = {_tag: "Url", url: "predefined url"}