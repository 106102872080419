import React from "react";
import {asCssRGBAString, PokerTheme} from "../../model/PokerTheme";
import {Button, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

interface Props {
    id?: string
    disabled: boolean
    onClick: () => any | ((a: string, b: string) => void)
    variant: any
    text: string
    icon?: React.ReactNode
    pokerTheme: PokerTheme
    overrideUpperCase?: boolean
}



export const PokerButton: React.FC<Props> = (props) => {
    const {id, disabled, onClick, variant, text, pokerTheme, icon} = props;
    const useStyles =
        makeStyles((theme: Theme) => ({
            pokerButton: (pTheme: PokerTheme) => ({
                color: asCssRGBAString(pTheme.panel.text),
                width: "100%",
                backgroundColor: asCssRGBAString(pTheme.panel.button),
                "&:hover": {backgroundColor: asCssRGBAString(pTheme.panel.button)},
                "&.Mui-disabled": {
                    color: "rgba(0, 0, 0, 0.26)",
                    backgroundColor: asCssRGBAString(pTheme.panel.button)
                },
            }),
        }))

    const classes = useStyles(pokerTheme)

    return (
        <Button id={id}
                className={classes.pokerButton}
                disabled={disabled}
                onClick={onClick}
                variant={variant}>{text}{icon}</Button>
    )
}