import React, { useState } from "react";
import {Button, createStyles, makeStyles, Snackbar, TextField} from "@material-ui/core"
import { useIntl } from 'react-intl'

interface Props {
    signIn: (email: string, password: string) => void
    error: boolean
    closeError(): void
}

const useStyles = makeStyles((theme) =>
    createStyles({
        parentDiv: {
            width: "100%",
            height: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        },
        retroStartDiv: {
            width: "100%",
            display: "flex",
            justifyContent: "flex-end"
        },
        contentDiv: {
            width: "300px",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start"
        },
        textField: {
            margin: "5px"
        },
        button: {
            marginTop: "3px"
        },
        pokerTitle: {color: "#282c34", margin: "0 0 15px 0"},
        form: {
            width: "340px",
            boxShadow: "0 0 3px 0 rgba(0,0,0,0.3)",
            background: "#ffffff",
            padding: "20px",
            textAlign: "center",
            borderRadius: "3px",
            marginTop: 15
        }
    })
);

export default function LoginComponent(props: Props) {

    const styles = useStyles();
    const {signIn, error, closeError} = props

    const [eMail, setEMail] = useState<string>("")
    const [password, setPassword] = useState<string>("")

    const intl = useIntl()

    return (
        <div className={styles.form}>
            <div className={styles.contentDiv}>
                <h3 className={styles.pokerTitle} >{intl.formatMessage({id: "login_component-heading"})}</h3>
                <TextField
                    id="login_component-text_area-email"
                    className={styles.textField}
                    label={intl.formatMessage({id: "login_component-email_label"})}
                    value={eMail}
                    autoComplete="email"
                    onChange={(event) => {
                        setEMail(event.target.value)
                    }}
                    fullWidth>
                </TextField>
                <TextField
                    id="login_component-text_area-password"
                    className={styles.textField}
                    label={intl.formatMessage({id: "login_component-password_label"})}
                    value={password}
                    type="password"
                    autoComplete="current-password"
                    onChange={(event) => {
                        setPassword(event.target.value)
                    }}
                    fullWidth>
                </TextField>
                <div className={styles.retroStartDiv}>
                    <Button
                        id="login_component-button-log_into_account"
                        className={styles.button}
                        onClick={() => signIn(eMail, password)}
                        color={"primary"}
                        disabled={eMail === "" || password === ""}
                        variant={"contained"}>
                        {intl.formatMessage({id: "login_component-submit_button"})}
                    </Button>
                    <Snackbar
                        message={intl.formatMessage({id: "login_component-error_message"})}
                        open={error}
                        autoHideDuration={6000}
                        onClose={closeError}
                    />
                </div>
            </div>
        </div>
    )
}