import PokerPlayer from "../../model/PokerPlayer";
import {TableState} from "../../model/PokerTable";
import {NetworkEventListener} from "./NetworkEventListener";
import PlayerManager from "../control/PlayerManager";
import {PokerCardValue} from "../../model/PokerCard";
import {ProfileEntry} from "../control/StorageManager";

export default abstract class NetworkInterface {

    protected playerManager: PlayerManager
    protected networkEventListener?: NetworkEventListener

    protected constructor(playerManager: PlayerManager) {
        this.playerManager = playerManager;
    }

    setNetworkEventListener(listener: NetworkEventListener): void {
        this.networkEventListener = listener;
    }

    abstract checkLogin(tableID: string | undefined): Promise<void>

    abstract createTable(tableName: string): Promise<string>

    abstract joinTable(user: PokerPlayer, tableID: string): Promise<void>

    abstract playCard(tableID: string, value: PokerCardValue, playedBy: string | null): Promise<void>

    abstract updateTableState(tableID: string, state: TableState): Promise<void>

    abstract deleteVoteFromHistory(tableID: string, votingID: string): Promise<void>

    abstract revealCards(tableID: string): Promise<void>

    abstract toggleChangeOpenCard(tableID: string): Promise<void>

    abstract toggleChangeRandomizeFreeVote(tableID: string): Promise<void>

    abstract toggleShowHistory(tableID: string): Promise<void>

    abstract playReminderSound(tableID: string): Promise<void>

    abstract switchPlayerRole(tableId: string, player: PokerPlayer): Promise<void>

    abstract switchPlayerRoleByAdmin(tableId: string, player: PokerPlayer): Promise<void>

    abstract startFreeVote(tableID: string, votingOptions: string[], indices: string[]): Promise<void>

    abstract startFreeNumberVote(tableID: string): Promise<void>

    abstract uploadAvatar(tableID: string, player: string, cognitoID: string | undefined, file: File): Promise<void>

    abstract deleteAvatar(tableID: string, playerID: string, cognitoID: string | undefined): Promise<void>

    abstract updateAvatar(tableId: string, playerId: string): Promise<void>

    abstract updateVotingTitle(tableID: string, title: string): Promise<void>

    abstract updatePlayerProfileToDB(profile: ProfileEntry, cognitoId: string, cognitoIdToken: string): Promise<void>

    abstract getPlayerProfileFromDB(cognitoId: string, cognitoIdToken: string): Promise<ProfileEntry | undefined>


}
