import {Url} from "./PokerTheme";
import JSZip from "jszip";

export const getArchiveFromUrl = async (url: Url) => {
    return fetch(url.url)
        .then(resp => resp.blob())
        .then(blob => new File([blob], "Theme.zip"))
}

export const getImageAsDataUrl = async (file: JSZip, relativePath: Url): Promise<string> => {
    const reader = new FileReader()
    const image = file.file(relativePath.url)
    return image!.async("blob")
        //.then(blob => reader.readAsDataURL(blob))
        .then( (blob) => {
            return new Promise((resolve, reject) => {
                reader.readAsDataURL(blob);

                reader.onerror = (error) => {
                    reject("Input: File could not be read:" + error);
                };

                reader.onloadend = () => {
                    resolve(reader.result as string);
                };
            });
        })

}
