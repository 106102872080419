import React, {useCallback, useEffect, useMemo, useState} from "react";
import PokerTable, {VotingType} from "../../model/PokerTable";
import MainController from "../../controller/control/MainController";
import logger from "../../util/Logger";
import PokerToggle from "./PokerToggle";
import {PokerTheme} from "../../model/PokerTheme";
import {PokerButton} from "./PokerButton";
import {PokerPanel} from "./PokerPanel";
import {useIntl} from "react-intl";
import {getMostFrequentElementInAnObject, getPlayedCardsObject} from "../../util/Helper"
import {HistoryItem} from "../../pages/PokerPage";

interface Props {
    table: PokerTable
    controller: MainController
    openFreeVoteDialog: () => void
    openRepeatFreeVoteDialog: () => void
    votingTitle: string
    votingTitleHandler: (s: string) => void
    pokerTheme: PokerTheme
    lastFreeVoteFromHistory: HistoryItem
}

export const AdminControlPanel: React.FC<Props> = (props) => {
    const {
        table,
        controller,
        openFreeVoteDialog,
        openRepeatFreeVoteDialog,
        pokerTheme,
        votingTitleHandler,
        votingTitle,
        lastFreeVoteFromHistory
    } = props
    const [endVotingDisabledFlag, setEndVotingDisabledFlag] = useState(false)
    const [previousCardsRevealed, setPreviousCardsRevealed] = useState(false)

    const areAllLastCardsEqual = useMemo(() => {
            return lastFreeVoteFromHistory && lastFreeVoteFromHistory.votesForOptions.every((value, index, array) => {
                return value === array[0]
            });
        }, [lastFreeVoteFromHistory]
    )
    const startVoting = useCallback(
        () => {
            controller.enableVoting(table.id).catch(err => logger.error(err));
        },
        [controller, table]
    )

    const startLastFreeVote = useCallback(
        () => {
            controller.startFreeVote(lastFreeVoteFromHistory.tableId, lastFreeVoteFromHistory.votingOptions, lastFreeVoteFromHistory.indexForVotingOptions).catch(err => logger.error(err));
        },
        [controller, lastFreeVoteFromHistory]
    )

    const handleRepeatFreeVote = () => {
        if (areAllLastCardsEqual) {
            startLastFreeVote();
        } else {
            openRepeatFreeVoteDialog();
        }
    }

    const startFreeNumberVote = useCallback(
        () => {
            controller.startFreeNumberVote(table.id).catch(err => logger.error(err));
        },
        [controller, table]
    )
    const finishVoting = useCallback(
        async () => {
            votingTitleHandler("");
            controller.disableVoting(table.id).catch(err => logger.error(err));
            const {res, isAllCardsTheSame} = getVotingResult()
        },
        [controller, table, votingTitle, votingTitleHandler]
    )

    const showCards = useCallback(
        () => {
            controller.revealCards(table.id).catch(err => logger.error(err));
        },
        [controller, table]
    )

    const playReminderSound = useCallback(
        () => {
            controller.playReminderSound(table.id).catch(err => logger.error(err));
        },
        [controller, table]
    )

    const isVotingDisabledOrAreCardsRevealed = useMemo(
        () => {
            return !table.isVotingEnabled() || table.hasCardsRevealed();
        },
        [table]
    )

    const toggleChangeOpenCard = useCallback(() => {
        controller.toggleChangeOpenCard(table.id).catch(err => logger.error(err))
    }, [table, controller])

    const toggleChangeRandomizeFreeVote = useCallback(() => {
        controller.toggleChangeRandomizeFreeVote(table.id).catch(err => logger.error(err))
    }, [table, controller])

    useEffect(() => {
            if (table.cardsWereAutomaticallyRevealedRecently && !previousCardsRevealed) {
                setPreviousCardsRevealed(true)
                setEndVotingDisabledFlag(true)
                setTimeout(() => (setEndVotingDisabledFlag(false)), 1000);
            }
            if (!table.cardsWereAutomaticallyRevealedRecently && previousCardsRevealed) {
                setPreviousCardsRevealed(false)
            }
        }, [table, previousCardsRevealed, setPreviousCardsRevealed, setEndVotingDisabledFlag]
    )

    const getVotingResult = () => {
        const {playedCardsObject, isAllCardsTheSame} = getPlayedCardsObject(table);
        // @ts-ignore
        const votingResult = getMostFrequentElementInAnObject(playedCardsObject)
        let finalVotingResult: string[] = []
        Object.entries(playedCardsObject).forEach(p => {
            if (p[1] === votingResult[1]) {

                finalVotingResult.push((parseInt(p[0]) / 100).toString());

            }
        })
        if (table.votingType === VotingType.FREE_VOTE) {
            let tempArray: string[] = [];
            finalVotingResult.forEach(e => {
                if (typeof e === "string") {
                    tempArray.push(table.votingOptions[parseInt(e)])
                }
            })
            const res = tempArray.join(", ")
            return {res, isAllCardsTheSame};
        }
        const res = finalVotingResult.join(", ")
        return {res, isAllCardsTheSame};
        // @ts-ignore
    }
    const intl = useIntl();
    const startFibonacci = async () => {
        startVoting();
        if (votingTitle !== "") {
            await controller.updateVotingTitle(table.id, votingTitle)
        }
    }
    const startFreeNumberVoteWithTitle = async () => {
        startFreeNumberVote()
        if (votingTitle !== "") {
            await controller.updateVotingTitle(table.id, votingTitle)
        }
    }
    const repeatVoteHandler = async () => {
        handleRepeatFreeVote()
        if (votingTitle !== "") {
            await controller.updateVotingTitle(table.id, votingTitle)
        }
    }
    return (
        <PokerPanel
            content={

                [
                    <PokerButton
                        id={"admin_control_panel-button-fibonacci"}
                        disabled={table.isVotingEnabled()}
                        //onClick={startVoting}
                        onClick={startFibonacci}
                        variant={"contained"}
                        text={intl.formatMessage({id: "admin_control_panel-text-fibonacci"})}
                        pokerTheme={pokerTheme}/>,
                    <PokerButton
                        id={"admin_control_panel-button-free_number"}
                        disabled={table.isVotingEnabled()}
                        onClick={startFreeNumberVoteWithTitle}
                        variant={"contained"}
                        text={intl.formatMessage({id: "admin_control_panel-text-free_number"})}
                        pokerTheme={pokerTheme}/>,
                    <PokerButton
                        id={"admin_control_panel-button-free_vote_dialog"}
                        disabled={table.isVotingEnabled()}
                        onClick={() => {
                            openFreeVoteDialog()
                        }}
                        variant={"contained"}
                        text={intl.formatMessage({id: "admin_control_panel-text-free_vote"})}
                        pokerTheme={pokerTheme}/>,
                    <PokerButton
                        id={"admin_control_panel-button-repeat_free_vote"}
                        disabled={table.isVotingEnabled() || (lastFreeVoteFromHistory === undefined) || (lastFreeVoteFromHistory === null)}
                        onClick={repeatVoteHandler}
                        variant={"contained"}
                        text={intl.formatMessage({id: "admin_control_panel-text-repeat_free_vote"})}
                        pokerTheme={pokerTheme}/>,
                    <PokerButton
                        id={"admin_control_panel-button-reminder"}
                        disabled={isVotingDisabledOrAreCardsRevealed}
                        onClick={playReminderSound}
                        variant={"contained"}
                        text={intl.formatMessage({id: "admin_control_panel-text-reminder"})}
                        pokerTheme={pokerTheme}/>,
                    <PokerButton
                        id={"admin_control_panel-button-reveal_cards"}
                        disabled={isVotingDisabledOrAreCardsRevealed}
                        onClick={showCards}
                        variant={"contained"}
                        text={intl.formatMessage({id: "admin_control_panel-text-reveal_cards"})}
                        pokerTheme={pokerTheme}/>,
                    <PokerButton
                        id={"admin_control_panel-button-end_voting"}
                        disabled={!table.isVotingEnabled() || endVotingDisabledFlag}
                        onClick={finishVoting}
                        variant={"contained"}
                        text={intl.formatMessage({id: "admin_control_panel-text-end_voting"})}
                        pokerTheme={pokerTheme}/>,
                ].filter(button => button.props.disabled === false || (button.props.id === "admin_control_panel-button-end_voting" && endVotingDisabledFlag))
                    .concat([
                            <PokerToggle
                                id={"admin_control_panel-toggle-change_cards"}
                                onChange={toggleChangeOpenCard}
                                checked={table.isChangeOpenCardEnabled()}
                                text={intl.formatMessage({id: "admin_control_panel-text-change_cards"})}
                                flexJustifyContent={"flex-start"}
                            />,
                            <PokerToggle
                                id={"admin_control_panel-toggle-randomize-free-vote"}
                                onChange={toggleChangeRandomizeFreeVote}
                                checked={table.isRandomizeFreeVoteEnabled()}
                                text={intl.formatMessage({id: "admin_control_panel-text-randomize-free-vote"})}
                                flexJustifyContent={"flex-start"}
                            />,
                            <PokerToggle
                                id={"settings_panel_component-toggle-show_history"}
                                onChange={() => controller.toggleShowHistory(table.id)}
                                checked={table.showHistory}
                                text={intl.formatMessage({id: "settings_panel_component-toggle-show_history"})}
                                flexJustifyContent={"flex-start"}
                            />
                        ]
                    )
            } pokerTheme={pokerTheme}
        />
    );
}
