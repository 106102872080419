import React from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {PokerCardValue} from "../../model/PokerCard";


interface Props {
    cardvalue: PokerCardValue
    revealed: boolean
    color: string
}

export const Card: React.FC<Props> = (props) => {
    if (props.revealed) {
        const card: string = cardValueToString(props.cardvalue);
        const color: string = props.color
        return <RevealedCard value={card} color={color} />
    } else {
        return <HiddenCard />
    }
}

export default Card

const useStylesRevealedCard = makeStyles<Theme,{color: string, length: number}>(() => ({
    cardStandard: ({color, length}) => ({
        display: "flex",
        width: "38px",
        height: "70px",
        flexDirection: length > 2 ? "column" : undefined,
        justifyContent: length > 2 ? "space-between" : undefined
    }),
    cardTl: ({color, length}) => (
        length > 2 ? ({
            alignSelf: "flex-start"
        }) : ({
            display: "flex",
            flexGrow: 1,
            flexFlow: "column nowrap",
            alignItems: "flex-start",

        })
    ),
    cardValue: ({color, length}) => {
        const fontFactor = Math.min(1, 4/length);
        return ({
            color: color,
            fontSize: 16 * fontFactor
        })
    },
    cardBr: ({color, length}) => (
        length > 2 ? ({
            alignSelf: "flex-end",
            transform: "rotate(-180deg)"
        }) : ({
            display: "flex",
            flexGrow: 1,
            flexFlow: "column nowrap",
            justifyContent: "flex-start",
            transform: "translateX(2px) rotate(-180deg)"

        })
    ),
}))

const RevealedCard: React.FC<{ value: string, color:string }> = (props) => {
    const {value, color} = props;
    const classes = useStylesRevealedCard({color, length: value.length})
    return (<div className={classes.cardStandard}>
        <div className={classes.cardTl}>
            <div className={classes.cardValue}>
                {props.value}
            </div>
        </div>
        <div className={classes.cardBr}>
            <div className={classes.cardValue}>
                {props.value}
            </div>
        </div>
    </div>);
}

const useStylesHiddenCard = makeStyles((theme: Theme) => ({
    cardHidden: {
        width: "48px",
        height: "80px",
        borderRadius: "8px",
        backgroundColor: "#8e1216",
        boxShadow: "0 0 3px 0 rgba(0,0,0,0.3)",
        margin: "-5px"
    }
}))

const HiddenCard: React.FC = () => {
    const classes = useStylesHiddenCard()
    return <div className={classes.cardHidden} />
}

export const cardValueAsNumber = (value: PokerCardValue) => {
    if (isNaN(+value))
        throw Error("Free number is not a number!")
    return +value
}

export function cardValueToString(value: PokerCardValue | undefined): string {
    if(value === -1 )
        return "?"
    if(value === -2 )
        return "\u221E"
    if(value === undefined)
        return ""
    if(value === "")
        return value
    if(isNaN(+value))
        return value as string
    return (+value / 100).toString().replace(".", ",")
}
