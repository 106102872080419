import {ThemeDefinition, ThemeList, ThemeSource} from "../../../service/ThemeService";


export default abstract class ThemeStorageInterface {

    abstract uploadTheme(source: ThemeSource, file: File): Promise<void>

    abstract deleteTheme(themeSource: ThemeSource): Promise<void>

    abstract loadCustomThemes(tableID: string, playerID: string): Promise<Map<string, ThemeDefinition>>

    abstract loadUserThemes(cognitoID: string): Promise<Map<string, ThemeDefinition>>

    abstract loadCustomAndUserThemes(tableID: string, playerID: string, cognitoID: string): Promise<Map<string, ThemeDefinition>>

    abstract getThemeDefinition(source: ThemeSource): Promise<ThemeDefinition>;

    abstract loadPublicThemes(themeListJson: string): Promise<ThemeList>

}