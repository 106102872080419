import React from "react";
import {createStyles, FormControl, Input, InputLabel, makeStyles} from "@material-ui/core";
import {FormattedMessage} from "react-intl";

interface Props {
    nameValue: string
    onChange(event: object): void
}

const useStyles = makeStyles((theme) =>
    createStyles({
        textField: {width: "280px"}
    })
);

export default function PlayerNameInputComponent(props: Props) {

    const styles = useStyles();
    const {nameValue, onChange} = props

    return (
        <FormControl className={styles.textField}>
            <InputLabel
                htmlFor="playername"
                color={"primary"}>
                <b><FormattedMessage id={`login_page-text-user_name`}/></b>
            </InputLabel>
            <Input
                value={nameValue}
                onChange={onChange}
                type={"text"}
                name={"playername"}
                color={"primary"}
                required
            />
        </FormControl>
    )
}