import PokerTable from "./PokerTable";
import {PokerCardValue} from "./PokerCard";

export enum Role {
    VOTER,
    OBSERVER,
    FREE_PLACE
}

export default class PokerPlayer {

    static readonly AUDIO_NOTIFICATION_SETTING_DEFAULT = true

    id?: string
    cognitoID?: string
    name: string = ""
    role: Role = Role.OBSERVER
    playNotification: boolean = PokerPlayer.AUDIO_NOTIFICATION_SETTING_DEFAULT
    connected: boolean = false
    playedCardValue?: PokerCardValue
    cardPlayedBy: string|null = null
    useGuestS3: boolean

    constructor(id: string | undefined, cognitoID: string | undefined, name: string, role: Role, useGuestS3: boolean) {
        this.id = id;
        this.cognitoID = cognitoID;
        this.name = name;
        this.role = role;
        this.useGuestS3 = useGuestS3;
    }

    isAllowedToVote(): boolean {
        return this.role === Role.VOTER;
    }

    canControlTable(table: PokerTable): boolean {
        return this.id === table.admin?.id;
    }

    setRole(role: Role): void {
        this.role = role;
    }
}
