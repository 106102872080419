import {VotingOptionsFilters} from "../enums/VotingOptionsFilters";
import {HistoryItem} from "../pages/PokerPage";

export class VotesForOption {
    votingOption: string
    votesForOption: number
    indexForOption: string

    constructor(votingOption: string, votesForOption: number = 0, indexForOption: string = "") {
        this.votingOption = votingOption
        this.votesForOption = votesForOption
        this.indexForOption = indexForOption
    }
}

export class VotingOptionsWithIndex {
    votingOptions: string[] = []
    indices: string[]

    constructor(votingOptions: string[] = [], indices: string[] = []) {
        this.votingOptions = votingOptions
        this.indices = indices;
    }
}

const getSortedVotesForOptions = (lastVote: HistoryItem) => {
    const votesForOptions: VotesForOption[] = [];
    lastVote.votingOptions.forEach(((value, index) => {
        votesForOptions.push(new VotesForOption(value, lastVote.votesForOptions[index], lastVote.indexForVotingOptions[index]))
    }))

    return votesForOptions.sort((a, b) => {
        return b.votesForOption - a.votesForOption
    });
}

export const getVotingOptionsForLastFreeVote = (lastVote: HistoryItem) => {
    if (!lastVote) {
        return undefined;
    }
    const optionsForFilterMap: Map<VotingOptionsFilters, VotingOptionsWithIndex> = new Map();
    const sortedVotesForOption = getSortedVotesForOptions(lastVote);

    function calculateTopX(top: number): VotingOptionsWithIndex {
        if (top <= 1 || lastVote.votingOptions.length <= 2) {
            return new VotingOptionsWithIndex(lastVote.votingOptions, lastVote.indexForVotingOptions);
        }
        if (sortedVotesForOption[top - 1].votesForOption === sortedVotesForOption[sortedVotesForOption.length - 1].votesForOption) {
            if (top === 2) {
                return new VotingOptionsWithIndex(lastVote.votingOptions, lastVote.indexForVotingOptions);
            }
            return calculateTopX(top - 1)
        }
        const filteredOptions: VotingOptionsWithIndex = new VotingOptionsWithIndex();
        sortedVotesForOption.every((value, index, array) => {
            if (index >= top && array[index].votesForOption !== array[top - 1].votesForOption) {
                return false;
            }
            filteredOptions.votingOptions.push(array[index].votingOption);
            filteredOptions.indices.push(array[index].indexForOption);
            return true;
        })
        return filteredOptions;
    }

    function calculateTopPercent(percent: number): VotingOptionsWithIndex {
        let topX = Math.floor(sortedVotesForOption.length * percent / 100);
        if (topX <= 2) {
            topX = 2;
        }
        return calculateTopX(topX);
    }

    function calculateVotingOptionsForFilter(filter: string) {
        switch (filter) {
            case VotingOptionsFilters.ALL.toString(): {
                optionsForFilterMap.set(VotingOptionsFilters.ALL, new VotingOptionsWithIndex(lastVote.votingOptions, lastVote.indexForVotingOptions));
                break;
            }
            case VotingOptionsFilters.TOP_TWO.toString(): {
                if (sortedVotesForOption.length > 2)
                    optionsForFilterMap.set(VotingOptionsFilters.TOP_TWO, calculateTopX(2))
                break;
            }
            case VotingOptionsFilters.TOP_THREE.toString(): {
                if (sortedVotesForOption.length > 3)
                    optionsForFilterMap.set(VotingOptionsFilters.TOP_THREE, calculateTopX(3))
                break;
            }
            case VotingOptionsFilters.TOP_FOUR.toString(): {
                if (sortedVotesForOption.length > 4)
                    optionsForFilterMap.set(VotingOptionsFilters.TOP_FOUR, calculateTopX(4))
                break;
            }
            case VotingOptionsFilters.TOP_FIVE.toString(): {
                if (sortedVotesForOption.length > 5)
                    optionsForFilterMap.set(VotingOptionsFilters.TOP_FIVE, calculateTopX(5))
                break;
            }
            case VotingOptionsFilters.TOP_THIRTY_PERCENT.toString(): {
                optionsForFilterMap.set(VotingOptionsFilters.TOP_THIRTY_PERCENT, calculateTopPercent(30))
                break;
            }
            case VotingOptionsFilters.TOP_FORTY_PERCENT.toString(): {
                optionsForFilterMap.set(VotingOptionsFilters.TOP_FORTY_PERCENT, calculateTopPercent(40))
                break;
            }
            case VotingOptionsFilters.TOP_FIFTY_PERCENT.toString(): {
                optionsForFilterMap.set(VotingOptionsFilters.TOP_FIFTY_PERCENT, calculateTopPercent(50))
                break;
            }
            case VotingOptionsFilters.TOP_SIXTY_PERCENT.toString(): {
                optionsForFilterMap.set(VotingOptionsFilters.TOP_SIXTY_PERCENT, calculateTopPercent(60))
                break;
            }
            default:
        }
    }

    for (const filter in VotingOptionsFilters) {
        calculateVotingOptionsForFilter(filter)
    }
    return optionsForFilterMap;
}