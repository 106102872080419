import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import MainController from "../../controller/control/MainController";
import PokerTable from "../../model/PokerTable";

interface Props {
  showFreeVoteDialog: boolean;
  closeFreeVoteDialog: () => void;
  startFreeVote: (votingOptions: string[]) => void;
  votingTitle: string;
  controller:MainController
  table:PokerTable
}

const OPTIONS_MAX_LENGTH = 40;
const MAX_QUANTITY_OF_OPTIONS = 36;

const useStyles = makeStyles(() =>
  createStyles({
    optionsContainer: { padding: '10px' },
    option: {
      marginTop: '10px',
      marginBottom: '10px',
    },
    nameLengthItem: {
      color: 'rgb(148, 148, 148)',
      paddingTop: '6px',
      paddingBottom: '9px',
    },
    cancelButton: { marginRight: 5 },
  })
);

export const FreeVoteDialog: React.FC<Props> = (props) => {
  const { showFreeVoteDialog, closeFreeVoteDialog, startFreeVote, votingTitle,controller, table } = props;
  const classes = useStyles();
  const intl = useIntl();
  const [votingOptions, setVotingOptions] = useState(['']);

  const formatVotingOptions = (options: string[]) => {
    const optionsCount = options.length;
    if (optionsCount < MAX_QUANTITY_OF_OPTIONS && options[optionsCount - 1] !== '') {
      options.push('');
    } else if (optionsCount > MAX_QUANTITY_OF_OPTIONS) {
      options = options.slice(0, MAX_QUANTITY_OF_OPTIONS);
    }
    setVotingOptions(options);
  };

  const updateOptions = (option: string, index: number): void => {
    let newOptions = [...votingOptions];
    newOptions[index] = option.trimStart().slice(0, OPTIONS_MAX_LENGTH);
    newOptions = newOptions.filter((elem) => elem !== '' && elem !== '\r');
    formatVotingOptions(newOptions);
  };

  const trimAndRestrictLengthOfOptions = (
      newOptions: string[]
  ) : string[] =>{
    newOptions.forEach((option, index, newOption) => {
      newOption[index] = option.trimStart().substring(0,40)
    })
    newOptions = newOptions.filter((elem) => elem !== '');
    return newOptions;
  }

  const splitOptions = (
      newOptions: string[],
      index: number,
      selection: string,
      inputs: string[]
  ): string[] => {
    const splitWord =
        selection !== '' ? votingOptions[index].split(selection) : '';
    if (splitWord.length > 1) {
      newOptions[index] = (splitWord[0] + inputs.shift()).trimStart();
      inputs[inputs.length - 1] = inputs[inputs.length - 1] + splitWord[1];
    } else {
      newOptions[index] = (newOptions[index] + inputs.shift()).trimStart();
    }
    newOptions.splice(index + 1, 0, ...inputs);
    return newOptions;
  }

  const splitAndFormatOptions = (
    index: number,
    inputs: string[],
    newOptions: string[]
  ) => {
    const textField = document.getElementById(
        `free_vote_dialog-input-vote_option-${index}`
    ) as HTMLInputElement;
    const selection =
        textField && textField.selectionStart && textField.selectionEnd
            ? textField.value.substring(
            textField.selectionStart,
            textField.selectionEnd
            )
            : '';

    newOptions = splitOptions(newOptions, index, selection, inputs);
    newOptions = trimAndRestrictLengthOfOptions(newOptions);
    formatVotingOptions(newOptions);
  }

  const interceptPaste = (
    event: React.ClipboardEvent<HTMLDivElement>,
    index: number
  ) => {
    const inputs = event.clipboardData
      .getData('text/plain')
      .split('\n')
      .filter((t) => t !== '');
    let newOptions = [...votingOptions];
    if (inputs.length >= 1) {
      event.preventDefault();
      splitAndFormatOptions(index, inputs, newOptions)
    }
  };

  const renderTextFields = () => {
    return votingOptions.map((votingOption, index) => (
      <Grid container key={index} className={classes.option} spacing={2}>
        <Grid item xs={11}>
          <TextField
            id={`free_vote_dialog-input-vote_option-${index}`}
            placeholder={intl.formatMessage({
              id: 'free_vote_dialog-text-option',
            })}
            value={votingOption}
            type={'text'}
            fullWidth={true}
            inputProps={{
              maxLength: OPTIONS_MAX_LENGTH + (votingOption.length - votingOption.trimStart().length),
            }}
            onChange={(event) => updateOptions(event.target.value, index)}
            onPaste={(event) => interceptPaste(event, index)}
          />
        </Grid>
        <Grid item xs={1}>
          <div className={classes.nameLengthItem}>
            {votingOption.trim().length}
          </div>
        </Grid>
      </Grid>
    ));
  };

  const renderButtons = () => {
    return (
      <React.Fragment>
        <Button
          id={'free_vote_dialog-button-start'}
          color={'primary'}
          variant={'contained'}
          disabled={votingOptions.length <= 1}
          onClick={startVote}
        >
          <FormattedMessage id={'free_vote_dialog-text-start'} />
        </Button>
        <Button
          id={'free_vote_dialog-button-cancel'}
          color={'secondary'}
          variant={'contained'}
          className={classes.cancelButton}
          onClick={cancel}
        >
          <FormattedMessage id={'free_vote_dialog-text-cancel'} />
        </Button>
      </React.Fragment>
    );
  };

  const cancel = (event?: any, reason?: string): void => {
    if ((event == undefined && reason == undefined) || reason !== 'backdropClick') {
      clearOptions();
      closeFreeVoteDialog();
    }
  };

  const startVote = (): void => {
    let options = [...votingOptions];
    if (options[options.length - 1] === '') options = options.slice(0, -1);
    startFreeVote(options);
    controller.updateVotingTitle(table.id,votingTitle)
    clearOptions();
    closeFreeVoteDialog();
  };

  const clearOptions = (): void => {
    setVotingOptions(['']);
  };

  return (
    <Dialog
      open={showFreeVoteDialog}
      onClose={(event, reason) => cancel}
      maxWidth={'xs'}
      fullWidth={true}
    >
      <DialogContent>
        <div className={classes.optionsContainer}>{renderTextFields()}</div>
      </DialogContent>
      <DialogActions>{renderButtons()}</DialogActions>
    </Dialog>
  );
};

export default FreeVoteDialog;
