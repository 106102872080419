import {PlayerContainer} from "../container/PlayerContainer";
import PokerPlayer, {Role} from "../../../../model/PokerPlayer";


enum SwitchRoleCommand {
    SWITCH_TO_VOTER = "SWITCH_TO_VOTER",
    SWITCH_TO_OBSERVER = "SWITCH_TO_OBSERVER"
}

export default class SwitchPlayerRoleByAdminRequest {
    player: PlayerContainer
    tableID: string
    command: string
    adminToken: string

    constructor(tableID: string, command: SwitchRoleCommand, player: PokerPlayer, adminToken: string) {
        this.tableID = tableID;
        this.command = command;
        this.player = PlayerContainer.fromPokerPlayer(player);
        this.adminToken = adminToken;
    }

    static newSwitchRoleByAdminCommand(tableID: string, player: PokerPlayer, adminToken: string): SwitchPlayerRoleByAdminRequest {
        return new SwitchPlayerRoleByAdminRequest(tableID, playerRoleToCommand(player), player, adminToken);
    }
}

function playerRoleToCommand(player: PokerPlayer): SwitchRoleCommand {
    let command: SwitchRoleCommand
    switch (player.role) {
        case Role.OBSERVER:
            command = SwitchRoleCommand.SWITCH_TO_VOTER;
            break;
        case Role.VOTER:
            command = SwitchRoleCommand.SWITCH_TO_OBSERVER;
            break;
        case Role.FREE_PLACE:
            command = SwitchRoleCommand.SWITCH_TO_VOTER;
            break;
        default:
            throw Error("Invalid command!");
    }
    return command;
}
