import {
    Button,
    createStyles,
    Grid,
    makeStyles,
} from "@material-ui/core";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {Role} from "../model/PokerPlayer";
import {FormattedMessage} from "react-intl";
import ControllerContext from "../contexts/ControllerContext";
import EnableLoadingStateContext from "../contexts/EnableLoadingStateContext";
import PlayerNameInputComponent from "../components/Session/PlayerNameInputComponent";
import RoleSelectionComponent from "../components/Session/RoleSelectionComponent";
import {getCurrentUser, User} from "@2gether/frontend-library";
import LoginComponent from "../components/Session/LoginComponent";
import {Auth} from "@aws-amplify/auth";
import logger from "../util/Logger";
import {AuthorizationContext} from "../contexts/AuthorizationContext"
import {ThemeSource} from "../service/ThemeService";

interface Props {
    tableID: string,
    setThemeSource: (source: ThemeSource) => void;
}

const ROLE_VOTER: string = "Voter"
const ROLE_OBSERVER: string = "Observer"
const PLAYER_NAME_DISPLAY_MAX_LENGTH = 62

const useStyles = makeStyles((theme) =>
    createStyles({
        form: {
            width: "340px",
            boxShadow: "0 0 3px 0 rgba(0,0,0,0.3)",
            background: "#ffffff",
            padding: "20px",
            textAlign: "center",
            borderRadius: "3px",
            marginTop: 15
        },
        pokerTitle: {color: "#282c34", margin: "0 0 15px 0"},
        formButtons: {
            margin: "5px !important",
            backgroundColor: theme.palette.login.main,
            "&:hover": {backgroundColor: theme.palette.login.main},
            "&.Mui-disabled": {
                color: theme.palette.buttonText.main,
                backgroundColor: "gray"
            },
        },
    })
);

export default function JoinSessionPage(props: Props) {
    const {tableID, setThemeSource} = props;

    const {mainController, themeController} = useContext(ControllerContext)
    const enableLoadingState = useContext(EnableLoadingStateContext)

    const styles = useStyles();

    const [playerName, setPlayerName] = useState("");
    const [playerRole, setPlayerRole] = useState(Role.VOTER);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [cognitoUser, setCognitoUser] = useState<User | undefined>(undefined)
    const [showLoginError, setShowLoginError] = useState(false);
    const {userAuthorized, updateUser} = useContext(AuthorizationContext)

    useEffect(() => {
        getCurrentUser()
            .then(() => setIsUserLoggedIn(true))
            .catch(() => setIsUserLoggedIn(false))
    }, [])

    useEffect(() => {
        if (isUserLoggedIn) {
            getCurrentUser().then(user => {
                const name = user.givenName + ' ' + user.familyName
                setPlayerName(name.substring(0, PLAYER_NAME_DISPLAY_MAX_LENGTH - 1))
                setCognitoUser(user)
            })
        }
    }, [isUserLoggedIn])

    useEffect(updateUser, [updateUser, isUserLoggedIn])

    const createPlayer = async (cognitoID: string | undefined, tableID: string) => {
        let playerProfileFromDB = undefined;
        if (!!cognitoID) {
            playerProfileFromDB = await mainController.getPlayerProfileFromDB(cognitoID)
        }
        mainController.createPlayer(playerName, cognitoID, playerRole, tableID, !userAuthorized, playerProfileFromDB)
    }

    const joinButtonClicked = useCallback(
        async (): Promise<void> => {
            enableLoadingState()
            const cognitoID = cognitoUser ? cognitoUser.username : undefined
            await createPlayer(cognitoID,tableID)
            if (!!cognitoUser) {
                  const themeSource = await themeController.getThemeSourceSettingForUser(cognitoUser.username)
                  setThemeSource(themeSource)
            } else {
                const themeSource = await themeController.getThemeSourceSettingForGuest()
                setThemeSource(themeSource)
            }
            await mainController.joinTable(tableID!).catch(reason => {
                logger.error(reason.message)
                mainController.checkLogin()
            })

        },
        [enableLoadingState, mainController, tableID, playerName, cognitoUser, playerRole, userAuthorized]
    )

    const updatePlayerName = useCallback(
        (event) => {
            const name = event.target.value
            if (name.length < PLAYER_NAME_DISPLAY_MAX_LENGTH)
                setPlayerName(name)
        },
        [setPlayerName]
    )

    const updatePlayerRole = useCallback(
        (event) => {
            const roleString = event.target.value;
            let role: Role;
            switch (roleString) {
                case ROLE_VOTER:
                    role = Role.VOTER;
                    break;
                case ROLE_OBSERVER:
                    role = Role.OBSERVER;
                    break;
                default:
                    return;
            }
            setPlayerRole(role);
        },
        [setPlayerRole]
    )

    const onSubmit = useCallback(async (e) => {
        //e.preventDefault();
        await joinButtonClicked().then().catch(reason => logger.error(reason.message));
    }, [joinButtonClicked])

    const nameInputElement =
        <PlayerNameInputComponent
            nameValue={playerName} onChange={updatePlayerName}/>

    function handleSignIn(email: string, password: string) {
        Auth.signIn(email.toLowerCase(), password)
            .then(() => setIsUserLoggedIn(true))
            .catch(() => setShowLoginError(true))
    }


    const roleSelectionElement = <RoleSelectionComponent onChange={updatePlayerRole}
                                                         options={[ROLE_VOTER, ROLE_OBSERVER]}/>

    const joinButtonEnabled = playerName.trim().length !== 0;
    const joinButtonElement =
        <Button
            className={styles.formButtons}
            type={"submit"}
            color={"primary"}
            variant={"contained"}
            disabled={!joinButtonEnabled}>
            <FormattedMessage id={"login_page-text-join_session"}/>
        </Button>

    return (
        <Grid container alignItems="center" direction="column">
            <form className={styles.form} onSubmit={onSubmit}>
                <h3 className={styles.pokerTitle}>Poker2Gether</h3>
                {nameInputElement}
                {roleSelectionElement}
                {joinButtonElement}
            </form>
            {
                (!isUserLoggedIn) &&
                <LoginComponent
                    signIn={handleSignIn}
                    error={showLoginError}
                    closeError={() => setShowLoginError(false)}
                />
            }
        </Grid>
    );
}