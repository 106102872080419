import PokerPlayer, {Role} from "../../model/PokerPlayer";
import StorageManager, {ProfileEntry} from "./StorageManager";

export default class PlayerManager {

    private cookieManager: StorageManager

    constructor(cookieManager: StorageManager) {
        this.cookieManager = cookieManager;
    }

    createLocalPlayer(name: string, cognitoID: string | undefined, role: Role, tableId: string, useGuestS3: boolean, playerProfileFromDB?: ProfileEntry) {
        const player = new PokerPlayer(undefined, cognitoID, name, role, useGuestS3);
        player.playNotification = this.cookieManager.getSettings().isAudioNotificationActive
        this.setPlayerToCookie(player, tableId, playerProfileFromDB);
    }

    assignClientManagedProperties(player: PokerPlayer, tableId: string) {
        const localPlayer = this.getLocalPlayer(tableId);
        if (localPlayer) {
            player.playNotification = localPlayer.playNotification;
        }
    }

    updateLocalPlayer(player: PokerPlayer, tableId: string) {
        this.setPlayerToCookie(player, tableId);
    }

    getAdminToken(tableId: string): string | undefined {
        return this.cookieManager.getAdminToken(tableId);
    }

    setAdminToken(adminToken: string, tableId: string) {
        this.cookieManager.setAdminToken(adminToken, tableId);
    }

    getLocalPlayer(tableId: string): PokerPlayer | undefined {
        return this.getPlayerFromCookie(tableId);
    }

    setLocalPlayer(player: PokerPlayer, tableId: string) {
        player.playNotification = this.cookieManager.getSettings().isAudioNotificationActive
        this.setPlayerToCookie(player, tableId);
    }

    private getPlayerFromCookie(tableId: string): PokerPlayer | undefined {
        const player = this.cookieManager.getPlayer(tableId);
        if (!player)
            return undefined;
        player.playNotification = this.cookieManager.getSettings().isAudioNotificationActive;
        return player;
    }

    private setPlayerToCookie(player: PokerPlayer, tableId: string, playerProfileFromDB?: ProfileEntry) {
        this.cookieManager.addPlayer(player, tableId);
        const localProfile = this.cookieManager.getProfile(player.cognitoID)
        if (!!playerProfileFromDB) {
            this.cookieManager.setProfile(playerProfileFromDB, player.cognitoID);
        } else {
            this.cookieManager.setProfile(localProfile, player.cognitoID);
        }
    }

}
