import PokerCard, {PokerCardValue} from './PokerCard';
import PokerPlayer, {Role} from './PokerPlayer';

export enum TableState {
    VOTING_ENABLED = 1,
    VOTING_DISABLED = 0,
}

export enum VotingType {
    FIBONACCI = 0,
    FREE_VOTE = 1,
    FREE_NUMBER = 2,
}

export class VotingResult {
    votingTitle: string = ""
    votingResult: string = ""
    isHighlighted: boolean = true
    votingID: string = ""
    type: string = ""
}

export default class PokerTable {
    id: string = '';
    admin?: PokerPlayer;
    name: string = '';
    voterPlayers: PokerPlayer[] = [];
    observerPlayers: PokerPlayer[] = [];
    disconnectedPlayers: PokerPlayer[] = [];
    cardsRevealed: boolean = false;
    cardsWereAutomaticallyRevealedRecently: boolean = false;
    state: TableState = TableState.VOTING_DISABLED;
    votingType: VotingType = VotingType.FIBONACCI;
    votingOptions: string[] = [];
    votingOptionsIndex: string[] = [];
    changeOpenCardEnabled: boolean = true;
    randomizeFreeVoteEnabled: boolean = false;
    lastVotingCards: PokerCard[] = [];
    votingTitle: string | undefined = "";
    showHistory: boolean = true;


    addVoterPlayer(player: PokerPlayer) {
        this.voterPlayers.push(player);
    }

    addObserverPlayer(player: PokerPlayer) {
        this.observerPlayers.push(player);
    }

    addDisconnectedPlayer(player: PokerPlayer) {
        this.disconnectedPlayers.push(player);
    }

    getPlayersAtTable(): PokerPlayer[] {
        // disconnected voters are represented by a "connected" free place
        return this.voterPlayers;
    }

    getVoters(): PokerPlayer[] {
        return this.voterPlayers;
    }

    getConnectedObservers(): PokerPlayer[] {
        return this.observerPlayers;
    }

    getNumberOfConnectedObservers(): number {
        return this.getConnectedObservers().length;
    }

    hasCardsRevealed(): boolean {
        return this.cardsRevealed;
    }

    getCardValuesWithCount(): Map<PokerCardValue, number> {
        let countMap = new Map<PokerCardValue, number>();
        let playedCardValues = this.getPlayersAtTable()
            .map((player) => player.playedCardValue)
            .filter((value) => value != null); // filters out null AND undefined
        if (this.votingType === VotingType.FREE_NUMBER)
            playedCardValues = playedCardValues.map((value) => value!);
        for (let value of playedCardValues) {
            countMap.set(value!, (countMap.get(value!) || 0) + 1);
        }
        return countMap;
    }

    getAmountOfVotes(): number {
        const voters = this.getVotersAndEmptySeatsWithCards();
        return voters.length;
    }

    hasFreeVotingMajority(): boolean {
        if (this.votingType !== VotingType.FREE_VOTE) return false
        const voteCounts = this.getCardValuesWithCount();
        let maxCount = Math.max(...voteCounts.values());
        return (maxCount > this.getAmountOfVotes() / 2) && this.cardsRevealed
    }

    areAllCardsEqual(): boolean {
        if (!this.cardsRevealed) return false;
        const voters = this.getVotersAndEmptySeatsWithCards();
        for (let v of voters)
            if (v.connected && v.playedCardValue == null)
                // filters out null AND undefined
                return false;
        const playedCards = voters
            .map((voter) => voter.playedCardValue)
            .filter((voter) => voter != null);
        return new Set(playedCards).size === 1;
    }

    isVotingEnabled(): boolean {
        return this.state === TableState.VOTING_ENABLED;
    }

    getPlayersWithPlayedCard(): PokerPlayer[] {
        return this.getPlayersAtTable().filter(
            (player) => player.playedCardValue != null
        );
    }

    getVotersAndEmptySeatsWithCards(): PokerPlayer[] {
        return this.getPlayersAtTable().filter(
            (player) =>
                player.role === Role.VOTER ||
                (player.role === Role.FREE_PLACE && player.playedCardValue != null)
        );
    }

    getFreePlaces(): PokerPlayer[] {
        return this.getPlayersAtTable().filter(
            (player) => player.role === Role.FREE_PLACE
        );
    }

    isChangeOpenCardEnabled = () => {
        return this.changeOpenCardEnabled;
    };

    isRandomizeFreeVoteEnabled = () => {
        return this.randomizeFreeVoteEnabled;
    }

    getAllPlayers = (): PokerPlayer[] => {
        return this.voterPlayers
            .concat(this.observerPlayers)
            .concat(this.disconnectedPlayers);
    };


}
