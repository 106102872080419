import React, {useCallback, useEffect, useMemo, useRef, useState,} from "react";
import PokerTable, {VotingType} from "../../model/PokerTable";
import {ChartComponent} from "../PokerTable/ChartComponent";

import {createStyles, makeStyles, Theme} from "@material-ui/core";
import PokerPlayer from "../../model/PokerPlayer";
import CardsAbs from "./Cards";
import {PlayersAbs} from "./Players";
import {asCssRGBAString, PokerTheme} from "../../model/PokerTheme";
import {PokerCardValue} from "../../model/PokerCard";

interface Props {
    pokerTable: PokerTable;
    player: PokerPlayer;
    playCard: (value: PokerCardValue, playedBy: string | undefined) => void;
    pokerTheme: PokerTheme
}

const PNG_WIDTH = 1600;
const PNG_HEIGHT = 900;

const usePokerTableComponentFncStyles = makeStyles((t: Theme) =>
    createStyles({
        tableCol: {
            // border: `1px solid ${t.palette.primary.main}`,
        },
        tableContainer: {
            height: "100%",
            width: "100%",
            position: "relative",
        },
        playerCellLeftRight: {
            maxWidth: "100%",
            width: "100%",
            // border: `1px solid ${t.palette.secondary.main}`,
        },
        playersGrid: {position: "absolute", top: 0, left: 0},
        playersCards: {
            position: "absolute",
            top: "23%",
            left: "12%",
            right: "12%",
            bottom: "23%",
            // border: `1px solid ${t.palette.warning.main}`,
        },
        players: {
            position: "absolute",
            // top: "13%",
            // left: "2%",
            // right: "2%",
            // bottom: "13%",
            top: "0%",
            left: "0%",
            right: "0%",
            bottom: "0%",
            // border: `1px solid ${t.palette.success.main}`,
        },
        image: {
            height: "100%",
            width: "100%",
        },
        chart: {
            position: "absolute",
            transform: "translateX(-50%) translateY(-50%)",
            top: "50%",
            left: "50%",
            width: "40%"
        },
        tableTitle: {
            position: "absolute",
            fontFamily: "'Monoton', cursive",
            transform: "translateX(-50%) translateY(-50%)",
            top: "50%",
            left: "50%"
        },
        main: {
            position: "absolute",
            transform: "translateX(-50%) translateY(-50%)",
            top: "50%",
            left: "50%",
        }
    })
);

export const PokerTableComponentFnc: React.FC<Props> = (props) => {
    const classes = usePokerTableComponentFncStyles();
    const {pokerTable, player, playCard, pokerTheme} = props;
    const containerDivRef = useRef<HTMLDivElement>(null);
    const [factor, setFactor] = useState(1);
    const tableImg = pokerTheme.table.table.url

    const updateDimensions = useCallback(() => {
        const containerDiv: HTMLDivElement | null = containerDivRef.current;
        if (containerDiv !== null && containerDiv !== undefined) {
            let factor = Math.min(
                containerDiv.clientHeight / PNG_HEIGHT,
                containerDiv.clientWidth / PNG_WIDTH
            );
            setFactor(factor);
        }
    }, []);

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        updateDimensions();
        return () => {
            window.removeEventListener("resize", updateDimensions);
        };
    }, [updateDimensions]);

    const stl = useMemo(() =>
            ({
                height: PNG_HEIGHT * factor,
                width: PNG_WIDTH * factor,
            })
        , [factor]);

    const fontSizeStyle = {fontSize: 75 * factor}
    const titleColor = asCssRGBAString(pokerTheme.table.title)

    const chartComponentAndResultListElement = (pokerTable.hasCardsRevealed() && pokerTable.votingType !== VotingType.FREE_VOTE) ?
        (
            <div className={classes.chart}>
                <ChartComponent table={pokerTable} pokerTheme={pokerTheme}/>
            </div>
        ) : (
            <div className={classes.tableTitle} style={{...fontSizeStyle, color: titleColor}}>
                POKER2GETHER
            </div>
        )

    return (
        <div
            className={classes.tableContainer}
            ref={containerDivRef}
        >
            <div className={classes.main} style={stl}>
                <img
                    src={tableImg}
                    className={classes.image}
                    alt="poker-table"
                />
                <div>
                    <PlayersAbs
                        factor={factor}
                        table={pokerTable}
                        selfPlayer={player}
                        pokerTheme={pokerTheme}
                    />
                </div>
                <div>
                    <CardsAbs
                        factor={factor}
                        table={pokerTable}
                        onCardClicked={playCard}
                        selfPlayer={player}
                        pokerTheme={pokerTheme}
                    />
                </div>
                {chartComponentAndResultListElement}
            </div>
        </div>
    );
};
