import {createContext} from "react";
import MainController from "../controller/control/MainController";
import ThemeController from "../controller/control/ThemeController";

interface IControllerContext {
    mainController: MainController
    themeController: ThemeController
}

const ControllerContext = createContext<IControllerContext>({mainController: new MainController(), themeController: new ThemeController()})

export default ControllerContext