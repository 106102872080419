import React, {useContext, useEffect, useState} from "react";
import {ThemeChangeDialog} from "./ThemeChangeDialog";
import {PokerButton} from "./PokerButton";
import {PokerTheme} from "../../model/PokerTheme";
import {defaultThemeList, fetchThemes, ThemeDefinition, ThemeList, ThemeSource} from "../../service/ThemeService";
import {useIntl} from "react-intl";
import ControllerContext from "../../contexts/ControllerContext";


export const ThemeComponent: React.FC<{
    pokerTheme: PokerTheme,
    currentThemeSource: ThemeSource,
    loadThemes(): void
    setThemeSource: (source: ThemeSource) => void,
    tableID: string,
    playerID: string
    customThemes: Map<string, ThemeDefinition>
    setCustomThemes: (cThemes: Map<string, ThemeDefinition>) => void
    cognitoID:string|undefined
}> = (props)  => {
    const {pokerTheme, currentThemeSource, setThemeSource, loadThemes, tableID, playerID, customThemes, setCustomThemes,cognitoID} = props
    const {themeController} = useContext(ControllerContext)
    const {mainController} = useContext(ControllerContext)
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [themes, setThemes] = useState<ThemeList|null>(null)
    const intl = useIntl()

    useEffect(() => {
        defaultThemeList()
            .then(setThemes)
            .then(() => fetchThemes(themeController))
            .then(setThemes)
    }, [themeController])

    const onThemeChange = async (theme: ThemeDefinition) => {
        setThemeSource(theme.source);
        setShowDialog(false);
        !!cognitoID && await mainController.updatePlayerProfileToDB(cognitoID, theme.source._tag)
    }

    return (
        <>
                <PokerButton
                    onClick={() => {
                        setShowDialog(true);
                    }}
                    disabled={false}
                    variant={"contained"}
                    text={intl.formatMessage({id: "theme_component-text-select_theme"})}
                    pokerTheme={pokerTheme}
                />
            {themes !== null &&
                <ThemeChangeDialog
                    loadThemes={loadThemes}
                    showDialog={showDialog}
                    onClose={() => setShowDialog(false)}
                    onSubmit={(themeDef) => onThemeChange(themeDef)}
                    themeList={themes}
                    currentThemeSource={currentThemeSource}
                    playerID={playerID}
                    tableID={tableID}
                    customThemes={customThemes}
                    setCustomThemes={setCustomThemes}
            />
            }
        </>
    );
}