import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import {createStyles, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

interface Props {
    id: string
    onChange: () => any
    checked: boolean
    text: string,
    flexJustifyContent?: string
}

const useStyles = makeStyles((t) =>
    createStyles({
        toggleSvg: {
            "& svg": {color: t.palette.text.primary}
        },
        toggleTypography: (props: Props) => ({
            overflowWrap: "break-word",
            alignItems: "center",
            justifyContent: props.flexJustifyContent ?? "center",
            display: "flex",
            height: "30px"
        })
    }))

export const PokerToggle: React.FC<Props> = (props) => {
    const {
        id,
        onChange,
        checked,
        text,
    } = props
    const classes = useStyles(props);
    return (
            <Typography
                variant={"body1"}
                component={"p"}
                className={classes.toggleTypography}>
                <Checkbox
                    id={id}
                    checked={checked}
                    className={classes.toggleSvg}
                    onChange={onChange}
                />
                {text}
            </Typography>
    );
}

export default PokerToggle