import React, {useContext} from "react";
import PokerTable from "../../model/PokerTable";
import {cardValueToString} from "../Cards/Card";
import PokerPlayer, {Role} from "../../model/PokerPlayer";
import {makeStyles} from "@material-ui/core";
import {nanoid} from "nanoid";
import {asCssRGBAString, PokerTheme} from "../../model/PokerTheme";
import {PokerButton} from "./PokerButton";
import {cardShouldBeClickable} from "../../util/Cards";
import {FormattedMessage, useIntl} from "react-intl";
import {PokerCardValue} from "../../model/PokerCard";
import logger from "../../util/Logger";
import ControllerContext from "../../contexts/ControllerContext";

interface Props {
    pokerTable: PokerTable,
    selfPlayer: PokerPlayer;
    playCard: (value: PokerCardValue, playedBy: string|undefined) => void;
    pokerTheme: PokerTheme
}

const useStyle = makeStyles(() => ({
    mobileTableContainer: (pTheme:PokerTheme) => ({
        boxShadow: "0 0 10px",
        backgroundColor: asCssRGBAString(pTheme.panel.background),
        margin: "10px",
        textAlign: "center",
        padding: "10px"
    }),
    pokerTableTable: {margin: "0 auto"},
    pokerTableCell: {paddingLeft: "20px", paddingRight: "20px", whiteSpace: "nowrap"}
}));

export const PokerTableMobileComponent: React.FC<Props> = (props) => {
    const {pokerTable, selfPlayer, playCard, pokerTheme} = props;
    const tableMobileStyles = useStyle(pokerTheme)
    const {mainController} = useContext(ControllerContext)
    const intl = useIntl();
    const PLAYER_NAME_DISPLAY_MAX_LENGTH = 30

    const renderPlayerEntry = (player: PokerPlayer) => {
        const hasPlayedCard =
            (player.playedCardValue !== null && player.playedCardValue !== undefined)

        const participatesInVote =
            player.connected && player.role === Role.VOTER

        if (! hasPlayedCard && ! participatesInVote)
            return null;

        let cardText = "";
        if (pokerTable.isVotingEnabled() && hasPlayedCard)
            if (pokerTable.hasCardsRevealed())
                cardText = cardValueToString(player.playedCardValue!!)
            else
                cardText = intl.formatMessage({id: "poker_table_mobile_component-text-not_revealed"})

        const isClickable = (value: PokerCardValue | undefined): boolean => {
            return (
                cardShouldBeClickable(pokerTable, selfPlayer)
                && pokerTable.hasCardsRevealed()
                && value !== undefined
                && value !== selfPlayer.playedCardValue);
        }

        const onClick = (value: PokerCardValue | undefined, id: string|undefined) => {
            if (value !== undefined && value !== selfPlayer.playedCardValue)
                playCard(value, id);
        }

        const onClickSwitchPlayerRoleByAdmin = () => {
            if (selfPlayer.canControlTable(pokerTable)) {
                mainController.switchPlayerRoleByAdmin(pokerTable.id, player).catch(err => logger.error(err));
            }
        }

        const reduce = (name: string) => {
            if (name.length > PLAYER_NAME_DISPLAY_MAX_LENGTH) {
                return name.substring(0,PLAYER_NAME_DISPLAY_MAX_LENGTH-3) + "...";
            }
            else {
                return name;
            }
        }

        return (
            <tr key={nanoid()}>
                <td className={tableMobileStyles.pokerTableCell}
                    onClick={() => onClickSwitchPlayerRoleByAdmin()}
                    style={ selfPlayer.canControlTable(pokerTable)? {cursor: "pointer"} : {}}>
                    {player.connected && player.role === Role.VOTER ? reduce(player.name) : ""}
                </td>
                <td className={tableMobileStyles.pokerTableCell}>
                    {cardText !== "" ?
                        <PokerButton
                            variant={"contained"}
                            pokerTheme={pokerTheme}
                            onClick={() => onClick(player.playedCardValue, player.id)}
                            disabled={! isClickable(player.playedCardValue)}
                            text={cardText}>

                        </PokerButton> :
                        null
                    }
                </td>
            </tr>
        )
    }

    return (
        <div className={tableMobileStyles.mobileTableContainer}>
            <h2><FormattedMessage id={"poker_table_mobile_component-text-table"}/></h2>
            <table className={tableMobileStyles.pokerTableTable}>
                <tbody>
                {pokerTable.getPlayersAtTable().map((voter: PokerPlayer) => (
                    renderPlayerEntry(voter)
                ))}
                </tbody>
            </table>
        </div>
    )
}

export default PokerTableMobileComponent
