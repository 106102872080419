import gql from "graphql-tag";

export const SUB_TO_NEW_VOTES = gql`
subscription onAddVotingToTableHistory($tableId: String) {
  onAddVotingToTableHistory(tableId: $tableId) {
    tableId
    votingId
    type
    votingTitle
    votingOptions
    votesForOptions
  }
}
`;

export const SUB_TO_DELETE_VOTES = gql`
subscription onDeleteVoteFromTableHistory($tableId: String) {
  onDeleteP2GHistory(tableId: $tableId) {
    tableId
    votingId
    votingTitle
  }
}
`;