import { makeAutoObservable } from "mobx"

class AvatarChangeStore {
    updatedPlayers: string[]

    constructor() {
        makeAutoObservable(this)
        this.updatedPlayers = []
    }

    addUpdatedPlayer (user: string) {
        this.updatedPlayers = [...this.updatedPlayers].concat(user)
    }

    removeUpdatedPlayer (user: string) {
        this.updatedPlayers = [...this.updatedPlayers].filter(player => player !== user)
    }
}

export default AvatarChangeStore