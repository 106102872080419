import NetworkInterface from "../NetworkInterface";
import {TableState} from "../../../model/PokerTable";
import PokerPlayer from "../../../model/PokerPlayer";
import {STATUS_CREATED, STATUS_NOTFOUND, STATUS_OK, submitGETRequest, submitPOSTRequest} from "./requests";
import {withIdTokenInAuthenticationHeader} from "@2gether/frontend-library"
import WebsocketService, {WebsocketListener} from "./WebSocketService";
import TableCreationRequest from "./request/TableCreationRequest";
import PlayerManager from "../../control/PlayerManager";
import {PlayerContainer} from "./container/PlayerContainer";
import JoinTableRequest from "./request/JoinTableRequest";
import PlayCardRequest from "./request/PlayCardRequest";
import TableCommandRequest from "./request/TableCommandRequest";
import TableContainer from "./container/TableContainer";
import TableCheckResult, {TableCheckState} from "./response/TableCheckResult";
import {TableCreationResponse} from "./response/TableCreationResponse";
import SwitchPlayerRoleRequest from "./request/SwitchPlayerRoleRequest";
import {decodeMessage, MessageType} from "./message/WebsocketMessage";
import {AudioType} from "../AudioType";
import FreeVoteRequest from "./request/FreeVoteRequest";
import FreeNumberVoteRequest from "./request/FreeNumberVoteRequest";
import {PokerCardValue} from "../../../model/PokerCard";
import SwitchPlayerRoleByAdminRequest from "./request/SwitchPlayerRoleByAdminRequest";

import {ProfileEntry} from "../../control/StorageManager";
import {defaultThemeSource} from "../../../service/ThemeService";


export default class ServerNetworkInterface extends NetworkInterface implements WebsocketListener {

    private readonly baseURL: string
    private readonly playerSettingsURL: string
    private readonly avatarUploadBaseUrl: string
    private readonly avatarDeleteBaseUrl: string
    private readonly websocketService: WebsocketService
    private readonly environment: string

    constructor(playerManager: PlayerManager) {
        super(playerManager);
        this.baseURL = process.env.REACT_APP_BASE_URL!;
        this.avatarUploadBaseUrl = `${this.baseURL}/user/theming/avatarupload?`
        this.avatarDeleteBaseUrl = `${this.baseURL}/user/theming/avatardelete?`
        this.playerSettingsURL = process.env.REACT_APP_PLAYER_SETTINGS_URL!;
        this.websocketService = new WebsocketService(this.baseURL, this);
        this.environment = process.env.REACT_APP_ENV!;
    }

    async createTable(tableName: string): Promise<string> {
        const url = `${this.baseURL}/table/create`;
        const tableData = new TableCreationRequest(tableName);
        const response = await submitPOSTRequest(url, tableData, await withIdTokenInAuthenticationHeader({}));
        const statusCode = response.status
        if (statusCode !== STATUS_CREATED)
            throw Error(`Unexpected response from server while creating table. Status: ${statusCode}`)
        const creationResponse: TableCreationResponse = await response.json();
        this.playerManager.setAdminToken(creationResponse.adminToken, creationResponse.tableID);
        return creationResponse.tableID;
    }


    async checkLogin(tableID: string | undefined): Promise<void> {
        if (!tableID) {
            this.networkEventListener?.tableCreationRequired();
            return;
        }
        const player = this.playerManager.getLocalPlayer(tableID);
        if (!player || !player.id) {
            const exists = await this.checkTableExists(tableID);
            if (exists)
                this.networkEventListener?.loginRequired();
            else
                this.networkEventListener?.tableCreationRequired();
        } else {
            const tableCheckState = await this.checkTableExistsAndMembership(tableID, player.id);
            switch (tableCheckState) {
                case TableCheckState.NOT_A_MEMBER:
                    this.networkEventListener?.loginRequired();
                    break;
                case TableCheckState.NOT_EXISTING:
                    this.networkEventListener?.tableCreationRequired();
                    break;
                case TableCheckState.OK:
                    await this.websocketService.connectWebsocket(player.id);
                    this.networkEventListener?.loginSuccess(player, tableID);
                    break;
            }
        }
    }

    private async checkTableExists(tableID: string): Promise<boolean> {
        const url = `${this.baseURL}/table/exists?tableID=${tableID}`;
        const response = await submitGETRequest(url);
        const statusCode = response.status;
        if (statusCode === STATUS_OK)
            return true;
        if (statusCode === STATUS_NOTFOUND)
            return false;
        throw new Error(`Unexpected response status checking table existence. Status: ${statusCode}`);
    }

    private async checkTableExistsAndMembership(tableID: string, playerID: string): Promise<TableCheckState> {
        const url = `${this.baseURL}/table/check?tableID=${tableID}&playerID=${playerID}`;
        const response = await submitGETRequest(url);
        if (response.status !== STATUS_OK)
            throw new Error(`Unexpected status while checking table existence and membership. Status: ${response.status}`);
        const tableCheckResult: TableCheckResult = await response.json();
        return tableCheckResult.state!!;
    }

    async joinTable(player: PokerPlayer, tableID: string): Promise<void> {
        const adminToken = this.playerManager.getAdminToken(tableID);
        const url = `${this.baseURL}/table/join`;
        const request = new JoinTableRequest(player, tableID, adminToken);
        const response = await submitPOSTRequest(url, request);
        if (response.status !== STATUS_OK)
            throw new Error(`Unexpected status while joining table. Status: ${response.status}`);
        const playerWithID = PlayerContainer.toPokerPlayer(await response.json());
        this.playerManager.setLocalPlayer(playerWithID, tableID);
        this.networkEventListener?.loginSuccess(playerWithID, tableID);
        this.networkEventListener?.tableJoined(tableID);
        await this.websocketService.connectWebsocket(playerWithID.id!!);
    }

    async switchPlayerRole(tableId: string, player: PokerPlayer): Promise<void> {
        const url = `${this.baseURL}/table/command/switch`;
        const request = SwitchPlayerRoleRequest.newSwitchRoleCommand(tableId, player);
        const response = await submitPOSTRequest(url, request);
        if (response.status !== STATUS_OK)
            throw new Error(`Unexpected status while switching a role. Status: ${response.status}`);
    }

    async switchPlayerRoleByAdmin(tableId: string, player: PokerPlayer): Promise<void> {
        const adminToken = this.playerManager.getAdminToken(tableId)!!;
        const url = `${this.baseURL}/table/command/switch_admin`;
        const request = SwitchPlayerRoleByAdminRequest.newSwitchRoleByAdminCommand(tableId, player, adminToken);
        const response = await submitPOSTRequest(url, request);
        if (response.status !== STATUS_OK)
            throw new Error(`Unexpected status while switching a role. Status: ${response.status}`);
    }

    async playCard(tableID: string, value: PokerCardValue, playedBy: string | null): Promise<void> {
        const playerID = this.playerManager.getLocalPlayer(tableID)!!.id!!
        const url = `${this.baseURL}/table/card`;
        const request = new PlayCardRequest(tableID, playerID, value, playedBy);
        const response = await submitPOSTRequest(url, request);
        if (response.status !== 200)
            throw Error(`Error playing card. Status: ${response.status}`);
    }

    async updateTableState(tableID: string, state: TableState): Promise<void> {
        const adminToken = this.playerManager.getAdminToken(tableID)!!;
        const url = `${this.baseURL}/table/command`;
        const request = TableCommandRequest.newUpdateTableStateCommand(tableID, state, adminToken);
        const response = await submitPOSTRequest(url, request);
        if (response.status !== 200)
            throw Error(`Error applying command. Status: ${response.status}`);
    }

    async deleteVoteFromHistory(tableID: string, votingID: string): Promise<void> {
        const adminToken = this.playerManager.getAdminToken(tableID)!!;
        const url = `${this.baseURL}/table/command/deletevote`
        const request = {tableID: tableID, votingID: votingID, adminToken: adminToken};
        const response = await submitPOSTRequest(url, request);
        if (response.status !== 200)
            throw Error(`Error when trying to delete Vote. Status: ${response.status}`);
    }

    async updateVotingTitle(tableID: string, title: string): Promise<void> {
        const adminToken = this.playerManager.getAdminToken(tableID)!!;
        const url = `${this.baseURL}/table/command/update`;
        const request = {tableID: tableID, votingTitle: title, adminToken: adminToken};
        const response = await submitPOSTRequest(url, request);

        if (response.status === 400)
            throw Error(`Error applying command. Status: ${response.status}`);
    }

    async addHistory(tableID: string, title: string, votingResult: number | string, isHighlighted: boolean): Promise<void> {
        const adminToken = this.playerManager.getAdminToken(tableID)!!;
        const url = `${this.baseURL}/table/command/history`;
        const request = {
            tableID: tableID,
            votingTitle: title,
            votingResult: votingResult,
            isHighlighted: isHighlighted,
            adminToken: adminToken
        };
        const response = await submitPOSTRequest(url, request);

        if (response.status === 400)
            throw Error(`Error applying command. Status: ${response.status}`);
    }

    async revealCards(tableID: string): Promise<void> {
        const adminToken = this.playerManager.getAdminToken(tableID)!!
        const url = `${this.baseURL}/table/command`;
        const request = TableCommandRequest.newRevealCardsCommand(tableID, adminToken);
        const response = await submitPOSTRequest(url, request);
        if (response.status !== 200)
            throw Error(`Error applying command. Status: ${response.status}`);
    }

    async toggleChangeOpenCard(tableID: string): Promise<void> {
        const adminToken = this.playerManager.getAdminToken(tableID)!!
        const url = `${this.baseURL}/table/command`;
        const request = TableCommandRequest.newToggleChangeOpenCard(tableID, adminToken);
        const response = await submitPOSTRequest(url, request);
        if (response.status !== 200)
            throw Error(`Error applying command. Status: ${response.status}`);
    }

    async toggleChangeRandomizeFreeVote(tableID: string): Promise<void> {
        const adminToken = this.playerManager.getAdminToken(tableID)!!
        const url = `${this.baseURL}/table/command`;
        const request = TableCommandRequest.newToggleChangeRandomizeFreeVote(tableID, adminToken);
        const response = await submitPOSTRequest(url, request);
        if (response.status !== 200)
            throw Error(`Error applying command. Status: ${response.status}`);
    }

    async toggleShowHistory(tableID: string): Promise<void> {
        const adminToken = this.playerManager.getAdminToken(tableID)!!
        const url = `${this.baseURL}/table/command/toggleshowhistory`;
        const request = {tableID: tableID, adminToken: adminToken};
        const response = await submitPOSTRequest(url, request);
        if (response.status !== 200)
            throw Error(`Error when trying to toggle showHistory. Status: ${response.status}`)
    }

    async playReminderSound(tableID: string): Promise<void> {
        const adminToken = this.playerManager.getAdminToken(tableID)!!
        const url = `${this.baseURL}/table/command`;
        const request = TableCommandRequest.newPlayReminderSoundCommand(tableID, adminToken);
        const response = await submitPOSTRequest(url, request);
        if (response.status !== 200)
            throw Error(`Error applying command. Status: ${response.status}`);
    }

    async startFreeVote(tableID: string, votingOptions: string[], indices: string[]): Promise<void> {
        const adminToken = this.playerManager.getAdminToken(tableID)!!;
        const url = `${this.baseURL}/table/command/freevote`;
        const request = new FreeVoteRequest(tableID, adminToken, votingOptions, indices);
        const response = await submitPOSTRequest(url, request);
        if (response.status !== 200)
            throw Error(`Error applying command. Status: ${response.status}`);
    }

    async startFreeNumberVote(tableID: string): Promise<void> {
        const adminToken = this.playerManager.getAdminToken(tableID)!!;
        const url = `${this.baseURL}/table/command/freenumbervote`;
        const request = new FreeNumberVoteRequest(tableID, adminToken);
        const response = await submitPOSTRequest(url, request);
        if (response.status !== 200)
            throw Error(`Error applying command. Status: ${response.status}`);
    }

    messageReceived(message: string) {
        const websocketMessage = decodeMessage(message);
        switch (websocketMessage.type) {
            case MessageType.TABLE:
                const pokerTable = TableContainer.toPokerTable(websocketMessage.body as TableContainer);
                this.networkEventListener?.tableUpdated(pokerTable);
                break;
            case MessageType.AUDIO:
                this.networkEventListener?.audioNotificationRequested(websocketMessage.body as AudioType);
                break;
            case MessageType.AVATAR:
                this.networkEventListener?.playerAvatarUpdated(websocketMessage.body as string)
        }
    }

    async uploadAvatar(tableID: string, playerID: string, cognitoID: string | undefined, file: File): Promise<void> {
        let url: string;
        let headers = {}
        if (cognitoID !== undefined) {
            headers = await withIdTokenInAuthenticationHeader({})
            url = this.avatarUploadBaseUrl + `cognitoID=${cognitoID}&filesize=${file.size}`;
        } else {
            url = this.avatarUploadBaseUrl + `playerID=${playerID}&tableID=${tableID}&filesize=${file.size}`;
        }
        const presignedResponse = await submitGETRequest(url, headers);

        if (presignedResponse.status === 429) {
            throw Error(`Request limit reached! Try again later.`)
        }
        if (presignedResponse.status === 401) {
            throw Error(`Unauthorized. This user can't upload avatars!`)
        }
        if (presignedResponse.status === 413) {
            throw Error(`The file is to large. Select a smaller file!`)
        }

        const pair = await presignedResponse.json()
        const s3url = pair.first;


        await fetch(s3url, {
            method: 'PUT',
            body: file
        });
    }

    async deleteAvatar(tableID: string, playerID: string, cognitoID: string | undefined): Promise<void> {
        let url: string
        let headers = {}
        if (cognitoID !== undefined) {
            headers = await withIdTokenInAuthenticationHeader({})
            url = this.avatarDeleteBaseUrl + `cognitoID=${cognitoID}`;
        } else {
            url = this.avatarDeleteBaseUrl + `playerID=${playerID}&tableID=${tableID}`;
        }
        const presignedResponse = await submitGETRequest(url, headers);
        if (presignedResponse.status !== STATUS_OK) {
            if (presignedResponse.status === 401) {
                throw Error(`Unauthorized!`)
            }
            throw Error(`Unexpected problem with deleting an avatar. Status: ${presignedResponse.status}`)
        }

        const pair = await presignedResponse.json()
        const s3url = pair.first;

        await fetch(s3url, {
            method: 'DELETE',
        });
    }

    async updateAvatar(tableId: string, playerId: string): Promise<void> {
        const url = `${this.baseURL}/table/avatar?tableID=${tableId}&playerID=${playerId}`;
        const response = await submitGETRequest(url);
        if (response.status !== STATUS_OK)
            throw new Error(`Unexpected status while requesting an avatar update. Status: ${response.status}`);
    }

    async updatePlayerProfileToDB(playerProfile: ProfileEntry, cognitoID: string, cognitoIdToken: string): Promise<void> {
        const resource = "playersettings"
        const settings = playerProfile.settingsEntry;
        const data = {
            "playerID": cognitoID,
            "environment": this.environment,
            "isAudioNotificationActive": settings.isAudioNotificationActive,
            "isShowTableActive": settings.isShowTableActive,
            "lastTheme": settings.lastTheme,
            "locale": settings.locale,
            "userTheme": playerProfile.userTheme,
        }
        const header = {"Authorization": cognitoIdToken}
        const response = await submitPOSTRequest(this.playerSettingsURL + "/" + resource, data, header);
        if (response.status !== STATUS_OK) {
            throw Error(`Error applying command for getting player profile. Status: ${response.status}`);
        }
    }


    async getPlayerProfileFromDB(cognitoId: string, cognitoIdToken: string): Promise<ProfileEntry | undefined> {
        const header = {"Authorization": cognitoIdToken}
        const resource = "playersettings"
        const queryParam = "playerID"
        const response = await submitGETRequest(this.playerSettingsURL + "/" + resource + "?" + queryParam + "=" + cognitoId + "&" + "environment=" + this.environment, header);
        const responseContent = await response.json();
        if (response.status !== STATUS_OK) {
            throw Error(`Error applying command for updating player profile. Status: ${response.status}`);
        } else if (responseContent.statusCode === 200) {
            const playerProfileFromDB: ProfileEntry = {
                settingsEntry: {
                    isAudioNotificationActive: true,
                    isShowTableActive: true,
                    lastTheme: defaultThemeSource(),
                    locale: "undefined"
                },
                userTheme: undefined
            };
            playerProfileFromDB.settingsEntry.isAudioNotificationActive = responseContent.body.isAudioNotificationActive
            playerProfileFromDB.settingsEntry.isShowTableActive = responseContent.body.isShowTableActive
            playerProfileFromDB.settingsEntry.lastTheme = responseContent.body.lastTheme;
            playerProfileFromDB.settingsEntry.locale = responseContent.body.locale
            if (responseContent.body.hasOwnProperty("userTheme")) {
                playerProfileFromDB.userTheme = responseContent.body.userTheme;
            }
            return playerProfileFromDB;
        }
        return undefined;
    }


}
