import React, {useCallback, useEffect, useState} from "react"
import {Box} from "@material-ui/core";
import {observer} from "mobx-react-lite";
import {runInAction} from "mobx";
import {useStores} from "../../hooks";

interface Props {
    playerId: string | undefined,
    cognitoId: string | undefined,
    tableId: string,
    useGuestS3: boolean,
    defaultIconLink: string,
    factor: number,
}

const PlayerAvatarComponent: React.FC<Props> = (props) => {
    const {playerId, cognitoId, tableId, defaultIconLink, factor, useGuestS3} = props
    const [iconUrl, setIconUrl] = useState<string>(defaultIconLink)
    const {avatarChangeStore} = useStores()

    const getPlayerIconFromS3 = useCallback(async () => {
        const randomSuffix = Math.floor((Math.random() * 10000) + 1);
        const iconLink = cognitoId !== undefined && !useGuestS3
            ? process.env.REACT_APP_AVATAR_S3_BUCKET + `/Registered_Users/${cognitoId}?${randomSuffix}`
            : process.env.REACT_APP_AVATAR_S3_BUCKET + `/Guest_Users/${tableId}/${playerId}`
        return fetch(iconLink).then(
            response => {
                const type = response.headers.get("Content-Type")
                if (type !== null && type.startsWith("image")) {
                    return response
                }
                else throw Error("Avatar retrieved is not an image.")
            }
        ).then(
            response => response.blob()
        ).then(
            blob => URL.createObjectURL(blob)
        ).then(
            url => setIconUrl(url)
        ).catch(
            () => setIconUrl(defaultIconLink)
        )
    },[playerId, cognitoId, tableId, defaultIconLink, useGuestS3, setIconUrl])

    const getPlayerIcon = useCallback(() => {
        if(playerId !== undefined && avatarChangeStore.updatedPlayers.includes(playerId)) {
            getPlayerIconFromS3()
            runInAction(() => avatarChangeStore.removeUpdatedPlayer(playerId))
        }

        return <img src={iconUrl} width={48} height={48} alt={"Player avatar"} />
    }, [avatarChangeStore, getPlayerIconFromS3, iconUrl, playerId])

    useEffect(() => {
        const initAvatar = async ()=>{
            await getPlayerIconFromS3()
        }
       initAvatar();
    }, [getPlayerIconFromS3, avatarChangeStore])

    return (
    <Box clone fontSize={48 * factor}>
        {getPlayerIcon()}
    </Box>
    )
}

export default observer(PlayerAvatarComponent)
