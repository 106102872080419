import React, {useCallback, useState} from "react";

const usePanelHeightCalculator = () => {
    const [titlePanelHeight, setTitlePanelHeight] = useState<number>(0);
    const [adminControlsHeight, setAdminControlsHeight] = useState<number>(0);
    const [votingOptionsHeight, setVotingOptionsHeight] = useState<number>(0);
    const [freeNumberVoteResultsHeight, setFreeNumberVoteResultsHeight] = useState<number>(0);
    const [observerComponentHeight, setObserverComponentHeight] = useState<number>(0);
    const [settingsPanelComponentHeight, setSettingsPanelComponentHeight] = useState<number>(0);

    /* Calculating the Height of the Elements above the History */
    const calculatePanelHeight = useCallback( (): number => {
        return titlePanelHeight +
            adminControlsHeight +
            votingOptionsHeight +
            freeNumberVoteResultsHeight +
            observerComponentHeight +
            settingsPanelComponentHeight;
    },[
        titlePanelHeight,
        adminControlsHeight,
        votingOptionsHeight,
        freeNumberVoteResultsHeight,
        observerComponentHeight,
        settingsPanelComponentHeight
    ]);

    return {
        setTitlePanelHeight,
        setAdminControlsHeight,
        setVotingOptionsHeight,
        setFreeNumberVoteResultsHeight,
        setObserverComponentHeight,
        setSettingsPanelComponentHeight,
        calculatePanelHeight
    };
}

export default usePanelHeightCalculator;
