import SockJS from "sockjs-client"
import logger from "../../../util/Logger";
import {encodeLoginMessage, LoginMessage} from "./message/LoginMessage";

const CLOSE_STATUS_NORMAL = 1000;

export default class WebSocketService {

    private readonly baseURL: string
    private websocket?: WebSocket
    private listener: WebsocketListener

    constructor(baseURL: string, listener: WebsocketListener) {
        this.baseURL = baseURL;
        this.listener = listener;
    }

    async connectWebsocket(playerID: string): Promise<void> {
        const loginMessage = new LoginMessage(playerID);
        this.websocket = new SockJS(`${this.baseURL}/socket`, null,
            {transports: ['xhr-streaming', 'xhr-polling']});
        this.websocket.onopen = () => {
            if (!this.websocket)
                return Promise.reject();
            this.websocket?.send(encodeLoginMessage(loginMessage));
            logger.info("Websocket connected");
            return Promise.resolve();
        }
        this.websocket.onerror = (err) => {
            logger.error(`Error on websocket. Closing... Error: ${err}`);
        };
        this.websocket.onclose = (event) => {
            logger.warn(`Websocket closed. Reason: ${event.code}`);
            if (event.code !== CLOSE_STATUS_NORMAL) {
                logger.warn("Reconnecting websocket...");
                this.connectWebsocket(playerID);
            }
        };
        this.websocket.onmessage = (event: MessageEvent) => {
            const message = event.data;
            logger.info(`Websocket received message: ${message}`);
            this.receiveWebsocketMessage(message);
        };
    }

    private receiveWebsocketMessage(message: string) {
        this.listener.messageReceived(message);
    }

}

export interface WebsocketListener {
    messageReceived(message: string): void
}