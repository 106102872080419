export enum VotingOptionsFilters {
  ALL,
  TOP_SIXTY_PERCENT,
  TOP_FIFTY_PERCENT,
  TOP_FORTY_PERCENT,
  TOP_THIRTY_PERCENT,
  TOP_FIVE,
  TOP_FOUR,
  TOP_THREE,
  TOP_TWO,
}
