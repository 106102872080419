import React, {useState} from 'react';
import "./stylesheets/StandardStylesheet.css";
import HomePage from "./pages/HomePage";
import MainController from "./controller/control/MainController";
import {ThemeProvider} from "@material-ui/core";
import pokerTheme from "./stylesheets/poker_theme";
import ControllerContext from "./contexts/ControllerContext";
import './AmplifyConfig'
import UserProvider from "./contexts/AuthorizationContext";
import ThemeController from "./controller/control/ThemeController";
import { createHttpLink } from "apollo-link-http";
import {AuthClass} from "@aws-amplify/auth/lib/Auth";
import {ApolloClient, ApolloLink, ApolloProvider, InMemoryCache} from "@apollo/client";
import {AUTH_TYPE, createAuthLink} from "aws-appsync-auth-link";
import {createSubscriptionHandshakeLink} from "aws-appsync-subscription-link";

const authConfig = {
    'region': process.env.REACT_APP_AWS_REGION,
    'identityPoolId': process.env.REACT_APP_AWS_COGNITO_IDENTITIY_POOL_ID //This currently refers to the R2G Identity Pool for testing purposes, as no P2G Pool exists (yet).
}
const url = process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT;
const region = process.env.REACT_APP_AWS_REGION;
const auth = {
    type: AUTH_TYPE.AWS_IAM,
    credentials: () => new AuthClass(authConfig).currentCredentials()
};
const httpLink = createHttpLink(({uri: url}));

const link = ApolloLink.from([
    // @ts-ignore
    createAuthLink({url, region, auth}),
    // @ts-ignore
    createSubscriptionHandshakeLink({url, region, auth}, httpLink)
]);

export const client = new ApolloClient({
    link,
    cache: new InMemoryCache({addTypename: false})
});

const App: React.FC = () => {
    const [mainController] = useState(new MainController());
    const [themeController] = useState(new ThemeController());

    const controllerContextValue = {
        mainController: mainController,
        themeController: themeController
    }

    return (
        <ThemeProvider theme={pokerTheme}>
            <ApolloProvider client={client}>
                <ControllerContext.Provider value={controllerContextValue}>
                    <UserProvider>
                        <HomePage />
                    </UserProvider>
                </ControllerContext.Provider>
            </ApolloProvider>
        </ThemeProvider>

    )
}

export default App;
