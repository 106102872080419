import {PlayerContainer} from "./PlayerContainer";
import PokerTable, {VotingResult} from "../../../../model/PokerTable";
import {tableStateFromString, votingTypeFromString} from "../convert";
import PokerCard from "../../../../model/PokerCard";

export default class TableContainer {
    id?: string
    admin?: PlayerContainer
    name?: string
    voterPlayers?: PlayerContainer[]
    observerPlayers?: PlayerContainer[]
    disconnectedPlayers?: PlayerContainer[]
    cardsOpen?: boolean = false
    cardsWereAutomaticallyRevealedRecently?: boolean = false
    showHistory?: boolean = true
    state?: string
    votingType?: string
    votingOptions?: string[] = []
    votingOptionsIndex?: string[] = []
    changeOpenCardEnabled?: boolean = true
    randomizeFreeVoteEnabled?: boolean = false
    lastVotingCards?: PokerCard[] = []
    votingTitle?: string
    history: VotingResult [] = []

    static toPokerTable(container: TableContainer) {
        const table = new PokerTable();
        table.id = container.id!!;
        table.admin = container.admin !== undefined ? PlayerContainer.toPokerPlayer(container.admin) : undefined;
        table.name = container.name!!;
        container.voterPlayers?.forEach(playerContainer => {
            table.addVoterPlayer(PlayerContainer.toPokerPlayer(playerContainer));
        });
        container.observerPlayers?.forEach(playerContainer => {
            table.addObserverPlayer(PlayerContainer.toPokerPlayer(playerContainer));
        });
        container.disconnectedPlayers?.forEach(playerContainer => {
            table.addDisconnectedPlayer(PlayerContainer.toPokerPlayer(playerContainer));
        });
        table.cardsRevealed = container.cardsOpen!!;
        table.cardsWereAutomaticallyRevealedRecently = container.cardsWereAutomaticallyRevealedRecently!!;
        table.showHistory = container.showHistory!!;
        table.state = tableStateFromString(container.state!!);
        table.votingType = votingTypeFromString(container.votingType!!)
        table.votingOptions = container.votingOptions!!
        table.votingOptionsIndex = container.votingOptionsIndex!!
        table.changeOpenCardEnabled = container.changeOpenCardEnabled!!
        table.randomizeFreeVoteEnabled = container.randomizeFreeVoteEnabled!!
        table.lastVotingCards = container.lastVotingCards!!
        table.votingTitle = container.votingTitle
        return table;
    }
}
