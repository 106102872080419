import React from "react";
import Card from "./Card";
import {Button, Tooltip} from "@material-ui/core";
import MainController from "../../controller/control/MainController";
import logger from "../../util/Logger";
import {makeStyles} from "@material-ui/core/styles";
import {asCssRGBAString, PokerTheme} from "../../model/PokerTheme";
import {PokerCardValue} from "../../model/PokerCard";

interface Props {
    cardID: PokerCardValue|null
    selected: boolean
    controller: MainController
    tableID: string
    cardValue: number | string
    onSelect: (cardID: PokerCardValue|null) => void
    tooltipText: string,
    typeSubmit: boolean,
    pokerTheme: PokerTheme
    disabled: boolean
    id: string
}

const useStyles = makeStyles(() => ({
    cardButton: {
        display: "flex",
        width: "42px",
        minWidth: "42px",
        height: "70px",
        borderRadius: "8px",
        margin: "5px 6px 5px 6px",
        padding: "4px",
        boxShadow: "0 0 3px 0 rgba(0,0,0,0.3)",
    },
    cardButtonSelected: (pTheme:PokerTheme) => ({
        backgroundColor: asCssRGBAString(pTheme.card.highlightSelected),
        border: "1px solid black",
        "&:hover": {
            backgroundColor: asCssRGBAString(pTheme.card.highlightSelected),
        },
    }),
    cardButtonNotSelected: (pTheme:PokerTheme) => ({
        backgroundColor: asCssRGBAString(pTheme.card.backgroundFront),
        border: "1px solid #b5b5b5",
        "&:hover": {
            backgroundColor: asCssRGBAString(pTheme.card.highlight),
        },
    }),
    cardButtonHighlighter: (pTheme:PokerTheme) => ({
        "&:hover": {
            backgroundColor: asCssRGBAString(pTheme.card.shadow),
            color: "black",
            borderRadius: "8px",
        },
    })
}))

export const CardButton: React.FC<Props> = (props) => {
    const {
        onSelect,
        cardID,
        controller,
        tableID,
        cardValue,
        selected,
        tooltipText,
        typeSubmit,
        pokerTheme,
        disabled,
        id
    } = props;

    const classes = useStyles(pokerTheme)

    let onClick = () => {
        onSelect(cardID);
        controller.playCard(tableID, cardValue).catch(err => logger.error(err));
    }
    return (
        <div className={`${disabled ? '' : classes.cardButtonHighlighter}`} id={id}>
            <Tooltip title={tooltipText}>
                <Button
                    className={`${ classes.cardButton } ${
                        selected ? classes.cardButtonSelected : classes.cardButtonNotSelected
                    }`}
                    disabled={disabled}
                    onClick={typeSubmit ? undefined : onClick}
                    type={typeSubmit ? "submit" : undefined}
                >
                    <Card
                        cardvalue={cardValue}
                        revealed={true}
                        color={asCssRGBAString(pokerTheme.card.text)}
                    />
                </Button>
            </Tooltip>
        </div>
    );
}

export default CardButton