import {ThemeDefinition, ThemeList, ThemeSource, ThemeSourceTypes} from "../../service/ThemeService";
import StorageManager from "./StorageManager";
import NetworkAdapter from "../network/NetworkAdapter";
import PlayerManager from "./PlayerManager";


export default class ThemeController {

    private readonly storageManager: StorageManager
    private readonly networkAdapter: NetworkAdapter

    constructor() {
        this.storageManager = new StorageManager();
        this.networkAdapter = new NetworkAdapter(new PlayerManager(this.storageManager), this.storageManager);
    }


    setThemeSourceSetting(themeSource: ThemeSource) {
        if (themeSource._tag === ThemeSourceTypes.CUSTOM)
            this.storageManager.setPokerThemeForSession(themeSource.tableID, themeSource);
        else if (themeSource._tag === ThemeSourceTypes.USER)
            this.storageManager.setPokerThemeForUser(themeSource.cognitoID, themeSource)
        else if (themeSource._tag === ThemeSourceTypes.PUBLIC || themeSource._tag === ThemeSourceTypes.DEFAULT) {
            let settings = this.storageManager.getSettings()
            settings.lastTheme = themeSource
            this.storageManager.setSettings(settings)
            this.storageManager.unsetUserThemeForUser()
            this.storageManager.unsetCustomThemeForGuest()
        }
    }

    getThemeSourceSettingForGuest(): ThemeSource {
        return this.storageManager.getPokerThemeForGuest()
    }

    getThemeSourceSettingForUser(cognitoID: string | undefined): ThemeSource {
        let themeSource = this.storageManager.getSettings().lastTheme;
        if (!!cognitoID) {
            themeSource = this.storageManager.getPokerThemeForUser(cognitoID);
        }
        return themeSource;
    }

    async uploadTheme(themeSource: ThemeSource, file: File): Promise<void> {
        return this.networkAdapter.uploadTheme(themeSource, file)
    }

    async deleteTheme(themeSource: ThemeSource): Promise<void> {
        return this.networkAdapter.deleteTheme(themeSource)
    }

    async loadCustomThemes(tableID: string, playerID: string): Promise<Map<string, ThemeDefinition>> {
        return this.networkAdapter.loadCustomThemes(tableID, playerID)
    }

    async loadUserThemes(cognitoID: string): Promise<Map<string, ThemeDefinition>> {
        return this.networkAdapter.loadUserThemes(cognitoID)
    }

    async loadCustomAndUserThemes(tableID: string, playerID: string, cognitoID: string): Promise<Map<string, ThemeDefinition>> {
        return this.networkAdapter.loadCustomAndUserThemes(tableID, playerID, cognitoID);
    }

    async getThemeDefinition(source: ThemeSource): Promise<ThemeDefinition> {
        return this.networkAdapter.getThemeDefinition(source)
    }

    async loadPublicThemes(themeListJson: string): Promise<ThemeList> {
        return this.networkAdapter.loadPublicThemes(themeListJson)
    }

    unsetUserThemeForUser() {
        this.storageManager.unsetUserThemeForUser()
    }
}
