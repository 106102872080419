export enum LogLevel {
    INFO = 0,
    WARN = 1,
    ERROR = 2
}

const LOGLEVEL_INFO = "INFO";
const LOGLEVEL_WARN = "WARN";
const LOGLEVEL_ERROR = "ERROR";

export class Logger {

    private readonly logLevel: LogLevel

    protected constructor(logLevel: LogLevel = LogLevel.WARN) {
        this.logLevel = logLevel;
    }

    static newLogger(logLevel: string) {
        switch (logLevel) {
            case LOGLEVEL_INFO:
                return new Logger(LogLevel.INFO);
            case LOGLEVEL_WARN:
                return new Logger(LogLevel.WARN);
            case LOGLEVEL_ERROR:
            default:
                return new Logger(LogLevel.ERROR);
        }
    }

    info(msg: string) {
        if (this.isLoggingEnabledForLevel(LogLevel.INFO))
            console.info(msg);
    }

    warn(msg: string) {
        if (this.isLoggingEnabledForLevel(LogLevel.WARN))
            console.warn(msg);
    }

    error(msg: string) {
        if (this.isLoggingEnabledForLevel(LogLevel.ERROR))
            console.error(msg);
    }

    private isLoggingEnabledForLevel(level: LogLevel): boolean {
        return this.logLevel <= level;
    }

}

export default Logger.newLogger(process.env.REACT_APP_LOGLEVEL!);