import gql from "graphql-tag";

export const GET_VOTING_INFO = gql`
query getVotingInfo($tableId: String!, $votingId: String!) {
  getP2GHistory(tableId: $tableId, votingId: $votingId) {
    tableId
    votingId
    votingTitle
    votingOptions
    indexForVotingOptions
    votesForOptions
    type
  }
}
`;

export const GET_COMPLETE_HISTORY = gql`
query getCompleteHistory($tableId: String!) {
    listP2GHistories(tableId: $tableId){
        items{
            tableId
            votingId
            votingTitle
            votingOptions
            indexForVotingOptions
            votesForOptions
            type
        }
        nextToken    
    }
}
    
    
`;