import React from "react";
import {CircularProgress} from "@material-ui/core";
import {FormattedMessage} from "react-intl";

export const LoadingPage: React.FC = () => {
    return <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        marginTop: "150px"}}>
        <CircularProgress size={150} thickness={5}/>
        <h2 style={{margin: "20px"}}><FormattedMessage id={"loading_page-text-please_wait"}/></h2>
    </div>
}

export default LoadingPage;