export default class FreeVoteRequest {
    tableID: string;
    adminToken: string;
    votingOptions: string[];
    optionsIndex: string[];

    constructor(tableID: string, adminToken: string, votingOptions: string[], optionsIndex: string[] = []) {
        this.tableID = tableID;
        this.adminToken = adminToken;
        this.votingOptions = votingOptions;
        this.optionsIndex = optionsIndex;
    }
}
