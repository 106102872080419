import PokerPlayer from "../../model/PokerPlayer";
import {defaultThemeSource, ThemeSource} from "../../service/ThemeService";
import {getCurrentUser} from "@2gether/frontend-library";
import {getTableIDFromUrl} from "../../util/Helper";


const ADMIN_TOKEN = "adminTokenList";
const PLAYER_SETTINGS = "playerSettings";
const PLAYER = "player";
export const USER_THEME_KEY_PREFIX = "user-table-theme-";


interface PlayerEntry {
    timestamp: number;
    value: PokerPlayer;
}

interface AdminTokenEntry {
    timestamp: number;
    value: string;
}

export interface SettingsEntry {
    isAudioNotificationActive: boolean
    isShowTableActive: boolean
    locale: string
    lastTheme: ThemeSource
}

export interface ProfileEntry{
    settingsEntry: SettingsEntry
    userTheme?: ThemeSource
}

export const getDefaultSettings = (): SettingsEntry => {
    return {
        isAudioNotificationActive: true,
        isShowTableActive: true,
        locale: "undefined",
        lastTheme: defaultThemeSource()
    }
}

export default class StorageManager {
    getAdminToken(tableId: string): string | undefined {
        return this.getAdminTokenListFromLocalStorage().get(tableId)?.value;
    }

    setAdminToken(token: string | undefined, tableId: string) {
        let map = this.getAdminTokenListFromLocalStorage();
        if (token !== undefined) {
            map.set(tableId, {
                timestamp: (new Date()).getTime(),
                value: token
            });
            map = this.removeOldEntries(map);
        } else {
            map.delete(tableId)
        }
        this.setAdminTokenListInLocalStorage(map);
    }

    getSettings(): SettingsEntry {
        let value = localStorage.getItem(PLAYER_SETTINGS)
        let settings: SettingsEntry = getDefaultSettings()
        if (value === null) {
            return settings
        }
        try {
            settings = JSON.parse(value)
        } catch {
            this.setSettings(getDefaultSettings())
            return getDefaultSettings()
        }
        settings = this.repairSettings(settings)
        return settings
    }

    getProfile(cognitoID: string | undefined): ProfileEntry {
        let userTheme: ThemeSource | undefined;
        if (!!cognitoID){
             userTheme = this.getUserThemeForUser(cognitoID);
        }
        else {
            userTheme = undefined;
        }
        return {"settingsEntry": this.getSettings(), "userTheme": userTheme};
    }

    setSettings(value: SettingsEntry) {
        localStorage.setItem(PLAYER_SETTINGS, JSON.stringify(value))
    }

    setProfile(profile: ProfileEntry, cognitoID: string | undefined) {
        this.setSettings(profile.settingsEntry);
        if(cognitoID !== undefined && profile.userTheme !== undefined){
            this.setPokerThemeForUser(cognitoID, profile.userTheme);
        }
    }

    getPokerThemeForGuest(): ThemeSource {
        const lastDefaultTheme = this.getSettings().lastTheme;
        const tableID = getTableIDFromUrl();
        let customTheme
        if (!!tableID && (customTheme=sessionStorage.getItem(tableID)) != null) {
            try {
                const customThemeObject = JSON.parse(customTheme)
                if (customThemeObject.hasOwnProperty("_tag")) {
                    return customThemeObject;
                }
            }
            catch (e) {
                sessionStorage.removeItem(tableID);
            }
        }
        return lastDefaultTheme;
    }

    getUserThemeForUser(cognitoID: string): ThemeSource | undefined {
        let userTheme = localStorage.getItem(USER_THEME_KEY_PREFIX + cognitoID);
        return userTheme ? JSON.parse(userTheme) : undefined;
    }

    getPokerThemeForUser(cognitoID: string): ThemeSource {
        let lastPublicTheme = this.getSettings().lastTheme;
        let userTheme = this.getUserThemeForUser(cognitoID);
        return userTheme ? userTheme : lastPublicTheme;
    }

    setPokerThemeForSession(tableId: string, source: ThemeSource) {
        sessionStorage.setItem(tableId, JSON.stringify(source))
    }

    setPokerThemeForUser(cognitoID: string, source: ThemeSource) {
        localStorage.setItem(USER_THEME_KEY_PREFIX + cognitoID, JSON.stringify(source))
    }

    unsetUserThemeForUser() {
        getCurrentUser()
            .then(user =>  localStorage.removeItem(USER_THEME_KEY_PREFIX + user.username))
    }

    unsetCustomThemeForGuest() {
        const tableID = getTableIDFromUrl();
        if(!!tableID) {
            sessionStorage.removeItem(tableID);
        }
    }

    getPlayer(tableId: string) {
        return this.getPlayerListFromLocalStorage().get(tableId)?.value;
    }

    addPlayer(player: PokerPlayer, tableId: string) {
        this.addPlayerToList(player, tableId);
    }


    private static readConverter(value: string | null) {
        if (value === null) {
            return new Map<string, PlayerEntry>();
        }
        let parsedValue = JSON.parse(value);
        if (parsedValue === undefined) {
            return new Map<string, PlayerEntry>();
        }
        let map = new Map<string, PlayerEntry>()
        for (var key in parsedValue) {
            map.set(key,parsedValue[key])
        }
        return map;
    }

    private writeConverter(map: Map<string, PlayerEntry>) {
        let jsonObject = {};
        map.forEach((value, key) => {
            // @ts-ignore
            jsonObject[key] = value
        });
        return JSON.stringify(jsonObject);
    }

    private getAdminTokenListFromLocalStorage(): Map<string,AdminTokenEntry> {
        return StorageManager.readAdminTokenConverter(localStorage.getItem(ADMIN_TOKEN))
    }

    private setAdminTokenListInLocalStorage(tokenValue: Map<string, AdminTokenEntry>) {
        localStorage.setItem(ADMIN_TOKEN, this.writeAdminTokenConverter(tokenValue));
    }

    private getPlayerListFromLocalStorage(): Map<string, PlayerEntry> {
        return StorageManager.readConverter(localStorage.getItem(PLAYER));
    }

    private setPlayerListInLocalStorage(tokenValue: Map<string, PlayerEntry>) {
        localStorage.setItem(PLAYER, this.writeConverter(tokenValue));
    }

    private addPlayerToList(player: PokerPlayer | undefined, tableId: string) {
        let map = this.getPlayerListFromLocalStorage();
        if (player !== undefined) {
            map.set(tableId, {
                timestamp: (new Date()).getTime(),
                value: player
            });
            map = this.removeOldEntries(map);
        } else {
            map.delete(tableId)
        }
        this.setPlayerListInLocalStorage(map);
    }

    private static readAdminTokenConverter(value: string | null) {
        if (value === null) {
            return new Map<string, AdminTokenEntry>();
        }
        let parsedValue = JSON.parse(value);
        if (parsedValue === undefined) {
            return new Map<string, AdminTokenEntry>();
        }
        let map = new Map<string, AdminTokenEntry>()
        for (var key in parsedValue) {
            map.set(key,parsedValue[key])
        }
        return map;
    }

    private writeAdminTokenConverter(map: Map<string, AdminTokenEntry>) {
        let jsonObject = {};
        map.forEach((value, key) => {
            // @ts-ignore
            jsonObject[key] = value
        });
        return JSON.stringify(jsonObject);
    }

    private removeOldEntries(map: Map<string, any>) {
        // keep newest 10 entries
        const newMap = new Map(map);
        const entriesSorted = [...newMap.entries()]
            .sort(([,a],[,b]) => a.timestamp - b.timestamp);
        const oldEntries = entriesSorted.slice(0, Math.max(0, entriesSorted.length - 10));
        oldEntries.map(entry => entry[0])
            .forEach(key => newMap.delete(key));
        return newMap;
    }

    private repairSettings = (settings: SettingsEntry) => {
        if (typeof settings.isAudioNotificationActive !== "boolean") {
            settings.isAudioNotificationActive = getDefaultSettings().isAudioNotificationActive
        }
        if (typeof settings.isShowTableActive !== "boolean") {
            settings.isShowTableActive = getDefaultSettings().isShowTableActive
        }
        if (typeof settings.locale !== "string") {
            settings.locale = getDefaultSettings().locale
        }
        if (settings.lastTheme == undefined || !settings.lastTheme.hasOwnProperty("_tag")) {
            settings.lastTheme = getDefaultSettings().lastTheme
        }
        return settings
    }
}
