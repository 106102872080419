import React, {useCallback} from "react";
import PokerTable from "../../model/PokerTable";
import PokerPlayer from "../../model/PokerPlayer";
import {PlayerCard} from "./PlayerCard";
import {PokerTheme} from "../../model/PokerTheme";
import {PokerCardValue} from "../../model/PokerCard";

interface CardsAbsProps {
    factor: number;
    table: PokerTable;
    selfPlayer: PokerPlayer;
    onCardClicked: (value: PokerCardValue, playedBy: string | undefined) => void;
    pokerTheme: PokerTheme
}

const CardsAbs: React.FC<CardsAbsProps> = (props) => {
    const {factor, table, onCardClicked, selfPlayer} = props;

    const isClickable = useCallback((value: PokerCardValue | undefined): boolean => {

        return (
            table.isChangeOpenCardEnabled()
            && selfPlayer.isAllowedToVote()
            && value !== undefined
            && value !== selfPlayer.playedCardValue);
    }, [selfPlayer, table])

    const onClick = useCallback((value: PokerCardValue | undefined, id: string) => {
        if (value !== undefined && isClickable(value))
            onCardClicked(value, id);
    }, [onCardClicked, isClickable])

    const getPlayedByIndex = (playerId: string|null): number|null => {
        if (playerId === null)
            return null;
        const players = table.getPlayersAtTable();
        const index = players.findIndex((player => player.id === playerId))
        if (index === -1)
            return null;
        return index;
    }

    return (
        <div id={"cardsdiv"}>
            {table.getPlayersAtTable().map((player: PokerPlayer, i) => {
                return <PlayerCard factor={factor}
                                   value={player.playedCardValue}
                                   playedByIndex={getPlayedByIndex(player.cardPlayedBy)}
                                   onClick={() => onClick(player.playedCardValue, player.id!! )}
                                   revealed={table.cardsRevealed}
                                   id={player.id!!}
                                   clickable={isClickable(player.playedCardValue)}
                                   index={i}
                                   pokerTheme={props.pokerTheme}
                                   key={i}
                />
            })
            }
        </div>
    )
}

export default CardsAbs;