import {TableState, VotingType} from "../../../model/PokerTable";
import {Role} from "../../../model/PokerPlayer";
import {PokerCardValue} from "../../../model/PokerCard";

enum ProtocolTableState {
    VOTING_ENABLED = "VOTING_ENABLED",
    VOTING_DISABLED = "VOTING_DISABLED"
}

enum ProtocolVotingType {
    FIBONACCI = "FIBONACCI",
    FREE_VOTE = "FREE_VOTE",
    FREE_NUMBER = "FREE_NUMBER"
}

enum ProtocolRole {
    VOTER = "VOTER",
    OBSERVER = "OBSERVER",
    FREE_PLACE = "FREE_PLACE"
}

export function roleToString(role: Role): string {
    switch (role) {
        case Role.VOTER:
            return ProtocolRole.VOTER;
        case Role.OBSERVER:
            return ProtocolRole.OBSERVER;
        case Role.FREE_PLACE:
            return ProtocolRole.FREE_PLACE;
    }
}

export function roleFromString(role: string) {
    switch (role) {
        case ProtocolRole.VOTER:
            return Role.VOTER;
        case ProtocolRole.OBSERVER:
            return Role.OBSERVER;
        case ProtocolRole.FREE_PLACE:
            return Role.FREE_PLACE;
        default:
            throw Error(`Unknown role: ${role}`);
    }
}

export function tableStateFromString(state: string) {
    switch (state) {
        case ProtocolTableState.VOTING_ENABLED:
            return TableState.VOTING_ENABLED;
        case ProtocolTableState.VOTING_DISABLED:
            return TableState.VOTING_DISABLED;
        default:
            throw new Error(`Unknown state: ${state}`);
    }
}

export function votingTypeFromString(votingType: string) {
    switch (votingType) {
        case ProtocolVotingType.FIBONACCI:
            return VotingType.FIBONACCI;
        case ProtocolVotingType.FREE_VOTE:
            return VotingType.FREE_VOTE;
        case ProtocolVotingType.FREE_NUMBER:
            return VotingType.FREE_NUMBER;
        default:
            throw new Error(`Unknown votingType: ${votingType}`);
    }
}

export function cardValueToString(cardValue: PokerCardValue) {
    return cardValue.toString()
}

export function cardValueFromString(value: string): PokerCardValue {
    const n = +value
    if (isNaN(n)) {
        return value;
    }
    return n

}