import React from 'react'
import {createStyles, FormControl, makeStyles, NativeSelect} from "@material-ui/core";

interface Props {
    onChange(event: object): void

    options: string[]
}

const useStyles = makeStyles((theme) =>
    createStyles({
        selectField: {width: "200px", margin: "10px !important"},
    })
);

export default function RoleSelectionComponent(props: Props) {

    const styles = useStyles();
    const {onChange, options} = props

    return (
        <FormControl
            className={styles.selectField}
            color={"primary"}>
            <NativeSelect
                onChange={onChange}>
                {
                    options.map((opt, index) => <option key={index}>{opt}</option>)
                }
            </NativeSelect>
        </FormControl>
    )
}