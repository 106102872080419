import React from "react";
import {PokerTheme} from "../../model/PokerTheme";
import {PokerPanel} from "./PokerPanel";
import PokerToggle from "./PokerToggle";
import LanguageSelection from "./LanguageSelectionComponent";
import {useIntl} from "react-intl";
import PokerPlayer from "../../model/PokerPlayer";
import PokerTable from "../../model/PokerTable";
import MainController from "../../controller/control/MainController";

interface Props {
    changeAudioNotificationStatus: () => void
    isAudioNotificationActive: boolean
    changeShowTableStatus: () => void
    isShowTableActive: boolean
    selectedLanguage: string
    setSelectedLanguage: (selectedLanguage: string) => void
    pokerTheme: PokerTheme
    renderRoleComponent: () => void
    renderThemeComponent: () => void
    renderAvatarComponent: () => void
    player: PokerPlayer
    table: PokerTable
    controller: MainController
}

export const SettingsPanelComponent: React.FC<Props> = (props) => {
    const intl = useIntl()

    return (
        <PokerPanel content={[
            props.renderRoleComponent(),
            props.renderThemeComponent(),
            props.renderAvatarComponent(),
            <PokerToggle
                id={"settings_panel_component-toggle-play_audio"}
                onChange={props.changeAudioNotificationStatus}
                checked={props.isAudioNotificationActive}
                text={intl.formatMessage({id: "settings_panel_component-text-play_audio"})}
                flexJustifyContent={"flex-start"}
            />,
            <PokerToggle
                id={"settings_panel_component-toggle-show_table"}
                onChange={props.changeShowTableStatus}
                checked={props.isShowTableActive}
                text={intl.formatMessage({id: "settings_panel_component-text-show_table"})}
                flexJustifyContent={"flex-start"}
            />,
            <LanguageSelection
                setSelectedLanguage={props.setSelectedLanguage}
                selectedLanguage={props.selectedLanguage}
            />,

        ]} pokerTheme={props.pokerTheme} />
    );
}