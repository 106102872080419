import React, {useCallback, useEffect, useMemo, useState} from "react";
import PokerPlayer, {Role} from "../../model/PokerPlayer";
import MainController from "../../controller/control/MainController";
import logger from "../../util/Logger";
import PokerTable from "../../model/PokerTable";
import {PokerTheme} from "../../model/PokerTheme";
import {PokerButton} from "./PokerButton";
import {useIntl} from "react-intl";

interface Props {
    player: PokerPlayer
    controller: MainController
    tableId: string
    table: PokerTable,
    pokerTheme: PokerTheme
}

const TABLE_CAPACITY = 16;

export const RoleComponent: React.FC<Props> = (props) => {
    const {
        player,
        controller,
        tableId,
        table,
        pokerTheme
    } = props

    const [switchAllowed, setSwitchAllowed] = useState<boolean>(false)
    const intl = useIntl();

    useEffect(
        () => {
            const maxCapacityReached = table.getVotersAndEmptySeatsWithCards().length >= TABLE_CAPACITY;
            const hasSeatAtTable = table.getFreePlaces().some(place => place.id === player.id)
            setSwitchAllowed(player.role === Role.VOTER || !maxCapacityReached || hasSeatAtTable)
        },
        [player, table]
    )

    const buttonText = useMemo(
        () => {
            return player.role !== Role.VOTER ?
                intl.formatMessage({id: "role_component-text-to_voter"})
                : intl.formatMessage({id: "role_component-text-to_observer"});
        },
        [player, intl]
    )

    const switchPlayerRole = useCallback(
        () => {
            controller.switchPlayerRole(tableId, player).catch(err => logger.error(err));
        },
        [controller, tableId, player]
    )

    return (
        <>
            {switchAllowed && (
                    <PokerButton
                        onClick={switchPlayerRole}
                        disabled={false}
                        variant={"contained"}
                        text={buttonText}
                        pokerTheme={pokerTheme}/>
            )}
        </>
    );
}
