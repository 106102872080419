import {createStyles, FormControlLabel, Grid, Radio} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {FormattedMessage} from "react-intl";

interface Props {
    value: string,
    label: string,
    numberOfOptions: number,
    onChange: () => void
}

const useStyles = makeStyles(() =>
    createStyles({
        optionsContainer: { alignItems: 'center' },
        optionSizeNumber: {
            color: 'rgb(148, 148, 148)',
        },
    })
);

const RepeatFreeVoteOption: React.FC<Props> = (props) => {
    const {value, label, numberOfOptions, onChange} = props
    const styles = useStyles()

    return (
        <Grid container className={styles.optionsContainer}>
            <Grid item xs={8}>
                <FormControlLabel
                    value={value}
                    disabled={false}
                    control={<Radio />}
                    label={label}
                    onChange={onChange}
                />
            </Grid>
            <Grid item xs={4} className={styles.optionSizeNumber}>
                <FormattedMessage id={"repeat_free_vote_dialog-text-number_of_options"} values={{number: numberOfOptions}}/>
            </Grid>
        </Grid>

        )
}


export default RepeatFreeVoteOption