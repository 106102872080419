export class LoginMessage {

    playerID: string

    constructor(playerID: string) {
        this.playerID = playerID;
    }
}

export function encodeLoginMessage(message: LoginMessage): string {
    return JSON.stringify(message);
}