import React from 'react'
import { Slide, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export type MessageTypes = 'error' | 'warning' | 'info' | 'success'

interface IMessageSnackbar {
    open: boolean;
    messageType: MessageTypes;
    message: string;
    duration?: number;
    onClose: () => void;
}

const MessageSnackbar: React.FC<IMessageSnackbar> = ({
    open,
    messageType,
    message,
    duration = 6000,
    onClose
}) => {

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if(reason === 'clickaway') {
            return;
        }
        onClose();
    }
    return (
        <div>
            <Snackbar
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                autoHideDuration={duration}
                TransitionComponent={Slide}
            >
                <Alert
                    elevation={6}
                    variant={'filled'}
                    severity={messageType}
                    onClose={handleClose}
                >
                    {message}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default MessageSnackbar
