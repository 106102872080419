export enum MessageType {
    TABLE = "TABLE",
    AUDIO = "AUDIO",
    AVATAR = "AVATAR"
}

export interface WebsocketMessage {
    type: MessageType
    body: any
}

export function decodeMessage(message: string): WebsocketMessage {
    return JSON.parse(message)
}