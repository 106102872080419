import React, { useCallback, useContext, useEffect, useMemo, useState} from 'react';
import LoadingPage from "./LoadingPage";
import PokerPage from "./PokerPage";
import {PageHeaderComponent} from "../components/PageHeaderComponent";
import {NetworkEventListener} from "../controller/network/NetworkEventListener";
import PokerTable from "../model/PokerTable";
import PokerPlayer from "../model/PokerPlayer";
import logger from "../util/Logger";
import {AudioType} from "../controller/network/AudioType";
import HomePageStyles from "../stylesheets/HomePageStylesheet.module.css"
import {useStores} from "../hooks";
import {defaultPokerTheme, PokerThemeProvider} from "../model/PokerTheme";
import CreateSessionPage from "./CreateSessionPage";
import JoinSessionPage from "./JoinSessionPage";
import ControllerContext from "../contexts/ControllerContext";
import EnableLoadingStateContext from "../contexts/EnableLoadingStateContext";
import {
    defaultThemeSource,
    ThemeDefinition,
    ThemeSource,
    ThemeSourceTypes
} from "../service/ThemeService";
import {getCurrentUser} from "@2gether/frontend-library";
import {AccessDeniedPage} from './AccessDeniedPage';
import {AuthorizationContext} from "../contexts/AuthorizationContext";
import deLocale from "../locales/de.json";
import enLocale from "../locales/en.json";
import {IntlProvider} from "react-intl";

enum Page {
    LOADING = 0,
    CREATE = 1,
    LOGIN = 2,
    TABLE = 3
}

export const HomePage: React.FC = () => {
    const {mainController, themeController} = useContext(ControllerContext)
    const [locale, setLocale] = useState(mainController.getLocaleSetting());

    const [table, setTable] = useState<PokerTable | undefined>(undefined);
    const [player, setPlayer] = useState<PokerPlayer | undefined>(undefined);
    const [page, setPage] = useState(Page.LOADING);
    const [playNewRoundSound, setPlayNewRoundSound] = useState(false);
    const [playVotingReminderSound, setPlayVotingReminderSound] = useState(false);
    const [waitingForTheme, setWaitingForTheme] = useState(false)
    const [customThemes, setCustomThemes] = useState(new Map<string, ThemeDefinition>())
    const [themeSource, setThemeSource] = useState<ThemeSource>(defaultThemeSource())
    const [pokerTheme, setPokerTheme] = useState(defaultPokerTheme)
    const {userAuthorized, userLoggedIn, updateUser} = useContext(AuthorizationContext)

    const messages : any = {
        de: deLocale,
        en: enLocale
    }

    const loadThemes = useCallback(() => {
        getCurrentUser()
            .then(user => {
                if (!user.isAuthorized) throw Error()
                if (player?.id && table?.id) {
                    return themeController.loadCustomAndUserThemes(table.id, player.id, user.username)
                        .then((themes) => {
                            setCustomThemes(themes)
                        })
                } else {
                    return themeController.loadUserThemes(user.username)
                        .then((themes) => {
                            setCustomThemes(themes)
                        })
                }
            })
            .catch(() => {
                if (player?.id && table?.id) {
                    return themeController.loadCustomThemes(table.id, player.id)
                        .then((themes) => {
                            setCustomThemes(themes)
                        })
                }
            })
    }, [setCustomThemes, themeController, player, table])

    useEffect(loadThemes, [loadThemes])

    const {avatarChangeStore} = useStores()

    useEffect(updateUser, [updateUser])

    useEffect(() => {
        setWaitingForTheme(true)
        getCurrentUser()
            .then(user => {
                const themeSource = themeController.getThemeSourceSettingForUser(user.username)
                setThemeSource(themeSource)
            })
            .catch(() => {
                const themeSource = themeController.getThemeSourceSettingForGuest()
                setThemeSource(themeSource)
            })
            .finally(() => setTimeout(() => setWaitingForTheme(false), 1000))
    }, [themeController, mainController]);

    useEffect(() => {
        themeController.getThemeDefinition(themeSource)
            .then((def) => {
                new PokerThemeProvider(def).use(thm => setPokerTheme(thm))
            }).catch(() => {
                if (themeSource._tag === ThemeSourceTypes.USER) {
                    themeController.unsetUserThemeForUser();
                }
                logger.error("An error with the application of the theme has occurred.");
                setPokerTheme(defaultPokerTheme);
            })
    }, [themeSource, themeController]);

    const loginRequired = useCallback(
        (): void => {
            logger.info("INFO: Table exists, but login is required!");
            setPage(Page.LOGIN);
        },
        [setPage]
    );

    const loginSuccess = useCallback(
        (player: PokerPlayer): void => {
            logger.info(`INFO: Login successful! playerID: ${player.id}`);
            setPlayer(player);
            setPage(Page.LOADING);
        },
        [setPlayer, setPage]
    );

    const tableCreationRequired = useCallback(
        (): void => {
            logger.info("INFO: A table must be created first");
            mainController.setUrlTableID(undefined);
            setPage(Page.CREATE);
        },
        [mainController, setPage]
    );

    const tableJoined = useCallback(
        (tableID: string) => {
            logger.info(`INFO: Successfully joined table with id ${tableID}`);
            mainController.setUrlTableID(tableID);
            setPage(Page.LOADING);
        },
        [mainController, setPage]
    );

    const playerUpdated = useCallback(
        (player: PokerPlayer) => {
            logger.info(`Player update received for player with id ${player.id}`);
            setPlayer(player);
        },
        [setPlayer]
    );

    const setNewPokerTheme = useCallback(async(themeSource: ThemeSource) => {
        setThemeSource(themeSource);
        themeController.setThemeSourceSetting(themeSource);
    }, [setThemeSource, themeController]);

    const tableUpdated = useCallback(
        (table: PokerTable): void => {
            logger.info(`Table update received for table with id  ${table.id}`);
            setTable(table);
            setPage(Page.TABLE);
        },
        [setTable, setPage]
    );

    const audioNotificationRequested = useCallback(
        (type: AudioType): void => {
            switch (type) {
                case AudioType.VOTING_STARTED:
                    logger.info(`INFO: New round has started`);
                    setPlayNewRoundSound(true)
                    break;
                case AudioType.VOTING_REMINDER:
                    logger.info(`INFO: Voting reminder to play a card`);
                    setPlayVotingReminderSound(true)
                    break;
            }
        },
        [setPlayNewRoundSound, setPlayVotingReminderSound]
    );

    const newRoundSoundPlayed = useCallback(
        (): void => {
            setPlayNewRoundSound(false);
        },
        [setPlayNewRoundSound]
    );

    const votingReminderSoundPlayed = useCallback(
        (): void => {
            setPlayVotingReminderSound(false);
        },
        [setPlayVotingReminderSound]
    );

    const playerAvatarUpdated = useCallback(
        (playerId) => {
            logger.info(`Avatar update received for player with id ${playerId}`);
            avatarChangeStore.addUpdatedPlayer(playerId)
        },
        [avatarChangeStore]
    );

    const enableLoadingState = useCallback(
        () => {
            setPage(Page.LOADING);
        },
        [setPage]
    );

    const listener: NetworkEventListener = useMemo(
        () => ({
            loginRequired,
            tableCreationRequired,
            loginSuccess,
            tableJoined,
            playerUpdated,
            tableUpdated,
            audioNotificationRequested,
            playerAvatarUpdated
        }),
        [loginRequired, tableCreationRequired, loginSuccess, tableJoined, playerUpdated, tableUpdated, audioNotificationRequested, playerAvatarUpdated]
    );

    useEffect(() => {
        mainController.setNetworkEventListener(listener);
        mainController.checkLogin().catch(err => logger.error(err));
    }, [mainController, listener]);

    const getPageToRender = () => {
        if (waitingForTheme) {
            return (
                <LoadingPage/>
            );
        }

        switch (page) {
            case Page.LOADING:
                return (
                    <LoadingPage/>
                );
            case Page.LOGIN:
                return (
                    <JoinSessionPage
                        tableID={mainController.getUrlTableID()!}
                        setThemeSource={setNewPokerTheme}
                    />
                );
            case Page.CREATE:
                return (
                    <>
                        { !userLoggedIn || userAuthorized ? <CreateSessionPage/> : <AccessDeniedPage/>}
                    </>
                );
            case Page.TABLE:
                return (
                    <PokerPage
                        locale={locale}
                        setLocale={setLocale}
                        player={player!!}
                        table={table!!}
                        playNewRoundSound={playNewRoundSound}
                        callbackNewRoundSoundPlayed={newRoundSoundPlayed}
                        playVotingReminderSound={playVotingReminderSound}
                        callbackVotingReminderSoundPlayed={votingReminderSoundPlayed}
                        pokerTheme={pokerTheme}
                        loadThemes={loadThemes}
                        customThemes={customThemes}
                        setCustomThemes={setCustomThemes}
                        setThemeSource={setNewPokerTheme}
                        currentThemeSource={themeSource}
                    />
                );
        }
    }
    return (
        <div className={HomePageStyles.homePageContainer}>
            <IntlProvider
                locale={locale}
                key={locale}
                messages={messages[locale]}
            >
                <PageHeaderComponent
                    table={table}
                    pokerTheme={pokerTheme}
                    selfPlayer={player}
                />
                <EnableLoadingStateContext.Provider value={enableLoadingState}>
                    {getPageToRender()}
                </EnableLoadingStateContext.Provider>
            </IntlProvider>
        </div>
    )
}

export default HomePage;
