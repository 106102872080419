import NetworkAdapter from "../network/NetworkAdapter";
import PlayerManager from "./PlayerManager";
import PokerPlayer, {Role} from "../../model/PokerPlayer";
import {NetworkEventListener} from "../network/NetworkEventListener";
import StorageManager, {ProfileEntry, SettingsEntry} from "./StorageManager";
import PokerTable, {TableState} from "../../model/PokerTable";
import {AudioType} from "../network/AudioType";
import LanguageService from "../../service/LanguageService";
import {PokerCardValue} from "../../model/PokerCard";
import {getCognitoIdTokenFromCookies, getTableIDFromUrl} from "../../util/Helper";
import {ThemeSourceTypes} from "../../service/ThemeService";


export default class MainController implements NetworkEventListener {

    private readonly storageManager: StorageManager
    private readonly networkAdapter: NetworkAdapter
    private readonly playerManager: PlayerManager
    private networkListener?: NetworkEventListener

    constructor() {
        this.storageManager = new StorageManager();
        this.playerManager = new PlayerManager(this.storageManager);
        this.networkAdapter = new NetworkAdapter(this.playerManager, this.storageManager);
        this.networkAdapter.setNetworkEventListener(this);
    }

    loginRequired(): void {
        this.networkListener?.loginRequired();
    }

    tableCreationRequired(): void {
        this.networkListener?.tableCreationRequired();
    }

    loginSuccess(player: PokerPlayer, tableId: string): void {
        this.playerManager.setLocalPlayer(player, tableId);
        this.networkListener?.loginSuccess(player, tableId);
    }

    tableJoined(tableID: string) {
        this.networkListener?.tableJoined(tableID);
    }

    playerUpdated(player: PokerPlayer, tableId: string) {
        this.playerManager.updateLocalPlayer(player, tableId);
        this.networkListener?.playerUpdated(player, tableId);
    }

    tableUpdated(table: PokerTable): void {
        const player = this.findLocalPlayerAtTable(table);
        if (player) {
            this.playerManager.assignClientManagedProperties(player, table.id);
            this.playerUpdated(player, table.id);
        }
        // poker table relies on resize events to scale
        window.dispatchEvent(new Event('resize'));
        this.networkListener?.tableUpdated(table);
    }

    audioNotificationRequested(type: AudioType): void {
        this.networkListener?.audioNotificationRequested(type);
    }

    setNetworkEventListener(listener: NetworkEventListener) {
        this.networkListener = listener;
    }

    checkLogin(): Promise<void> {
        const tableID = this.getUrlTableID();
        return this.networkAdapter.checkLogin(tableID);
    }

    createPlayer(userName: string, cognitoID: string | undefined, role: Role, tableId: string, useGuestS3: boolean, playerProfileFromDB?: ProfileEntry): void {
        this.playerManager.createLocalPlayer(userName, cognitoID, role, tableId, useGuestS3, playerProfileFromDB);
    }

    getAudioNotificationSetting(): boolean {
        const settings = this.storageManager.getSettings()
        return settings.isAudioNotificationActive
    }

    setAudioNotificationSetting(audioNotificationsEnabled: boolean) {
        let settings = this.storageManager.getSettings()
        settings.isAudioNotificationActive = audioNotificationsEnabled
        this.storageManager.setSettings(settings)
    }

    getShowTableSetting(): boolean {
        const settings = this.storageManager.getSettings()
        return settings.isShowTableActive
    }

    setShowTableSetting(isShowTableActive: boolean) {
        let settings = this.storageManager.getSettings()
        settings.isShowTableActive = isShowTableActive
        this.storageManager.setSettings(settings)
    }

    getLocaleSetting(): string {
        const settings = this.storageManager.getSettings()
        if (settings.locale === "undefined")
            return LanguageService.getLanguage()
        else
            return settings.locale
    }

    getSettings(): SettingsEntry {
        return this.storageManager.getSettings();
    }

    getProfile(cognitoID: string | undefined): ProfileEntry {
        return this.storageManager.getProfile(cognitoID);
    }

    setSelectedLanguageSetting(selectedLanguage: string) {
        let settings = this.storageManager.getSettings()
        settings.locale = selectedLanguage
        this.storageManager.setSettings(settings)
    }

    async createTable(name: string): Promise<string> {
        return this.networkAdapter.createTable(name);
    }

    async joinTable(tableID: string): Promise<void> {
        return this.networkAdapter.joinTable(this.playerManager.getLocalPlayer(tableID)!!, tableID);
    }

    async enableVoting(tableID: string): Promise<void> {
        const state = TableState.VOTING_ENABLED;
        return this.networkAdapter.updateTableState(tableID, state);
    }

    async disableVoting(tableID: string): Promise<void> {
        const state = TableState.VOTING_DISABLED;
        return this.networkAdapter.updateTableState(tableID, state);
    }

    async deleteVoteFromHistory(tableID: string, votingID: string): Promise<void> {
        return this.networkAdapter.deleteVoteFromHistory(tableID, votingID);
    }

    async playCard(tableID: string, value: PokerCardValue, playedBy?: string): Promise<void> {
        if (playedBy)
            return this.networkAdapter.playCard(tableID, value, playedBy);
        else
            return this.networkAdapter.playCard(tableID, value, null);
    }

    async revealCards(tableID: string): Promise<void> {
        return this.networkAdapter.revealCards(tableID);
    }

    async toggleChangeOpenCard(tableID: string): Promise<void> {
        return this.networkAdapter.toggleChangeOpenCard(tableID);
    }

    toggleChangeRandomizeFreeVote(tableID: string) {
        return this.networkAdapter.toggleChangeRandomizeFreeVote(tableID);
    }

    async toggleShowHistory(tableID: string): Promise<void> {
        return this.networkAdapter.toggleShowHistory(tableID)
    }

    async playReminderSound(tableID: string): Promise<void> {
        return this.networkAdapter.playReminderSound(tableID);
    }

    async switchPlayerRole(tableId: string, player: PokerPlayer): Promise<void> {
        return this.networkAdapter.switchPlayerRole(tableId, player);
    }

    async switchPlayerRoleByAdmin(tableId: string, player: PokerPlayer): Promise<void> {
        return this.networkAdapter.switchPlayerRoleByAdmin(tableId, player);
    }

    async startFreeVote(tableId: string, votingOptions: string[], indices: string[]): Promise<void> {
        return this.networkAdapter.startFreeVote(tableId, votingOptions, indices);
    }

    async startFreeNumberVote(tableId: string): Promise<void> {
        return this.networkAdapter.startFreeNumberVote(tableId);
    }

    getUrlTableID(): string | undefined {
        return getTableIDFromUrl();
    }

    setUrlTableID(tableID: string | undefined) {
        if (tableID === this.getUrlTableID())
            return;
        window.location.search = !tableID ? "" : `id=${tableID}`;
    }

    private findLocalPlayerAtTable(table: PokerTable): PokerPlayer | undefined {
        const playerID = this.playerManager.getLocalPlayer(table.id)?.id;
        return table.getAllPlayers().find(player => player.id === playerID);
    }

    async uploadAvatar(tableID: string, playerID: string, cognitoID: string | undefined, file: File) {
        await this.networkAdapter.uploadAvatar(tableID, playerID, cognitoID, file)
        return this.networkAdapter.updateAvatar(tableID, playerID);
    }

    playerAvatarUpdated(playerId: string): void {
        this.networkListener?.playerAvatarUpdated(playerId);
    }

    async deleteAvatar(tableID: string, playerID: string, cognitoID: string | undefined) {
        await this.networkAdapter.deleteAvatar(tableID, playerID, cognitoID);
        return this.networkAdapter.updateAvatar(tableID, playerID);
    }

    async updateVotingTitle(tableID: string, title: string): Promise<void> {
        await this.networkAdapter.updateVotingTitle(tableID, title);

    }

    async updatePlayerProfileToDB(cognitoId: string, _tag?: ThemeSourceTypes): Promise<void> {
        const cognitoIdToken = getCognitoIdTokenFromCookies()
        const profileEntry = this.storageManager.getProfile(cognitoId)
        if (!!_tag && _tag !== ThemeSourceTypes.USER) {
            profileEntry.userTheme = undefined;
        }
        !!cognitoIdToken && await this.networkAdapter.updatePlayerProfileToDB(profileEntry, cognitoId, cognitoIdToken);
    }

    async getPlayerProfileFromDB(cognitoId: string): Promise<ProfileEntry | undefined> {
        const cognitoIdToken = getCognitoIdTokenFromCookies();
        if (!!cognitoIdToken) {
            return await this.networkAdapter.getPlayerProfileFromDB(cognitoId, cognitoIdToken);
        }
        return undefined;
    }


}
