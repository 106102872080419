import React, {useEffect, useState} from 'react';
import PokerTable, {TableState} from '../../model/PokerTable';
import {makeStyles, Typography} from '@material-ui/core';
import {asCssRGBAString, PokerTheme} from '../../model/PokerTheme';
import {PokerPanel} from './PokerPanel';
import {FormattedMessage} from 'react-intl';
import {PokerCardValue} from '../../model/PokerCard';
import StarIcon from '@material-ui/icons/Star';
import logger from "../../util/Logger";
import MainController from "../../controller/control/MainController";
import {VotingOptionButton} from "./VotingOptionButton";
import PokerPlayer, {Role} from "../../model/PokerPlayer";

const useStyle =
    makeStyles(() => ({
        votingOptionsTitle: {marginBottom: '10px'},
        votingOptionsTable: {
            width: '100%',
            border: '0',
            borderSpacing: '0 5px',
            borderCollapse: 'separate',
            textAlign: 'center',
            tableLayout: 'fixed',
        },
        votingOptionsHighlightRow: (pTheme: PokerTheme) => ({
            backgroundColor: asCssRGBAString(pTheme.panel.button),
            borderStyle: 'none',
        }),
        startIcon: {
            marginTop: 5
        }
    }));

interface Props {
    table: PokerTable;
    pokerTheme: PokerTheme;
    controller: MainController;
    player: PokerPlayer;
}

export const VotingOptionsPanel: React.FC<Props> = (props) => {
    const {table, pokerTheme, controller,player} = props;
    const votingOptionsStyles = useStyle(pokerTheme);

    const [majorityIndex, setMajorityIndex] = useState<string | number>(-1);
    const [randomizeFreeVote, setRandomizeFreeVote] = useState(table.isRandomizeFreeVoteEnabled);
    const [randomRows, setRandomRows] = useState<string[]>([])
    const [randomModeCounter, setRandomModeCounter] = useState(0)
    const voteCounts = table.getCardValuesWithCount();
    let maxCount = Math.max(...voteCounts.values());


    const renderVoteCount = (
        counts: Map<PokerCardValue, number>,
        index: string
    ) => {
        let option: PokerCardValue = index;
        if (!isNaN(parseInt(option))) {
            option = parseInt(option) * 100;
        }
        if (table.cardsRevealed && counts.get(option))
            return `x${counts.get(option)}`;
        return null;
    };

    const renderStarForMajority = (
        index: string,
    ) => {
        return (index === majorityIndex && table.hasFreeVotingMajority())
            ? <div className={votingOptionsStyles.startIcon}><StarIcon fontSize={"small"}/></div> : null
    }

    const getIfIndexIsVotingMajority = (index: string) => {
        return (index === majorityIndex && table.hasFreeVotingMajority())
    }

    const shouldHighlight = (
        counts: Map<PokerCardValue, number>,
        index: number,
        max: number,
    ) => {
        let option: PokerCardValue = table.votingOptionsIndex[index];
        if (!isNaN(parseInt(option))) {
            option = parseInt(option) * 100;
        }

        return table.cardsRevealed && counts.get(option) === max;
    };

    const getIfTwoColumnDesign = () => {
        return (table.votingOptions.length > 10)
    }


    useEffect(() => {
        let size = voteCounts.size
        for (let i = 0; i < size; i++) {
            let option: PokerCardValue = table.votingOptionsIndex[i];
            if (!isNaN(parseInt(option))) {
                option = parseInt(option) * 100;
            }
            if (voteCounts.get(option) === undefined) size++;
            else if (voteCounts.get(option) === maxCount) {
                if (table.hasFreeVotingMajority()) {
                    if (!isNaN(parseInt(option as string))) {
                        setMajorityIndex((parseInt(option as string) / 100).toString());
                    } else {
                        setMajorityIndex(option)
                    }
                } else {
                    setMajorityIndex(-1);
                }
            }
        }

    }, [maxCount, voteCounts, table.hasFreeVotingMajority, setMajorityIndex, majorityIndex])

    let onClickOption = (tableID: string, votingOptionIndex: string) => {
        setRandomModeCounter(prevState => prevState + 1)
        let cardValue: PokerCardValue = votingOptionIndex;
        if (!isNaN(parseInt(votingOptionIndex))) {
            cardValue = parseInt(votingOptionIndex) * 100;
        }
        controller.playCard(tableID, cardValue).catch(err => logger.error(err));
    }

    const rowElements = table.votingOptions.map((votingOption, i) => (
        <>
            <tr
                key={i}
                data-testid={"votingOption"}
            >
                <td>
                    <VotingOptionButton
                        id={"optionButton_" + table.votingOptionsIndex[i]}
                        disabled={!table.isVotingEnabled()}
                        onClick={() => onClickOption(table.id, table.votingOptionsIndex[i])}
                        variant={""}
                        text={votingOption}
                        pokerTheme={pokerTheme}
                        voteCount={renderVoteCount(voteCounts, table.votingOptionsIndex[i])}
                        optionIndex={table.votingOptionsIndex[i]}
                        isRelativelyWinning={shouldHighlight(voteCounts, i, maxCount)}
                        isAbsolutelyWinning={getIfIndexIsVotingMajority(table.votingOptionsIndex[i])}
                        isTwoColumnDesign={getIfTwoColumnDesign()}
                        isShownAsButton={player.role === Role.VOTER}
                    />
                </td>
            </tr>
        </>
    ));

    const randomRowElements = randomRows.map((votingOptionIndex) => (
        <tr
            key={table.votingOptionsIndex.indexOf(votingOptionIndex)}
        >
            <td>
                <VotingOptionButton
                    id={"optionButton_" + votingOptionIndex}
                    disabled={!table.isVotingEnabled()}
                    onClick={() => onClickOption(table.id, votingOptionIndex)}
                    variant={""}
                    text={table.votingOptions[table.votingOptionsIndex.indexOf(votingOptionIndex)]}
                    pokerTheme={pokerTheme}
                    voteCount={renderVoteCount(voteCounts, votingOptionIndex)}
                    optionIndex={votingOptionIndex}
                    isRelativelyWinning={shouldHighlight(voteCounts, table.votingOptionsIndex.indexOf(votingOptionIndex), maxCount)}
                    isAbsolutelyWinning={getIfIndexIsVotingMajority(votingOptionIndex)}
                    isTwoColumnDesign={getIfTwoColumnDesign()}
                    isShownAsButton={player.role === Role.VOTER}
                />
            </td>
        </tr>
    ));

    useEffect(() => {
        if (table.state == TableState.VOTING_DISABLED)
            setRandomizeFreeVote(table.isRandomizeFreeVoteEnabled);
        else
            setRandomRows(table.votingOptionsIndex.slice().sort(() => 0.5 - Math.random()))
    }, [table.state])


    return (
        <PokerPanel
            content={[
                <>
                    <h2>
                        <Typography variant={'h6'}>
                            <b>
                                <FormattedMessage id={'voting_options_panel-text-options'}/>
                            </b>
                        </Typography>
                    </h2>
                    <table>
                        <tbody>
                        <tr>
                            {!getIfTwoColumnDesign() ? (
                                <td>
                                    <table className={votingOptionsStyles.votingOptionsTable}>
                                        <tbody>{randomizeFreeVote
                                            ? randomRowElements
                                            : rowElements}</tbody>
                                    </table>
                                </td>
                            ) : (
                                <>
                                    <td>
                                        <table className={votingOptionsStyles.votingOptionsTable}>
                                            <tbody>
                                            {randomizeFreeVote && randomRowElements
                                                ? randomRowElements.slice(0, Math.floor(rowElements.length / 2))
                                                : rowElements.slice(0, Math.floor(rowElements.length / 2))
                                            }
                                            </tbody>
                                        </table>
                                    </td>
                                    <td>
                                        <table className={votingOptionsStyles.votingOptionsTable}>
                                            <tbody>
                                            {randomizeFreeVote && randomRowElements
                                                ? randomRowElements.slice(Math.floor(rowElements.length / 2))
                                                : rowElements.slice(Math.floor(rowElements.length / 2))
                                            }
                                            </tbody>
                                        </table>
                                    </td>
                                </>
                            )}
                        </tr>
                        </tbody>
                    </table>
                </>,
            ]}
            pokerTheme={pokerTheme}
        />
    );
};

export default VotingOptionsPanel;
