import {useWindowSize} from "react-use";
import Confetti from "react-confetti";
import pokerPageStyles from "../../stylesheets/PokerPageStylesheet.module.css";
import React from "react";
import PokerTable, {VotingType} from "../../model/PokerTable";

interface Props {
    table: PokerTable
}

let allCardsEqualLastState = false
let FreeVotingMajorityLastState = false
let timeStampOfLastConfetti = 0;

function ConfettiComponent(props: Props) {
    const {table} = props;
    const {width, height} = useWindowSize();

    if ((table.areAllCardsEqual() && table.votingType !== VotingType.FREE_VOTE && !allCardsEqualLastState) ||
        (table.hasFreeVotingMajority() && !FreeVotingMajorityLastState) ||
        (Date.now()-timeStampOfLastConfetti) < 500 ){
        // console.log("Drawing confetti - "+Date.now())
        timeStampOfLastConfetti = Date.now()
        return (
            <Confetti
                className={pokerPageStyles.confettiCanvas}
                width={width}
                height={height}
                gravity={0.2}
                numberOfPieces={500}
                recycle={false}
                onConfettiComplete={confetti => confetti?.reset()}/>
        )
    }
    // console.log("Erasing confetti - "+Date.now())
    return null;
}

export default React.memo(ConfettiComponent, (prevProps: Props, nextProps: Props) => {
    // custom Props comparator
    // return true if previous Props and next Props are functionally equal (from confetti's POV)
    // goal: don't rerender when there is no change in cards or revealed status
    const prevCards = prevProps.table.voterPlayers.map(p => p.playedCardValue);
    const nextCards = nextProps.table.voterPlayers.map(p => p.playedCardValue);
    allCardsEqualLastState = prevProps.table.areAllCardsEqual();
    FreeVotingMajorityLastState = prevProps.table.hasFreeVotingMajority();

    let result = (  prevProps.table.cardsRevealed === nextProps.table.cardsRevealed &&          // ( reveal status didn't change   AND
                    prevCards.length === nextCards.length &&                                    //   amount of cards didn't change AND
                    prevCards.every((v,i) => v === nextCards[i])  )           //   cards didn't change )
                                                                                                // OR
            || (prevProps.table.areAllCardsEqual() === nextProps.table.areAllCardsEqual() &&    // ( "cards are equal" status didn't change  AND
            prevProps.table.hasFreeVotingMajority() === nextProps.table.hasFreeVotingMajority())//   "cards are in majority" status didn't change )

    return (result)
})