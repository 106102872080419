import {PlayerContainer} from "../container/PlayerContainer";
import PokerPlayer, {Role} from "../../../../model/PokerPlayer";


enum SwitchRoleCommand {
    SWITCH_TO_VOTER = "SWITCH_TO_VOTER",
    SWITCH_TO_OBSERVER = "SWITCH_TO_OBSERVER"
}

export default class SwitchPlayerRoleRequest {
    player: PlayerContainer
    tableID: string
    command: string

    constructor(tableID: string, command: SwitchRoleCommand, player: PokerPlayer) {
        this.tableID = tableID;
        this.command = command;
        this.player = PlayerContainer.fromPokerPlayer(player);

    }

    static newSwitchRoleCommand(tableID: string, player: PokerPlayer): SwitchPlayerRoleRequest {
        return new SwitchPlayerRoleRequest(tableID, playerRoleToCommand(player), player);
    }
}

function playerRoleToCommand(player: PokerPlayer): SwitchRoleCommand {
    let command: SwitchRoleCommand
    switch (player.role) {
        case Role.OBSERVER:
            command = SwitchRoleCommand.SWITCH_TO_VOTER;
            break;
        case Role.VOTER:
            command = SwitchRoleCommand.SWITCH_TO_OBSERVER;
            break;
        case Role.FREE_PLACE:
            command = SwitchRoleCommand.SWITCH_TO_VOTER;
            break;
        default:
            throw Error("Invalid command!");
    }
    return command;
}
