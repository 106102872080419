import blobToFile from "../util/File";
import ThemeController from "../controller/control/ThemeController";

const THEME_LIST = process.env.REACT_APP_THEME_LIST;
let dtn = process.env.REACT_APP_DEFAULT_THEME

if (!dtn) {
    throw new Error("REACT_APP_DEFAULT_THEME is missing!")
}
export const DEFAULT_THEME_NAME = dtn;

if (!THEME_LIST) {
    throw new Error("REACT_APP_THEME_LIST is missing!")
}

export enum ThemeSourceTypes {
    DEFAULT = "DEFAULT",
    PUBLIC = "PUBLIC",
    CUSTOM = "CUSTOM",
    USER = "USER"
}

export type DefaultThemeSource = {
    _tag: ThemeSourceTypes.DEFAULT
    name: string
}
export function defaultThemeSource(): DefaultThemeSource {
    return {
        _tag: ThemeSourceTypes.DEFAULT,
        name: DEFAULT_THEME_NAME
    }
}

export type PublicThemeSource = {
    _tag: ThemeSourceTypes.PUBLIC
    name: string
}

export type CustomThemeSource = {
    _tag: ThemeSourceTypes.CUSTOM
    name: string
    playerID: string
    tableID: string
}
export function customThemeSource(tableID: string, playerID: string, name: string): CustomThemeSource {
    return {
        _tag: ThemeSourceTypes.CUSTOM,
        name,
        playerID,
        tableID
    }
}

export type UserThemeSource = {
    _tag: ThemeSourceTypes.USER
    name: string
    cognitoID: string
}
export function userThemeSource(cognitoID: string, name: string): UserThemeSource {
    return {
        _tag: ThemeSourceTypes.USER,
        name,
        cognitoID
    }
}


export type ThemeSource = PublicThemeSource | CustomThemeSource | UserThemeSource | DefaultThemeSource

export type ThemeDefinition = {
    source: ThemeSource
    file: File
    preview: string
}

export const localDefaultTheme: () => Promise<ThemeDefinition> = async () => {
    return {
        file: await fetch("./defaultTheme/DefaultTheme")
            .then(resp => resp.blob())
            .then(blob => blobToFile(blob, "Casino Royal")),
        source: defaultThemeSource(),
        preview: "/defaultTheme/preview.png"
    }
}

export const defaultThemeList: () => Promise<ThemeList> = async () => {
    return {
        default: DEFAULT_THEME_NAME,
        themes: {
            [DEFAULT_THEME_NAME]: await localDefaultTheme()
        }
    }
}

const failOverPromise: Promise<ThemeList> =  new Promise( async(accept, reject) => {  accept(
    await defaultThemeList()
)})


type Themes = {
    [key: string]: ThemeDefinition
}

export type ThemeList = {
    default: string,
    themes: Themes
}

export function fetchThemes(controller: ThemeController): Promise<ThemeList> {
    return controller.loadPublicThemes(THEME_LIST!!)
        .catch((reason) => {
            console.error("Failed to load themes: " + reason)
            return failOverPromise
        })
}