export type PokerCardValue = number | string

export const asNumber = (cardValue: PokerCardValue) => {
    let cardAsNumber = Number(cardValue)
    if(!Number.isNaN(cardAsNumber) && cardAsNumber >= 100) {
        return cardAsNumber / 100
    }
    return parseInt(cardValue as string, 16);
}

export default class PokerCard {

    readonly ownerID: string
    readonly value: PokerCardValue
    readonly playedBy: string|null

    constructor(ownerID: string, value: PokerCardValue, playedBy: string|null) {
        this.ownerID = ownerID
        this.value = value
        this.playedBy = playedBy
    }

}

/**
 * The card values to use for non free voting
 * Number -1 represents "Don't know"
 * Number -2 represents "Infinity"
 */
export const FibonacciCardValues: number[] = [0,100,200,300,500,800,1300,2100,-1,-2];