import ThemeStorageInterface from "./ThemeStorageInterface";
import {submitGETRequest} from "../serverbackend/requests";
import blobToFile from "../../../util/File";
import {
    customThemeSource,
    defaultThemeList,
    localDefaultTheme,
    ThemeDefinition,
    ThemeList,
    ThemeSource,
    ThemeSourceTypes,
    userThemeSource
} from "../../../service/ThemeService";
import JSZip from "jszip";
import {getImageAsDataUrl} from "../../../model/PokerThemeArchives";
import {ThemeImageLocations, Url} from "../../../model/PokerTheme";
import StorageManager from "../../control/StorageManager";
import logger from "../../../util/Logger";
import {withIdTokenInAuthenticationHeader} from "@2gether/frontend-library";

const xml2js = require("xml2js")

export default class S3ThemeStorageInterface extends ThemeStorageInterface {

    private readonly baseURL: string
    private readonly themeUploadBaseURL: string
    private storageManager: StorageManager
    private readonly themeDeleteBaseURL: string;

    constructor(storageManager: StorageManager) {
        super()
        this.baseURL = process.env.REACT_APP_BASE_URL!;
        this.themeUploadBaseURL = `${this.baseURL}/user/theming/custom-theme/upload?`
        this.themeDeleteBaseURL = `${this.baseURL}/user/theming/custom-theme/delete?`
        this.storageManager = storageManager
    }



    async uploadTheme(source: ThemeSource, file: File): Promise<void> {
        let url = ""
        const {name} = source
        let headers = {}
        switch (source._tag) {
            case ThemeSourceTypes.CUSTOM:
                const {playerID, tableID} = source
                url = this.themeUploadBaseURL
                    +`playerID=${playerID}&tableID=${tableID}&theme=${encodeURIComponent(name)}&filesize=${file.size}`;
                break
            case ThemeSourceTypes.USER:
                const {cognitoID} = source
                if (cognitoID !== undefined) {
                    headers = await withIdTokenInAuthenticationHeader({})
                }
                url = this.themeUploadBaseURL
                    +`cognitoID=${cognitoID}&theme=${encodeURIComponent(name)}&filesize=${file.size}`;
                break
            default:
                return Promise.reject("ERROR while uploading theme: Can only upload custom and user themes.")
        }

        const presignedResponse = await submitGETRequest(url, headers);
        if (presignedResponse.status === 429) {
            throw Error(`Request limit reached! Try again later.`)
        }
        if (presignedResponse.status === 401) {
            throw Error(`Unauthorized!`)
        }
        if (presignedResponse.status === 413) {
            throw Error(`The file is to large. Select a smaller file!`)
        }

        const pair = await presignedResponse.json()
        const s3url = pair.first;
        return fetch(s3url, {
            method: 'PUT',
            body: file
        }).then();
    }

    async deleteTheme(source: ThemeSource): Promise<void> {
        let url = ""
        const {name} = source
        let headers = {}
        switch (source._tag) {
            case ThemeSourceTypes.CUSTOM:
                const {playerID, tableID} = source
                url = this.themeDeleteBaseURL
                    +`playerID=${playerID}&tableID=${tableID}&theme=${encodeURIComponent(name)}`;
                break
            case ThemeSourceTypes.USER:
                const {cognitoID} = source
                if (cognitoID !== undefined) {
                    headers = await withIdTokenInAuthenticationHeader({})
                }
                url = this.themeDeleteBaseURL
                    +`cognitoID=${cognitoID}&theme=${encodeURIComponent(name)}`;
                break
            default:
                return Promise.reject("ERROR while uploading theme: Can only delete custom and user themes.")
        }

        const presignedResponse = await submitGETRequest(url, headers);

        if (presignedResponse.status === 401) {
            throw Error(`Unauthorized!`)
        }

        const pair = await presignedResponse.json()
        const s3url = pair.first;

        if (s3url)
            fetch(s3url, {
                method: 'DELETE',
            }).then(() => {
                if (source._tag === ThemeSourceTypes.USER) {
                    this.storageManager.unsetUserThemeForUser()
                }
            })
    }

    private fetchThemeByUrl(themeUrl: string, theme: string) {
        return fetch(themeUrl, {
            method: 'GET'
        })
            .then(resp => resp.blob())
            .then(blob => blobToFile(blob, theme));
    }

    async fetchCustomTheme(tableID:string, playerID: string, theme: string): Promise<File> {
        const themeUrl = `${process.env.REACT_APP_THEME_S3_BUCKET}/guest-users/${tableID}/${playerID}/${theme}`
        return this.fetchThemeByUrl(themeUrl, theme)
    }

    async fetchUserTheme(cognitoID: string, theme: string): Promise<File> {
        const themeUrl = `${process.env.REACT_APP_THEME_S3_BUCKET}/registered-users/${cognitoID}/${theme}`
        return this.fetchThemeByUrl(themeUrl, theme)
    }

    async fetchPublicTheme(theme: string): Promise<File> {
        const themeUrl = `${process.env.REACT_APP_THEME_PUBLIC_THEMES_BUCKET}/${theme}.zip`
        return this.fetchThemeByUrl(themeUrl, theme)
    }

    async getThemeDefinition(source: ThemeSource): Promise<ThemeDefinition> {
        if (source._tag === ThemeSourceTypes.DEFAULT) {
            return localDefaultTheme()
        }
        if (source._tag === ThemeSourceTypes.CUSTOM) {
            const file = await this.fetchCustomTheme(source.tableID, source.playerID, source.name)
            return {
                source: source,
                file: file,
                preview: await new JSZip().loadAsync(file).then(zip => getImageAsDataUrl(zip, Url(ThemeImageLocations.PREVIEW)))
            }
        }
        if (source._tag === ThemeSourceTypes.USER) {
            const file = await this.fetchUserTheme(source.cognitoID, source.name)
            return {
                source: source,
                file: file,
                preview: await new JSZip().loadAsync(file).then(zip => getImageAsDataUrl(zip, Url(ThemeImageLocations.PREVIEW)))
            }
        }
        if (source._tag === ThemeSourceTypes.PUBLIC) {
            const file = await this.fetchPublicTheme(source.name)
            return {
                source: source,
                file: file,
                preview: await new JSZip().loadAsync(file).then(zip => getImageAsDataUrl(zip, Url(ThemeImageLocations.PREVIEW)))
            }
        }
        throw Error("Unknown source")
    }

    async loadThemesFromBucket(bucketPath: string, themeSourceGenerator: (themeName: string) => ThemeSource): Promise<Map<string, ThemeDefinition>> {
        const listUrl = `${process.env.REACT_APP_THEME_S3_BUCKET}`
            +`?list-type=2&format=json&prefix=${bucketPath}`

        interface Content {
            Key: string[]
        }

        interface Response {
            ListBucketResult: {
                Contents: Content[]
            }
        }

        return fetch(listUrl, {
            method: 'GET'
        })
            .then(response => response.text())
            .then(text => xml2js.parseStringPromise(text) as Response)
            .then(jsObj => (jsObj.ListBucketResult.Contents ?? [])
                .map(v => v.Key[0])
                .map(key => key.substr(bucketPath.length)))
            .then(themes =>
                Promise.all(
                    themes.map(theme => {
                        return this.getThemeDefinition(themeSourceGenerator(theme))
                    }))
            ).then( (themeDefinitions) => {
                    const map = new Map<string, ThemeDefinition>()
                    themeDefinitions.forEach(def => map.set(def.source.name, def ))
                    return map
                }
            )
            .catch((reason) => {
                logger.error("Failed to load themes: " + reason)
                return new Map<string, ThemeDefinition>()
            })
    }

    async loadCustomThemes(tableID: string, playerID: string): Promise<Map<string, ThemeDefinition>> {
        const prefix = `guest-users/${tableID}/${playerID}/`
        const customThemeSourceGenerator = (themeName: string) => customThemeSource(tableID, playerID, themeName)
        return await this.loadThemesFromBucket(prefix, customThemeSourceGenerator)
    }

    async loadUserThemes(cognitoID: string): Promise<Map<string, ThemeDefinition>> {
        const prefix = `registered-users/${cognitoID}/`
        const customThemeSourceGenerator = (themeName: string) => userThemeSource(cognitoID, themeName)
        return await this.loadThemesFromBucket(prefix, customThemeSourceGenerator)
    }

    async loadCustomAndUserThemes(tableID: string, playerID: string, cognitoID: string): Promise<Map<string, ThemeDefinition>> {
        const customThemes = await this.loadCustomThemes(tableID, playerID)
        const userThemes = await this.loadUserThemes(cognitoID)
        return new Map([...customThemes, ...userThemes])
    }

    async loadPublicThemes(themeListJson: string): Promise<ThemeList> {
        return fetch(themeListJson)
            .then(resp => resp.json())
            .then(res => res as ThemeList)
            .then(async themes => {
                const definitions = (await Promise.all(Object.entries(themes.themes)
                    .map(([,theme]) => this.getThemeDefinition(theme.source))))
                    .map(def => [def.source.name, def])
                return Object.assign({}, themes, {themes: Object.fromEntries(definitions)})
            })
            .catch((reason) => {
                logger.error("Failed to load themes: " + reason)
                return defaultThemeList()
            })
    }
}