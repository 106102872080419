import PokerTable from "../../model/PokerTable";
import MainController from "../../controller/control/MainController";
import PokerPlayer from "../../model/PokerPlayer";
import {makeStyles, Theme} from "@material-ui/core/styles";
import React, {useState} from "react";
import CardButton from "../Cards/CardButton";
import {TextField} from "@material-ui/core";
import logger from "../../util/Logger";
import {asCssRGBAString, PokerTheme} from "../../model/PokerTheme";
import {cardShouldBeClickable} from "../../util/Cards";

interface Props {
    table: PokerTable
    tableID: string
    controller: MainController
    localPlayer: PokerPlayer,
    pokerTheme: PokerTheme
    constraints: (value: string) => boolean
}

const useStyles = makeStyles((theme: Theme) => ({
    deck: {
        display: "flex",
        flexWrap: "wrap",
        minWidth: "40px",
        justifyContent: "center",
        [theme.breakpoints.down("md")]: { marginBottom: "10px" },
        [theme.breakpoints.down("sm")]: { margin: "auto", maxWidth: "300px" }
    },
    freeNumberInputPanel: (pTheme: PokerTheme) => ({
        boxShadow: "0 0 10px",
        margin: "5px 10px",
        backgroundColor: asCssRGBAString(pTheme.panel.background),
        padding: "15px 10px",
        height: "70px",
        boxSizing: "border-box"
    }),
    textInput: (pTheme: PokerTheme) => ({
        color: asCssRGBAString(pTheme.panel.text),
    }),
    cardPlaceholder: {
        width: "44px",
        margin: "6px 5px"
    }
}))

export const FreeNumberComponent: React.FC<Props> = (props) => {
    const {
        table,
        tableID,
        controller,
        pokerTheme,
        constraints
    } = props

    const classes = useStyles(pokerTheme)

    const [cardValue, setCardValue] = useState<number | string>(0)
    const [textFieldString, setTextFieldString] = useState<string | null>("")

    const renderCommitButton = () => {
        if (textFieldString !== null && textFieldString !== "") {
            return <CardButton
                        cardID={cardValue}
                        selected={false}
                        controller={controller}
                        tableID={tableID}
                        cardValue={cardValue}
                        onSelect={() => {}}
                        tooltipText={""}
                        typeSubmit={true}
                        pokerTheme={pokerTheme}
                        disabled={!cardShouldBeClickable(table, props.localPlayer)}
                        id={`free_number_component-card_button`}
                    />
        } else {
            return <div className={classes.cardPlaceholder}/>
        }
    }


    const onChangeFreeVoteNumber = (event: any) => {
        const targetValue = event.target.value as string
        if(targetValue === ''){
            setCardValue(0)
            setTextFieldString("")
        } else if(constraints(targetValue)){
            setTextFieldString(targetValue.replace(".", ","))
            const num = Number(targetValue.replace(",", "."))
            if (Number.isNaN(num)) {
                setCardValue(targetValue.toUpperCase())
            } else {
                setCardValue(Math.round(num * 100))
            }
        } else {
            setTextFieldString(cardValue === 0 ? "" : textFieldString)
        }
    }

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        controller.playCard(tableID, cardValue).catch(err => logger.error(err));
    }

    const textFieldValue = () => {
        if (textFieldString === null) {
            const cardValueAsNum = Number(cardValue)
            if (Number.isNaN(cardValueAsNum)) {
                return cardValue
            } else {
                return (cardValueAsNum/100).toString().replace(".", ",")
            }
        } else {
            return textFieldString
        }
    }

    return (
        <form onSubmit={onSubmit}>
            <div className={classes.deck}>
                <div className={classes.freeNumberInputPanel}>
                    <TextField
                        InputProps={{className:classes.textInput}}
                        type={"text"}
                        id={"freenumberfield"}
                        value={textFieldValue()}
                        onChange={event => onChangeFreeVoteNumber(event)}
                    />
                </div>
                {renderCommitButton()}
            </div>
        </form>
    );
}
