import React, {CSSProperties, useContext} from "react";
import PokerPlayer, {Role} from "../../model/PokerPlayer";
import {Box, createStyles, makeStyles, Typography} from "@material-ui/core";
import PokerTable from "../../model/PokerTable";
import {asCssRGBAString, PokerTheme} from "../../model/PokerTheme";
import PlayerAvatarComponent from "./PlayerAvatarComponent";
import logger from "../../util/Logger";
import ControllerContext from "../../contexts/ControllerContext";

interface PlayersAbsProps {
    factor: number
    table: PokerTable
    selfPlayer: PokerPlayer,
    pokerTheme: PokerTheme
}

enum playerPosition {
    top,
    left,
    bottom,
    right
}

const playerPositions = [
    {left: "70.7", top: "10.9"},
    {left: "60.3", top: "10.9"},
    {left: "50", top: "10.9"},
    {left: "39.7", top: "10.9"},
    {left: "29.3", top: "10.9"},
    {left: "8", top: "24.7"},
    {left: "5.5", top: "50"},
    {left: "8", top: "75.3"},
    {left: "29.3", top: "89.1"},
    {left: "39.7", top: "89.1"},
    {left: "50", top: "89.1"},
    {left: "60.3", top: "89.1"},
    {left: "70.7", top: "89.1"},
    {left: "92", top: "75.3"},
    {left: "94.5", top: "50"},
    {left: "92", top: "24.7"},
]

const getAlignment = (i: number) => {
    switch (i) {
        case 6:
            return {
                width: "11%",
                display: "flex",
                justifyContent: "flex-end"
            }
        case 14:
            return {
                width: "11%",
                display: "flex",
                justifyContent: "flex-start"
            }
        case 5:
        case 7:
            return {
                width: "16%",
                display: "flex",
                justifyContent: "flex-end"
            }
        case 13:
        case 15:
            return {
                width: "16%",
                display: "flex",
                justifyContent: "flex-start"
            }
        default:
            return {}
    }
}

const getPlayerStyle = (i: number): CSSProperties => {
    return ({
        transformOrigin: "center center",
        position: "absolute",
        transform: "translate(-50%, -50%)",
        left: `${playerPositions[i].left}%`,
        top: `${playerPositions[i].top}%`,
        ...getAlignment(i)
    })
}

const getPlayerPosition = (i: number) => {
    switch (i) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
            return playerPosition.top;
        case 5:
        case 6:
        case 7:
            return playerPosition.left;
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
            return playerPosition.bottom;
        case 13:
        case 14:
        case 15:
            return playerPosition.right;
        default:
            return playerPosition.top;
    }
}

const getPlayerTextStyles = (index: number, factor: number): React.CSSProperties => {
    const width = (index === 6 || index === 14) ? 120 : 160;
    return ({
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: width * factor,
        paddingLeft: 4,
        paddingRight: 4,
    });
}

export const PlayersAbs: React.FC<PlayersAbsProps> = (props) => {
    const {table, factor, selfPlayer, pokerTheme} = props;
    const players = table.getPlayersAtTable();
    return (
        <div>
            {players.map((player, i) => {
                return (
                    <div style={getPlayerStyle(i)} key={player.id}>
                        <Player
                            player={player}
                            selfPlayer={selfPlayer}
                            isSelf={player.id === selfPlayer.id}
                            factor={factor}
                            position={getPlayerPosition(i)}
                            playerTextStyle={getPlayerTextStyles(i, factor)}
                            pokerTheme={pokerTheme}
                            table={table}
                        />
                    </div>
                )
            })}
        </div>
    )
}


const usePlayerStyles = makeStyles(() =>
    createStyles({
        playerName: (pTheme: PokerTheme) => ({
            color: asCssRGBAString(pTheme.table.playerName),
            backgroundColor: asCssRGBAString(pTheme.table.playerNameBackground)
        }),
        currentPlayerName: (pTheme: PokerTheme) => ({
            color: asCssRGBAString(pTheme.table.currentPlayerName),
            backgroundColor: asCssRGBAString(pTheme.table.currentPlayerNameBackground)
        }),
    })
);

const Player: React.FC<{
    playerTextStyle?: React.CSSProperties;
    player: PokerPlayer;
    selfPlayer: PokerPlayer;
    isSelf: boolean;
    factor: number;
    position: playerPosition;
    pokerTheme: PokerTheme;
    table: PokerTable;
}> = (props) => {
    const {playerTextStyle, player, selfPlayer, isSelf, factor, position, pokerTheme, table} = props;
    const classes = usePlayerStyles(pokerTheme)
    const {mainController} = useContext(ControllerContext)

    const showPlayer: boolean = (player.connected && player.role === Role.VOTER);

    const onClickSwitchPlayerRoleByAdmin = () => {
        if (selfPlayer.canControlTable(table)) {
            mainController.switchPlayerRoleByAdmin(table.id, player).catch(err => logger.error(err));
        }
    }

    const getDefaultIconLink = () => {
        // @ts-ignore
        return (
            isSelf
                ? pokerTheme.table.currentPlayerIcon.url
                : pokerTheme.table.playerIcon.url
        )
    }

    const renderPlayerIcon = () => {
        return <PlayerAvatarComponent
            playerId={player.id}
            cognitoId={player.cognitoID}
            tableId={table.id}
            useGuestS3={player.useGuestS3}
            defaultIconLink={getDefaultIconLink()}
            factor={factor}
        />
    }

    return (
        <Box
            display="flex"
            flexDirection={(position === playerPosition.top || position === playerPosition.bottom) ? "column" : "row"}
            alignItems="center"
            justifyContent="center"
            maxWidth="100%"
            style={ selfPlayer.canControlTable(table)? {cursor: "pointer"} : {}}
            onClick={() => onClickSwitchPlayerRoleByAdmin()}
        >
            {(showPlayer && (position === playerPosition.bottom || position === playerPosition.right)) && renderPlayerIcon()}
            <Box height={36 * factor} display={"flex"} alignItems={"center"} >
                <Typography
                    style={{
                        ...playerTextStyle
                    }}
                    className={`${isSelf ? classes.currentPlayerName + " " : ""}${classes.playerName}`}
                >
                    {showPlayer ? player.name : ""}
                </Typography>
            </Box>
            {(showPlayer && (position === playerPosition.top || position === playerPosition.left)) && renderPlayerIcon()}
        </Box>
    );
};
