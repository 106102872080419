import {PlayerContainer} from "../container/PlayerContainer";
import PokerPlayer from "../../../../model/PokerPlayer";

export default class JoinTableRequest {
    player: PlayerContainer
    tableID: string
    adminToken?: string

    constructor(player: PokerPlayer, tableID: string, adminToken: string | undefined) {
        this.player = PlayerContainer.fromPokerPlayer(player);
        this.tableID = tableID;
        this.adminToken = adminToken;
    }

}