import PokerPlayer from "../../../../model/PokerPlayer";
import {cardValueFromString, roleFromString, roleToString} from "../convert";

export class PlayerContainer {
    id?: string
    cognitoID?: string
    name?: string
    role?: string
    connected?: boolean
    playedCardValue?: string
    cardPlayedBy?: string
    useGuestS3?: boolean

    static toPokerPlayer(container: PlayerContainer): PokerPlayer {
        const player = new PokerPlayer(container.id!!, container.cognitoID, container.name!!, roleFromString(container.role!!), container.useGuestS3!!);
        player.cardPlayedBy = (container.cardPlayedBy && container.cardPlayedBy !== "") ? container.cardPlayedBy : null;
        player.connected = container.connected!!;
        player.playedCardValue = container.playedCardValue ? cardValueFromString(container.playedCardValue) : undefined;
        return player;
    }

    static fromPokerPlayer(player: PokerPlayer): PlayerContainer {
        const container = new PlayerContainer();
        container.id = player.id;
        container.cognitoID = player.cognitoID;
        container.name = player.name;
        container.role = roleToString(player.role);
        container.useGuestS3 = player.useGuestS3;
        return container;
    }
}
