import {cardValueToString} from "../convert";
import {PokerCardValue} from "../../../../model/PokerCard";

export default class PlayCardRequest {

    tableID: string
    playerID: string
    cardValue: PokerCardValue
    playedBy: string | null

    constructor(tableID: string, playerID: string, cardValue: PokerCardValue, playedBy: string | null) {
        this.tableID = tableID;
        this.playerID = playerID;
        this.cardValue = cardValueToString(cardValue);
        this.playedBy = playedBy
    }

}