import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import Sound from 'react-sound';
import {AdminControlPanel} from '../components/PokerPage/AdminControlPanel';
import {CardComponent} from '../components/PokerPage/CardComponent';
import FreeVoteDialog from '../components/PokerPage/FreeVoteDialog';
import {PokerTableComponentFnc} from '../components/PokerPage/PokerTableComponent';
import {RoleComponent} from '../components/PokerPage/RoleComponent';
import VotingOptionsPanel from '../components/PokerPage/VotingOptionsPanel';
import PokerPlayer, {Role} from '../model/PokerPlayer';
import PokerTable, {TableState, VotingType} from '../model/PokerTable';
import logger from '../util/Logger';
import PokerTableMobileComponent from '../components/PokerPage/PokerTableMobileComponent';
import {Box, Grid, Theme, useMediaQuery, useTheme} from '@material-ui/core';
import ConfettiComponent from '../components/PokerPage/ConfettiComponent';
import {ChartComponent} from '../components/PokerTable/ChartComponent';
import {makeStyles} from '@material-ui/core/styles';
import FreeNumberVoteResultsPanel from '../components/PokerPage/FreeNumberVoteResultsPanel';
import {ThemeComponent} from '../components/PokerPage/ThemeComponent';
import {AvatarDialog} from '../components/PokerPage/AvatarDialog';
import {asCssRGBAString, asCssUrl, PokerTheme,} from '../model/PokerTheme';
import {SettingsPanelComponent} from '../components/PokerPage/SettingsPanelComponent';
import ReducedPokerTableComponent from '../components/PokerPage/ReducedPokerTableComponent';
import RepeatFreeVoteDialog from '../components/PokerPage/RepeatFreeVoteDialog';
import {PokerCardValue} from "../model/PokerCard";
import {ThemeDefinition, ThemeSource} from "../service/ThemeService";
import ControllerContext from "../contexts/ControllerContext";
import MessageSnackbar, {MessageTypes} from '../components/SnackbarComponents/MessageSnackbar';
import {HistoryPanelComponent} from "../components/PokerPage/HistoryPanelComponent";
import {TitlePanel} from "../components/PokerPage/TitlePanel";
import Measure from "react-measure";
import {getCurrentUser} from "@2gether/frontend-library";
import {usePanelHeightCalculator} from "../hooks";
import {useHistory} from "../hooks/useHistory";

const NEW_VOTING_ROUND_SOUND = process.env.REACT_APP_SOUND_NEW_VOTING_ROUND;
const VOTING_REMINDER_SOUND = process.env.REACT_APP_SOUND_VOTING_REMINDER;

const useStyles = makeStyles((theme: Theme) => ({
    chartContainer: (pokerTheme: PokerTheme) => ({
        boxShadow: '0 0 10px',
        margin: '10px',
        backgroundColor: asCssRGBAString(pokerTheme.panel.background),
        position: 'relative',
        padding: '10px',
    }),
    body: {
        backgroundSize: 'cover',
        [theme.breakpoints.down('md')]: {
            overflowY: 'auto !important',
        },
    },
}));
const useStylesLayout = makeStyles(() => ({
    pokerPageContainer: {flex: '1 0 auto'},
}));

interface Props {
    locale: string;
    setLocale: React.Dispatch<any>;
    player: PokerPlayer;
    table: PokerTable;
    playNewRoundSound: boolean;
    callbackNewRoundSoundPlayed: () => void;
    playVotingReminderSound: boolean;
    callbackVotingReminderSoundPlayed: () => void;
    pokerTheme: PokerTheme;
    setThemeSource: (source: ThemeSource) => void;

    loadThemes(): void;

    customThemes: Map<string, ThemeDefinition>
    setCustomThemes: (cThemes: Map<string, ThemeDefinition>) => void
    currentThemeSource: ThemeSource
}

export interface HistoryItem {
    tableId: string
    votingId: string
    votingTitle: string
    votingOptions: string[]
    votesForOptions: number[]
    indexForVotingOptions: string[]
    type: string
}

const backgroundFromTheme = (thm: PokerTheme): string => {
    const _bkg = thm.general.background;
    if (_bkg._tag === 'Color') {
        return 'background: ' +
            asCssRGBAString(_bkg) +
            ' no-repeat fixed; background-size: cover'
    } else {
        return 'background: ' +
            asCssUrl(_bkg) +
            ' no-repeat fixed; background-size: cover'
    }
};

export const PokerPage: React.FC<Props> = (props) => {
    const {
        locale,
        setLocale,
        player,
        table,
        playNewRoundSound,
        callbackNewRoundSoundPlayed,
        playVotingReminderSound,
        callbackVotingReminderSoundPlayed,
        pokerTheme,
        currentThemeSource,
        setThemeSource,
        loadThemes,
        customThemes,
        setCustomThemes,
    } = props;

    const {mainController, themeController} = useContext(ControllerContext)
    const [showFreeVoteDialog, setShowFreeVoteDialog] = useState(false);
    const [showRepeatFreeVoteDialog, setShowRepeatFreeVoteDialog] = useState(false);
    const [selectedCardId, setSelectedCardId] = useState<PokerCardValue | null>(null);
    const [openMessageSnackbar, setOpenMessageSnackbar] = useState<boolean>(false)
    const [snackbarMessageType, setSnackbarMessageType] = useState<MessageTypes>('warning')
    const [snackbarMessage, setSnackbarMessage] = useState<string>('')
    const theme = useTheme();
    const sizeXs = useMediaQuery(theme.breakpoints.only('xs'));
    const sizeSm = useMediaQuery(theme.breakpoints.only('sm'));
    const sizeMd = useMediaQuery(theme.breakpoints.only('md'));
    const sizeLg = useMediaQuery(theme.breakpoints.only('lg'));
    const sizeXlUp = useMediaQuery(theme.breakpoints.up('xl'));
    const styles = useStyles(pokerTheme);

    const showSnackbarMessage = (messageType: MessageTypes, message: string) => {
        setSnackbarMessageType(messageType)
        setSnackbarMessage(message)
        setOpenMessageSnackbar(true)
    }

    const data = useHistory(table.id);

    const getLastFreeVoteFromHistory: HistoryItem = useMemo(() => {
        if (!data) return null;
        return data.listP2GHistories.items.filter((historyItem: HistoryItem) => historyItem.type === "FREE_VOTE")
            .sort((item1: HistoryItem, item2: HistoryItem) => parseInt(item1.votingId) - parseInt(item2.votingId)).pop()
    }, [data]);


    useEffect(() => {
        const localVotingTitleSettings = localStorage.getItem("localVotingTitleSettings");
        if (localVotingTitleSettings != null && (JSON.parse(localVotingTitleSettings as string).sessionId !== table.id))
            localStorage.removeItem("localVotingTitleSettings")
    }, []);

    useEffect(() => {
        const bodyElement = document.getElementsByTagName('body')[0];
        const bkg = backgroundFromTheme(pokerTheme);
        bodyElement.setAttribute('style', bkg);
    }, [pokerTheme]);

    useEffect(() => {
        getCurrentUser()
            .then(user => {
                const themeSource = themeController.getThemeSourceSettingForUser(user.username)
                setThemeSource(themeSource)
            })
            .catch(() => {
                const themeSource = themeController.getThemeSourceSettingForGuest()
                setThemeSource(themeSource)
            })
    }, [themeController, mainController]);

    useEffect(() => {
        if (table.state === TableState.VOTING_DISABLED) setSelectedCardId(null);
    }, [table, setSelectedCardId]);

    const isPlayerAllowedToVote = useMemo(() => {
        if (!table.isVotingEnabled()) return false;
        return (
            table.getVoters().find((voter) => {
                if (voter.id === player.id) return voter.role === Role.VOTER;
                return false;
            }) !== undefined
        );
    }, [player, table]);

    const isTableControlledByPlayer = player.canControlTable(table);
    const [isAudioNotificationEnabled, setIsAudioNotificationEnabled] = useState(
        mainController.getAudioNotificationSetting()
    );
    const [isShowTableEnabled, setIsShowTableEnabled] = useState(
        mainController.getShowTableSetting()
    );
    const [votingTitle, setVotingTitle] = useState("")
    const showVotingOptions =
        table.votingType === VotingType.FREE_VOTE && table.isVotingEnabled();
    const showFreeNumberVoteResults =
        table.votingType === VotingType.FREE_NUMBER && table.cardsRevealed;

    const playCard = useCallback(
        (value: PokerCardValue, playedBy: string | undefined) => {
            setSelectedCardId(value);
            mainController
                .playCard(table.id, value, playedBy)
                .catch((err) => logger.error(err));
        },
        [setSelectedCardId, mainController, table]
    );

    const votingTitleHandler = (title: string) => {
        setVotingTitle(title);
        if (title !== "") {
            localStorage.setItem("localVotingTitleSettings", JSON.stringify({
                "localVotingTitle": title,
                "sessionId": table.id
            }))
        } else if (localStorage.getItem("localVotingTitleSettings") != null) {
            JSON.parse(localStorage.getItem("localVotingTitleSettings") as string).localVotingTitle && localStorage.removeItem("localVotingTitleSettings");
        }
    }
    const closeFreeVoteDialog = useCallback(() => {
        setShowFreeVoteDialog(false);
    }, [setShowFreeVoteDialog]);

    const closeRepeatFreeVoteDialog = useCallback(() => {
        setShowRepeatFreeVoteDialog(false);
    }, [setShowRepeatFreeVoteDialog]);

    const openRepeatFreeVoteDialog = useCallback(() => {
        setShowRepeatFreeVoteDialog(true);
    }, [setShowRepeatFreeVoteDialog]);

    const openFreeVoteDialog = useCallback(() => {
        setShowFreeVoteDialog(true);
    }, [setShowFreeVoteDialog]);

    const startFreeVote = useCallback(
        async (votingOptions: string[] | undefined, indices: string[] = []) => {
            if (votingOptions === undefined) return;
            const tableId = table.id;
            mainController
                .startFreeVote(tableId, votingOptions, indices)
                .catch((err: string) => logger.error(err));

            if (votingTitle) {
                await mainController.updateVotingTitle(table.id, votingTitle)
            }
        },
        [table, mainController, votingTitle]
    );

    const toggleAudioNotificationSetting = useCallback(() => {
        mainController.setAudioNotificationSetting(!isAudioNotificationEnabled);
        setIsAudioNotificationEnabled(mainController.getAudioNotificationSetting());
        !!player.cognitoID && mainController.updatePlayerProfileToDB(player.cognitoID as string).catch((err: string) => logger.error(err));
    }, [mainController, isAudioNotificationEnabled, setIsAudioNotificationEnabled]);

    const toggleShowTableSetting = useCallback(() => {
        mainController.setShowTableSetting(!isShowTableEnabled);
        setIsShowTableEnabled(mainController.getShowTableSetting());
        !!player.cognitoID && mainController.updatePlayerProfileToDB(player.cognitoID as string).catch((err: string) => logger.error(err));
    }, [mainController, isShowTableEnabled, setIsShowTableEnabled]);

    const setSelectedLanguageSetting = useCallback(
        (selectedLanguage: string) => {
            mainController.setSelectedLanguageSetting(selectedLanguage);
            setLocale(selectedLanguage);
            !!player.cognitoID && mainController.updatePlayerProfileToDB(player.cognitoID as string).catch((err: string) => logger.error(err));
        },
        [mainController, setLocale]
    );

    const renderAdminControls = useCallback(() => {
        if (isTableControlledByPlayer)
            return (
                <AdminControlPanel
                    controller={mainController}
                    table={table}
                    openFreeVoteDialog={openFreeVoteDialog}
                    openRepeatFreeVoteDialog={openRepeatFreeVoteDialog}
                    votingTitleHandler={votingTitleHandler}
                    pokerTheme={pokerTheme}
                    votingTitle={votingTitle}
                    lastFreeVoteFromHistory={getLastFreeVoteFromHistory}

                />
            );
        return <React.Fragment></React.Fragment>;
    }, [
        isTableControlledByPlayer,
        mainController,
        table,
        openFreeVoteDialog,
        openRepeatFreeVoteDialog,
        pokerTheme,
        votingTitle,
        getLastFreeVoteFromHistory,
    ]);

    const renderRoleComponent = useCallback(() => {
        return (
            <RoleComponent
                player={player}
                controller={mainController}
                tableId={table.id}
                table={table}
                pokerTheme={pokerTheme}
            />
        );
    }, [player, mainController, table, pokerTheme]);

    const renderPokerTableComponent = useCallback(() => {
        return (
            <PokerTableComponentFnc
                pokerTable={table}
                player={player}
                playCard={playCard}
                pokerTheme={pokerTheme}
            />
        );
    }, [table, player, playCard, pokerTheme]);
    const renderPokerTableMobileComponent = useCallback(() => {
        return (
            <PokerTableMobileComponent
                pokerTable={table}
                selfPlayer={player}
                playCard={playCard}
                pokerTheme={pokerTheme}
            />
        );
    }, [table, player, playCard, pokerTheme]);

    const renderReducedPokerTableComponent = useCallback(() => {
        return (
            <ReducedPokerTableComponent
                pokerTable={table}
                selfPlayer={player}
                playCard={playCard}
                pokerTheme={pokerTheme}
            />
        );
    }, [table, player, playCard, pokerTheme]);

    const renderCardComponent = useCallback(() => {
        if (isPlayerAllowedToVote)
            return (
                <CardComponent
                    table={table}
                    tableID={table.id}
                    controller={mainController}
                    localPlayer={player}
                    selectedCardID={selectedCardId}
                    setSelectedCardID={setSelectedCardId}
                    pokerTheme={pokerTheme}
                />
            );
        return <React.Fragment></React.Fragment>;
    }, [
        isPlayerAllowedToVote,
        table,
        mainController,
        player,
        selectedCardId,
        pokerTheme,
    ]);

    const renderThemeComponent = useCallback(() => {
        return (
            <ThemeComponent
                loadThemes={loadThemes}
                pokerTheme={pokerTheme}
                setThemeSource={setThemeSource}
                playerID={player.id!}
                tableID={table.id}
                customThemes={customThemes}
                setCustomThemes={setCustomThemes}
                currentThemeSource={currentThemeSource}
                cognitoID={player.cognitoID}
            />
        );
    }, [loadThemes, pokerTheme, currentThemeSource, setThemeSource, customThemes, player.id, setCustomThemes, table.id]);

    const renderAvatarComponent = useCallback(() => {
        return (
            <AvatarDialog
                pokerTheme={pokerTheme}
                playerID={player.id!}
                cognitoID={player.cognitoID}
                tableID={table.id}
                showSnackbarMessage={showSnackbarMessage}
            />
        );
    }, [pokerTheme, player.id, table.id, player.cognitoID]);


    const renderSettingsPanelComponent = useCallback(() => {
        return (
            <SettingsPanelComponent
                isAudioNotificationActive={isAudioNotificationEnabled}
                changeAudioNotificationStatus={toggleAudioNotificationSetting}
                isShowTableActive={isShowTableEnabled}
                changeShowTableStatus={toggleShowTableSetting}
                selectedLanguage={locale}
                setSelectedLanguage={setSelectedLanguageSetting}
                pokerTheme={pokerTheme}
                renderRoleComponent={renderRoleComponent}
                renderThemeComponent={renderThemeComponent}
                renderAvatarComponent={renderAvatarComponent}
                player={player}
                table={table}
                controller={mainController}
            />
        );
    }, [
        isAudioNotificationEnabled,
        toggleAudioNotificationSetting,
        isShowTableEnabled,
        toggleShowTableSetting,
        locale,
        setSelectedLanguageSetting,
        pokerTheme,
        renderRoleComponent,
        renderThemeComponent,
        renderAvatarComponent
    ]);


    const renderHistoryPanel = useCallback((panelHeight?: number) => {

        if (isTableControlledByPlayer) {
            return (
                <HistoryPanelComponent
                    table={table}
                    pokerTheme={pokerTheme}
                    controller={mainController}
                    isTableControlledByPlayer={true}
                    panelHeight={panelHeight}
                    data={data}
                />
            );
        }
        return (
            <HistoryPanelComponent
                table={table}
                pokerTheme={pokerTheme}
                controller={mainController}
                isTableControlledByPlayer={false}
                panelHeight={panelHeight}
                data={data}
            />
        );
    }, [table, pokerTheme, data]);

    const renderTitlePanel = useCallback(() => {
        if (isTableControlledByPlayer) {
            return (
                <TitlePanel
                    title={votingTitle}
                    votingTitleHandler={votingTitleHandler}
                    table={table}
                    controller={mainController}
                    pokerTheme={pokerTheme}
                />
            )
        } else return <React.Fragment></React.Fragment>
    }, [table, pokerTheme])

    const renderVotingOptions = useCallback(() => {
        if (showVotingOptions)
            return <VotingOptionsPanel table={table} pokerTheme={pokerTheme} controller={mainController} player={player}/>;
        return <React.Fragment></React.Fragment>;
    }, [table, table.votingOptions, showVotingOptions, pokerTheme]);

    const renderFreeNumberVoteResults = useCallback(() => {
        if (showFreeNumberVoteResults)
            return (
                <FreeNumberVoteResultsPanel table={table} pokerTheme={pokerTheme}/>
            );
        return <React.Fragment></React.Fragment>;
    }, [table, showFreeNumberVoteResults, pokerTheme]);

    const renderChartComponent = useCallback(() => {
        if (
            table.hasCardsRevealed() &&
            table.votingType !== VotingType.FREE_VOTE
        )
            return (
                <div className={styles.chartContainer}>
                    <ChartComponent table={table} pokerTheme={pokerTheme}/>
                </div>
            );
        return <React.Fragment></React.Fragment>;
    }, [table, styles.chartContainer, pokerTheme]);

    const renderMessageSnackbar = useCallback(() => {
        return (
            <MessageSnackbar
                open={openMessageSnackbar}
                messageType={snackbarMessageType}
                message={snackbarMessage}
                onClose={() => setOpenMessageSnackbar(false)}
            />
        )
    }, [openMessageSnackbar, snackbarMessage, snackbarMessageType])

    const newRoundSoundElement = useCallback(() => {
        if (!playNewRoundSound) {
            return null;
        }
        if (!isAudioNotificationEnabled) {
            callbackNewRoundSoundPlayed();
            return null;
        }
        return (
            <Sound
                url={NEW_VOTING_ROUND_SOUND!}
                playStatus={'PLAYING'}
                onFinishedPlaying={callbackNewRoundSoundPlayed}
                volume={25}
            />
        );
    }, [
        playNewRoundSound,
        isAudioNotificationEnabled,
        callbackNewRoundSoundPlayed,
    ]);

    const votingReminderSoundElement = playVotingReminderSound && (
        <Sound
            url={VOTING_REMINDER_SOUND!}
            playStatus={'PLAYING'}
            onFinishedPlaying={callbackVotingReminderSoundPlayed}
            volume={70}
        />
    );

    const confettiElement = <ConfettiComponent table={table}/>;

    const repeatFreeVoteDialogElement = (
        <RepeatFreeVoteDialog
            lastFreeVoteFromHistory={getLastFreeVoteFromHistory}
            showRepeatFreeVoteDialog={showRepeatFreeVoteDialog}
            closeRepeatFreeVoteDialog={closeRepeatFreeVoteDialog}
            startFreeVote={startFreeVote}
        />
    );

    const freeVoteDialogElement = (
        <FreeVoteDialog
            showFreeVoteDialog={showFreeVoteDialog}
            closeFreeVoteDialog={closeFreeVoteDialog}
            startFreeVote={startFreeVote}
            votingTitle={votingTitle}
            controller={mainController}
            table={table}
        />
    );

    const layoutElements = {
        renderAdminControls,
        renderPokerTableComponent,
        renderPokerTableMobileComponent,
        renderReducedPokerTableComponent,
        renderCardComponent,
        renderSettingsPanelComponent,
        renderVotingOptions,
        renderFreeNumberVoteResults,
        renderChartComponent,
        renderHistoryPanel,
        renderTitlePanel,
        showReducedLayout: !isShowTableEnabled,
    };

    const xsLayoutElement = sizeXs && <XsLayout {...layoutElements} />;
    const smLayoutElement = sizeSm && <SmLayout {...layoutElements} />;
    const mdLayoutElement = sizeMd && <MdLayout {...layoutElements} />;
    const lgLayoutElement = sizeLg && <LgLayout {...layoutElements} />;
    const xlLayoutElement = sizeXlUp && <XlLayout {...layoutElements} />;

    return (
        <React.Fragment>
            {xsLayoutElement}
            {smLayoutElement}
            {mdLayoutElement}
            {lgLayoutElement}
            {xlLayoutElement}
            {confettiElement}
            {newRoundSoundElement()}
            {votingReminderSoundElement}
            {freeVoteDialogElement}
            {repeatFreeVoteDialogElement}
            {renderMessageSnackbar()}
        </React.Fragment>
    );
};

export default PokerPage;

interface LayoutProps {
    renderAdminControls: () => JSX.Element;
    renderPokerTableComponent: () => JSX.Element;
    renderPokerTableMobileComponent: () => JSX.Element;
    renderReducedPokerTableComponent: () => JSX.Element;
    renderCardComponent: () => JSX.Element;
    renderSettingsPanelComponent: () => JSX.Element;
    renderVotingOptions: () => JSX.Element;
    renderFreeNumberVoteResults: () => JSX.Element;
    renderChartComponent: () => JSX.Element;
    renderHistoryPanel: (panelHeight?: number) => JSX.Element;
    renderTitlePanel: () => JSX.Element;
    showReducedLayout: boolean;
}

const XsLayout: React.FC<LayoutProps> = (props) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                {props.renderTitlePanel()}
            </Grid>
            <Grid item xs={12}>
                {props.renderAdminControls()}
            </Grid>
            <Grid item xs={12}>
                {props.renderPokerTableMobileComponent()}
            </Grid>
            <Grid item xs={12}>
                {props.renderCardComponent()}
            </Grid>
            <Grid item xs={12}>
                {props.renderVotingOptions()}
            </Grid>
            <Grid item xs={12}>
                {props.renderFreeNumberVoteResults()}
            </Grid>
            <Grid item xs={12}>
                {props.renderChartComponent()}
            </Grid>
            <Grid item xs={12}>
                {props.renderSettingsPanelComponent()}
            </Grid>
            <Grid item xs={12}>
                {props.renderHistoryPanel()}
            </Grid>
        </Grid>
    );
};

const SmLayout: React.FC<LayoutProps> = (props) => {
    return (
        <React.Fragment>
            <Grid container xs={12}
                  style={{
                      display: "flex",
                      justifyContent: "center"
                  }}
            >
                <Grid
                    style={{
                        alignSelf: "center",
                    }}
                    item xs={8}>
                    {props.renderTitlePanel()}
                </Grid>
            </Grid>
            <Grid container alignItems={"flex-start"} spacing={0}>
                <Grid item xs={12}>
                    {props.renderAdminControls()}
                </Grid>
                <Grid item container xs={6}>
                    <Grid item xs={12}>
                        {props.renderPokerTableMobileComponent()}
                    </Grid>
                    <Grid item xs={12}>
                        {props.renderCardComponent()}
                    </Grid>
                    <Grid item xs={12}>
                        {props.renderHistoryPanel()}
                    </Grid>
                </Grid>
                <Grid item container xs={6}>
                    <Grid item xs={12}>
                        {props.renderVotingOptions()}
                    </Grid>
                    <Grid item xs={12}>
                        {props.renderFreeNumberVoteResults()}
                    </Grid>
                    <Grid item xs={12}>
                        {props.renderChartComponent()}
                    </Grid>
                    <Grid item xs={12}>
                        {props.renderSettingsPanelComponent()}
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const MdLayout: React.FC<LayoutProps> = (props) => {
    const styles = useStylesLayout();
    return (
        <Box className={styles.pokerPageContainer} position='relative'>
            <Box
                display='flex'
                flexDirection='column'
                position='absolute'
                height='100%'
                maxHeight='100%'
                width='100%'
                style={{overflowY: 'auto'}}
            >
                <Box
                    width={"550px"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    alignSelf={"center"}
                >
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        {props.renderTitlePanel()}
                    </Box>
                </Box>
                <Box flex={0}>{props.renderAdminControls()}</Box>
                <Box flex={'1 0'} minHeight={500}>
                    {props.showReducedLayout
                        ? props.renderReducedPokerTableComponent()
                        : props.renderPokerTableComponent()}
                </Box>
                <Box flex={0}>{props.renderCardComponent()}</Box>
                <Box flex={0}>
                    <Grid container alignItems='flex-start' spacing={0} justifyContent='center'>
                        <Grid container item xs={6}>
                            <Grid item xs={12}>
                                {props.renderVotingOptions()}
                            </Grid>
                            <Grid item xs={12}>
                                {props.renderFreeNumberVoteResults()}
                            </Grid>
                            <Grid item xs={12}>
                                {props.renderSettingsPanelComponent()}
                            </Grid>
                            <Grid item xs={12}>
                                {props.renderHistoryPanel()}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

const useLargeLayoutStyles = makeStyles(() => ({
    // pokertable + carddeck + header = 500 + 100 + 38 = 638px
    // below that we need a scrollbar anyways
    largeControlsContainer: {
        '@media only screen and (min-height: 638px)': {
            overflowY: 'auto',
        },
    },
}));

const LgLayout: React.FC<LayoutProps> = (props) => {
    const styles = useStylesLayout();
    const largeStyles = useLargeLayoutStyles();
    const {
        setTitlePanelHeight,
        setAdminControlsHeight,
        setVotingOptionsHeight,
        setFreeNumberVoteResultsHeight,
        setObserverComponentHeight,
        setSettingsPanelComponentHeight,
        calculatePanelHeight
    } = usePanelHeightCalculator();
    return (
        <Box className={styles.pokerPageContainer} position='relative'>
            <Box
                display='flex'
                position='absolute'
                height='100%'
                width='100%'
                style={{overflowY: 'auto'}}
            >
                <Box flex='1 0 auto' display='flex' flexDirection='column'>
                    <Box flexGrow={1} minHeight={500}>
                        {
                            props.showReducedLayout ? (
                                props.renderReducedPokerTableComponent()
                            ) : (
                                props.renderPokerTableComponent()
                            )
                        }
                    </Box>
                    <Box flexShrink={0} height={100}>
                        {props.renderCardComponent()}
                    </Box>
                </Box>
                <Box
                    flexShrink={0}
                    width={350}
                    className={largeStyles.largeControlsContainer}
                    style={{
                        height: "100%",
                    }}
                >
                    <Grid container spacing={0} alignItems='flex-start'>
                        <Measure
                            bounds
                            onResize={contentRect => {
                                setTitlePanelHeight(contentRect.bounds!.height);
                            }}
                        >
                            {
                                ({measureRef}) => (
                                    <Grid ref={measureRef} item xs={12}>
                                        {props.renderTitlePanel()}
                                    </Grid>
                                )
                            }
                        </Measure>
                        <Measure
                            bounds
                            onResize={contentRect => {
                                setAdminControlsHeight(contentRect.bounds!.height);
                            }}
                        >
                            {
                                ({measureRef}) => (
                                    <Grid ref={measureRef} item xs={12}>
                                        {props.renderAdminControls()}
                                    </Grid>
                                )
                            }
                        </Measure>
                        <Measure
                            bounds
                            onResize={contentRect => {
                                setVotingOptionsHeight(contentRect.bounds!.height);
                            }}
                        >
                            {
                                ({measureRef}) => (
                                    <Grid ref={measureRef} item xs={12}>
                                        {props.renderVotingOptions()}
                                    </Grid>
                                )
                            }
                        </Measure>
                        <Measure
                            bounds
                            onResize={contentRect => {
                                setFreeNumberVoteResultsHeight(contentRect.bounds!.height);
                            }}
                        >
                            {
                                ({measureRef}) => (
                                    <Grid ref={measureRef} item xs={12}>
                                        {props.renderFreeNumberVoteResults()}
                                    </Grid>
                                )
                            }
                        </Measure>
                        <Measure
                            bounds
                            onResize={contentRect => {
                                setSettingsPanelComponentHeight(contentRect.bounds!.height);
                            }}
                        >
                            {
                                ({measureRef}) => (
                                    <Grid ref={measureRef} item xs={12}>
                                        {props.renderSettingsPanelComponent()}
                                    </Grid>
                                )
                            }
                        </Measure>
                        <Grid item xs={12}>
                            {props.renderHistoryPanel(calculatePanelHeight())}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

const XlLayout: React.FC<LayoutProps> = (props) => {
    const styles = useStylesLayout();
    const {
        setTitlePanelHeight,
        setAdminControlsHeight,
        setVotingOptionsHeight,
        setFreeNumberVoteResultsHeight,
        setObserverComponentHeight,
        setSettingsPanelComponentHeight,
        calculatePanelHeight
    } = usePanelHeightCalculator();
    return (
        <Box className={styles.pokerPageContainer} position='relative'>
            <Box
                display='flex'
                position='absolute'
                height='100%'
                width='100%'
                style={{overflowY: 'auto'}}
            >
                <Box flex='1 0 auto' display='flex' flexDirection='column'>
                    <Box flexGrow={1} minHeight={500}>
                        {props.showReducedLayout
                            ? props.renderReducedPokerTableComponent()
                            : props.renderPokerTableComponent()}
                    </Box>
                    <Box flexShrink={0} height={100}>
                        {props.renderCardComponent()}
                    </Box>
                </Box>
                <Box
                    flexShrink={0}
                    width={350}
                    style={{
                        height: "100%",
                    }}
                >
                    <Grid container spacing={0} alignItems='flex-start' style={{maxHeight: "95vh"}}>
                        <Measure
                            bounds
                            onResize={contentRect => {
                                setTitlePanelHeight(contentRect.bounds!.height);
                            }}
                        >
                            {
                                ({measureRef}) => (
                                    <Grid ref={measureRef} item xs={12}>
                                        {props.renderTitlePanel()}
                                    </Grid>
                                )
                            }
                        </Measure>
                        <Measure
                            bounds
                            onResize={contentRect => {
                                setAdminControlsHeight(contentRect.bounds!.height);
                            }}
                        >
                            {
                                ({measureRef}) => (
                                    <Grid ref={measureRef} item xs={12}>
                                        {props.renderAdminControls()}
                                    </Grid>
                                )
                            }
                        </Measure>
                        <Measure
                            bounds
                            onResize={contentRect => {
                                setVotingOptionsHeight(contentRect.bounds!.height);
                            }}
                        >
                            {
                                ({measureRef}) => (
                                    <Grid ref={measureRef} item xs={12}>
                                        {props.renderVotingOptions()}
                                    </Grid>
                                )
                            }
                        </Measure>
                        <Measure
                            bounds
                            onResize={contentRect => {
                                setFreeNumberVoteResultsHeight(contentRect.bounds!.height);
                            }}
                        >
                            {
                                ({measureRef}) => (
                                    <Grid ref={measureRef} item xs={12}>
                                        {props.renderFreeNumberVoteResults()}
                                    </Grid>
                                )
                            }
                        </Measure>
                        <Measure
                            bounds
                            onResize={contentRect => {
                                setSettingsPanelComponentHeight(contentRect.bounds!.height);
                            }}
                        >
                            {
                                ({measureRef}) => (
                                    <Grid ref={measureRef} item xs={12}>
                                        {props.renderSettingsPanelComponent()}
                                    </Grid>
                                )
                            }
                        </Measure>
                        <Grid item xs={12}>
                            {props.renderHistoryPanel(calculatePanelHeight())}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};


