
import React, {createContext, useCallback, useEffect, useState} from 'react'
import {Auth} from "aws-amplify";
import {getCurrentUser} from "@2gether/frontend-library";

export interface UserContextValue {
    userAuthorized: boolean
    userLoggedIn: boolean
    updateUser(): void
}

export const AuthorizationContext = createContext<UserContextValue>({userAuthorized: false, userLoggedIn: false, updateUser: () => {}})

const UserProvider : React.FC = ({ children }) => {

    const [userAuthorized, setUserAuthorized] = useState<boolean>(false)
    const [userLoggedIn, setUserLoggedIn] = useState<boolean>(false)

    const updateUser = useCallback(() => {
        Auth.currentSession()
            .then(() => getCurrentUser())
            .then(user => {
                    setUserAuthorized(user.isAuthorized)
                    setUserLoggedIn(true)
            })
            .catch(() => {
                setUserLoggedIn(false)
            })
    }, [setUserAuthorized, setUserLoggedIn])

    useEffect(() => {
        updateUser()
    }, [updateUser])

    const contextValue = {userAuthorized: userAuthorized, userLoggedIn: userLoggedIn, updateUser: updateUser}
    return <>
        <AuthorizationContext.Provider value={contextValue}>{children}</AuthorizationContext.Provider>
    </>
}

export default UserProvider