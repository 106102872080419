import React from "react";
import {asCssRGBAString, PokerTheme} from "../../model/PokerTheme";
import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import StarIcon from '@material-ui/icons/Star';
import StarBorder from "@material-ui/icons/StarBorder";

interface Props {
    id?: string
    disabled: boolean
    onClick: () => any | ((a: string, b: string) => void)
    variant: any
    text: string
    pokerTheme: PokerTheme
    voteCount: number | any
    optionIndex: string
    isRelativelyWinning: boolean
    isAbsolutelyWinning: boolean
    isTwoColumnDesign: boolean
    isShownAsButton?: boolean
}


export const VotingOptionButton: React.FC<Props> = (props) => {
    const {
        id,
        disabled,
        onClick,
        variant,
        text,
        pokerTheme,
        voteCount,
        optionIndex,
        isAbsolutelyWinning,
        isRelativelyWinning,
        isTwoColumnDesign,
        isShownAsButton
    } = props

    //provide different stylings depending on isTwoColumnDesign
    const useStyles =
        (makeStyles(() => ({
            buttonPadding: {
                paddingLeft: '10px',
                paddingRight: '10px',
            },

            textPadding: {
                paddingLeft: '10px',
                paddingRight: '10px',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
            doubleColWholeTable: {
                tableLayout: 'fixed',
                width: '100%',
                border: '0',
                display: 'table-row',
                padding: '0px',
            },
            doubleColIndexAndVoteCount: {
                display: 'table-cell',
                verticalAlign: 'middle',
                border: '0',
                borderCollapse: "collapse",
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                width: 'fit-content',
                minWidth: '25px',
            },
            doubleColText: {
                width: '100%',
                borderStyle: 'none',
                overflowWrap: 'anywhere',
                display: 'table-cell',
                verticalAlign: 'middle',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                hyphens: "auto",
            },
            singleColWholeTable: {
                width: '100%',
                border: '0',
                display: 'content'
            },
            singleColIndex: {
                height: '100%',
                width: "8%"
            },
            singleColIconAndVoteCount: {
                textAlign: "right",
                height: '100%',
                width: "15%",
                display: 'content'
            },
            singleColText: {
                width: '100%',
                borderStyle: 'none',
                overflowWrap: 'anywhere',
                display: 'table',
                verticalAlign: 'middle',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                hyphens: "auto",
            },
            index: {
                fontWeight: 'bold',
            },
            voteCount: {
                fontWeight: 'bolder',
            },
            icon: {
                padding: "0",
                lineHeight: "100%",
                fontSize: "smaller"
            },
            flexBox: {
                display: "flex",
                justifyContent: 'right',
                verticalAlign: 'middle'
            }
        })));


    const useThemeStyles =
        makeStyles(() => ({
            votingOptionsButton: (pTheme: PokerTheme) => ({
                color: asCssRGBAString(pTheme.panel.text),
                width: "100%",
                backgroundColor: asCssRGBAString(pTheme.panel.button),
                "&:hover": {backgroundColor: asCssRGBAString(pTheme.panel.button)},
                "&.Mui-disabled": {
                    color: "rgba(0, 0, 0, 0.26)",
                    backgroundColor: asCssRGBAString(pTheme.panel.button)
                },
                textTransform: 'none'
            }),
        }))

    const useStylesForText =
        makeStyles(() => ({
            votingOptionsText: (pTheme: PokerTheme) => ({
                color: asCssRGBAString(pTheme.panel.text),
                width: "100%",
                "&.Mui-disabled": {
                    color: asCssRGBAString(pTheme.panel.text),
                },
                textTransform: 'none'
            }),
        }))

    const classes = useStyles(pokerTheme)
    const themes = useThemeStyles(pokerTheme)
    const textStyles = useStylesForText(pokerTheme)
    const votingButtonStyles = themes.votingOptionsButton + ' ' + classes.buttonPadding
    const votingTextStyles = textStyles.votingOptionsText + ' ' + classes.textPadding

    const doubleColView = <Button id={id}
                                  disabled={disabled || !isShownAsButton}
                                  onClick={onClick}
                                  variant={variant}
                                  className={isShownAsButton ? votingButtonStyles : votingTextStyles}
                                  data-testid={"votingOptionButton_" + text}>
        <table className={classes.doubleColWholeTable}>
            <tbody>
            <tr>
                <td className={classes.doubleColIndexAndVoteCount}>
                    <table>
                        <tbody>
                        <tr className={classes.index}>
                            <td>
                                {optionIndex}:
                            </td>
                        </tr>
                        {voteCount && <tr className={classes.voteCount}>
                            <td>
                                {voteCount}
                            </td>
                        </tr>}
                        {(isRelativelyWinning || isAbsolutelyWinning) && <tr>
                            {isAbsolutelyWinning ? (<StarIcon fontSize={"small"}/>) : (
                                isRelativelyWinning ? (<StarBorder fontSize={"small"}/>) : ""
                            )}
                        </tr>}
                        </tbody>
                    </table>
                </td>
                <td data-testid={"votingOptionValue"} className={classes.doubleColText}>
                    {text}
                </td>
            </tr>
            </tbody>
        </table>
    </Button>;

    const singleColView = <Button
        id={id}
        disabled={disabled || !isShownAsButton}
        onClick={onClick}
        variant={variant}
        data-testid={"votingOptionButton_" + text}
        className={isShownAsButton ? votingButtonStyles : votingTextStyles}
    >
        <table className={classes.singleColWholeTable}>
            <tbody>
            <tr>
                <td className={classes.singleColIndex}>
                    <div className={classes.index}>
                        {optionIndex}:
                    </div>
                </td>
                <td data-testid={"votingOptionValue"} className={classes.singleColText}>
                    {text}
                </td>
                <td className={classes.singleColIconAndVoteCount}>
                    <div className={classes.flexBox}>
                        <div className={classes.voteCount}>
                            {voteCount}
                        </div>
                        <div className={classes.icon}>
                            {isAbsolutelyWinning ? (<StarIcon/>) : (
                                isRelativelyWinning ? (<StarBorder/>) : "")}
                        </div>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </Button>;

    return (
        <>
            {isTwoColumnDesign ? doubleColView : singleColView}
        </>
    )
}