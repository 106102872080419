import React, {useMemo, useRef} from "react";
import PokerTable, {VotingResult} from "../../model/PokerTable";
import {Grid, Tooltip} from "@material-ui/core";
import {PokerPanel} from "./PokerPanel";
import {PokerTheme} from "../../model/PokerTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useIntl} from "react-intl";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import MainController from "../../controller/control/MainController";
import logger from "../../util/Logger";

interface Props {
    table: PokerTable
    pokerTheme: PokerTheme
    controller: MainController
    isTableControlledByPlayer: boolean,
    panelHeight?: number,
    data: any;
}

export const HistoryPanelComponent: React.FC<Props> = ({
                                                           table,
                                                           pokerTheme,
                                                           controller,
                                                           isTableControlledByPlayer,
                                                           panelHeight,
                                                           data
                                                       }) => {
    const baseStyles = {
        root: {
            "&::-webkit-scrollbar": {
                width: 7,
                left: "-10px",
            },
            "&::-webkit-scrollbar-track": {
                boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "darkgrey",
                outline: `1px solid slategrey`,
            }
        },
    }
    let useStyles = makeStyles((theme) => ({
        ...baseStyles,
        historyPanel: {
            [theme.breakpoints.down("xs")]: {
                width: "90%",
            },
            [theme.breakpoints.up("sm")]: {
                width: "55%",
            },
            [theme.breakpoints.up("md")]: {
                width: "78%",
            },
            [theme.breakpoints.up("lg")]: {
                width: "100%",
                maxHeight: `calc(85vh - ${panelHeight}px)`
            },
            [theme.breakpoints.up(1921)]: {
                // Greater then Full HD: 1920 x 1080
                width: "100%",
                maxHeight: `calc(90vh - ${panelHeight}px)`
            }
        }
    }));

    if (isTableControlledByPlayer) {
        useStyles = makeStyles((theme) => ({
            ...baseStyles,
            historyPanel: {
                [theme.breakpoints.down("xs")]: {
                    width: "90%",
                },
                [theme.breakpoints.up("sm")]: {
                    width: "55%",
                },
                [theme.breakpoints.up("md")]: {
                    width: "78%",
                },
                [theme.breakpoints.up("lg")]: {
                    width: "100%",
                    // 118px := First 5 History Entries
                    maxHeight: "118px"
                },
                [theme.breakpoints.up(1921)]: {
                    // Greater then Full HD: 1920 x 1080
                    width: "100%",
                    maxHeight: `calc(90vh - ${panelHeight}px)`
                }
            }
        }));
    }

    const classes = useStyles();
    const intl = useIntl();
    const ref = useRef(null);


    const history = useMemo(() => {
        if (data) {
            let historyResult: VotingResult[] = [];
            let highestVotes = 0;
            let currentWinner = "";
            let unanimous = true;
            data.listP2GHistories.items.forEach((historyItem: any) => {
                for (let i = 0; i < historyItem.votingOptions.length; i++) {
                    if (historyItem.votesForOptions[i] > 0 && highestVotes > 0) {
                        unanimous = false;
                    }
                    if (historyItem.votesForOptions[i] > highestVotes) {
                        currentWinner = historyItem.votingOptions[i];
                        highestVotes = historyItem.votesForOptions[i];
                    } else if (historyItem.votesForOptions[i] === highestVotes) {
                        currentWinner = currentWinner + ", " + historyItem.votingOptions[i];
                    }
                }
                if (highestVotes === 0) {
                    currentWinner = intl.formatMessage({id: "text-history-result-no-voting"})
                    unanimous = false;
                }
                historyResult.unshift({
                    votingTitle: historyItem.votingTitle,
                    votingResult: currentWinner,
                    isHighlighted: unanimous,
                    votingID: historyItem.votingId,
                    type: historyItem.type
                });
                highestVotes = 0;
                currentWinner = "";
                unanimous = true;
            });

            return historyResult.sort((votingResult1, votingResult2) => {
                return parseInt(votingResult2.votingID) - parseInt(votingResult1.votingID);
            });
        } else return [];
    }, [data]);

    const deleteVote = async (votingId: string) => {
        controller.deleteVoteFromHistory(table.id, votingId).catch(err => logger.error(err));
    }

    const getDisplayForVotingType = (votingType: string) => {
        switch (votingType) {
            case "FIBONACCI":
                return (
                    <div data-testid="fibonacciIcon">
                        <img
                            src="/icons/fibonacci_03.png" alt="FIB" width="20"
                            height="20"/>
                    </div>);
            case "FREE_VOTE":
                return (<div data-testid="freeVoteIcon">
                    <img
                        src="/icons/free_votes_final.png" alt="VOT" width="20"
                        height="20"/>
                </div>);
            case "FREE_NUMBER":
                return (<div data-testid="freeNumberIcon">
                    <img
                        src="/icons/free_number_final.png" alt="NUM" width="20"
                        height="20"/>
                </div>);
            default:
                return "Unknown";
        }
    }

    const getTooltipTextForVotingType = (votingType: string) => {
        switch (votingType) {
            case "FIBONACCI":
                return intl.formatMessage({id: "text-tooltip-voting-history-item-fibonacci"});
            case "FREE_VOTE":
                return intl.formatMessage({id: "text-tooltip-voting-history-item-free-vote"});
            case "FREE_NUMBER":
                return intl.formatMessage({id: "text-tooltip-voting-history-item-free-number"});
            default:
                return intl.formatMessage({id: "text-tooltip-voting-history-item-unknown"});
        }
    }

    return (
        (history.length === 0 || !table.showHistory) ? (
            <></>
        ) : (
            <PokerPanel
                content={
                    [
                        <b>{intl.formatMessage({id: "text-voting-history"})}</b>,
                        <Grid
                            item
                            ref={ref}
                            style={{
                                overflowX: "hidden",
                                overflowY: "auto",
                                flexWrap: "nowrap",
                                margin: "0 auto",
                            }}
                            className={`${classes.root} ${classes.historyPanel}`}
                        >
                            {
                                history.map((l: VotingResult, idx: number) => {
                                    const result = l.votingResult.toString() === "" ? intl.formatMessage({id: "text-no-votes-error"}) : l.votingResult
                                    return (
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="flex-start"
                                            key={idx}
                                            style={{
                                                display: "flex", overflowY: "auto"
                                            }}
                                        >
                                            <Tooltip title={getTooltipTextForVotingType(l.type)}>
                                                <Grid
                                                    item
                                                    style={{
                                                        width: "10%",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        cursor: "pointer",
                                                        whiteSpace: "nowrap",
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {getDisplayForVotingType(l.type)}
                                                </Grid>
                                            </Tooltip>

                                            <Tooltip title={l.votingTitle}>
                                                <Grid
                                                    item
                                                    style={{
                                                        width: "40%",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        cursor: "pointer",
                                                        whiteSpace: "nowrap",
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {l.votingTitle}
                                                </Grid>
                                            </Tooltip>
                                            <Tooltip title={result}>
                                                <Grid
                                                    style={{
                                                        width: "40%",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        cursor: "pointer",
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {l.isHighlighted ? <b>{result}</b> : result}
                                                </Grid>
                                            </Tooltip>
                                            {
                                                isTableControlledByPlayer ? (
                                                    <Tooltip
                                                        title={intl.formatMessage({id: "text-tooltip-delete-voting-history-item"})}>
                                                        <HighlightOffIcon
                                                            onClick={() => deleteVote(l.votingID)}
                                                            style={{
                                                                cursor: "pointer"
                                                            }}
                                                        >
                                                        </HighlightOffIcon>
                                                    </Tooltip>
                                                ) : null
                                            }
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    ]
                }
                pokerTheme={pokerTheme}
            />
        )
    )
}
