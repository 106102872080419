import PokerTable from "../model/PokerTable";

export const getPlayedCardsObject = (table: PokerTable) => {
    let playedCards: number[];
    let playedCardsObject = {}
// @ts-ignore
    playedCards = table.voterPlayers.filter(p => typeof (p.playedCardValue) === "number")
        .map(p => p.playedCardValue)
    const playedCardsSet = new Set(playedCards);
    const isAllCardsTheSame = playedCardsSet.size === 1;
    playedCards.forEach(p => {
        // @ts-ignore
        playedCardsObject[p] = parseInt(playedCardsObject[p] + 1) || 1
    })
    return {playedCardsObject, isAllCardsTheSame}
}

export const getMostFrequentElementInAnObject = (obj: { [s: string]: number; }) => {
    // @ts-ignore
    return Object.entries(obj).reduce((a, v) => (v[1] >= a[1] ? v : a), [null, 0]);
}

export const getTimeNow = () => {
    const date = new Date();
    return ((date.getHours() < 10) ? "0" : "") + date.getHours() + ":" + ((date.getMinutes() < 10) ? "0" : "") + date.getMinutes();
}

export const getCognitoIdTokenFromCookies = ():string|undefined => {
    if(!!document.cookie){
        let allCookies = document.cookie?.split(";");
        allCookies = allCookies.filter(cookie => cookie.includes("idToken"));
        return allCookies[0]?.split("=").pop() as string;
    }
    return undefined;
}

export const getTableIDFromUrl = (): string | undefined => {
    const search = window.location.search
    let regex = new RegExp("\\?id=(.*)");
    let match = regex.exec(search);
    if (match === null || match.length === 0)
        return undefined;
    return match[1];
}
