import React from 'react'
import { Typography } from '@material-ui/core'

export const AccessDeniedPage = () => {
    return (
        <div>
            <Typography variant="h6">
                Sie haben keinen Zugriff auf diese Seite
            </Typography>
        </div>
    )
}
