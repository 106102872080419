const SUPPORTED_LANGUAGES: string[] = ["de", "en"]
const DEFAULT_LANGUAGE: string = "en"

export default class LanguageService{

    static getSupportedLanguages = () => {
        return SUPPORTED_LANGUAGES;
    }

    static getLanguage = () => {
        let languageList = navigator.languages;
        let language = languageList
            .map((country) => country.substring(0,2).toLowerCase())
            .find((country) => SUPPORTED_LANGUAGES.includes(country))
        return language === undefined ? DEFAULT_LANGUAGE : language
    }
}

