import {createStyles, makeStyles, Theme} from "@material-ui/core";
import React, {useLayoutEffect, useMemo, useState} from "react";
import {cardValueToString} from "../Cards/Card";
import FlipCard from "flip-card-react";
import {asCssRGBAString, PokerTheme} from "../../model/PokerTheme";
import {useRaf} from "react-use";
import {easing} from "ts-easing";
import {PokerCardValue} from "../../model/PokerCard";


// these differ from the entries in Players.tsx slightly for the players on the left and right
const playerPositions = [
    {left: 70.7, top: 10.9},
    {left: 60.3, top: 10.9},
    {left: 50, top: 10.9},
    {left: 39.7, top: 10.9},
    {left: 29.3, top: 10.9},
    {left: 14, top: 24.7},
    {left: 9, top: 50},
    {left: 14, top: 75.3},
    {left: 29.3, top: 89.1},
    {left: 39.7, top: 89.1},
    {left: 50, top: 89.1},
    {left: 60.3, top: 89.1},
    {left: 70.7, top: 89.1},
    {left: 86, top: 75.3},
    {left: 91, top: 50},
    {left: 86, top: 24.7},
]

const cardPositions = [
    {left: 70.7, top: 26},
    {left: 60.3, top: 26},
    {left: 50, top: 26},
    {left: 39.7, top: 26},
    {left: 29.3, top: 26},
    {left: 20.5, top: 33.7},
    {left: 17.1, top: 50},
    {left: 20.5, top: 66.3},
    {left: 29.3, top: 74},
    {left: 39.7, top: 74},
    {left: 50, top: 74},
    {left: 60.3, top: 74},
    {left: 70.7, top: 74},
    {left: 79.5, top: 66.3},
    {left: 82.9, top: 50},
    {left: 79.5, top: 33.7},
]

const getCardRotation = (i: number) => {
    switch (i) {
        case 5:
        case 13:
            return -45
        case 7:
        case 15:
            return 45;
        case 6:
        case 14:
            return 90;
        default:
            return 0;
    }
}

enum AnimationType {
    playCard,
    changeCard,
    playCardFrom,
    changeCardFrom,
    takeBack,
    none
}

const getAnimationType = (playedByIndex: number | null, prevValue: PokerCardValue | undefined, value: PokerCardValue | undefined) => {
    if (playedByIndex === null && prevValue === undefined && value !== undefined)
        return AnimationType.playCard;
    if (prevValue !== undefined && value === undefined)
        return AnimationType.takeBack;
    if (playedByIndex === null && prevValue !== undefined && value !== undefined)
        return AnimationType.changeCard;
    if (playedByIndex !== null && prevValue === undefined && value !== undefined)
        return AnimationType.playCardFrom;
    if (playedByIndex !== null && prevValue !== undefined && value !== undefined)
        return AnimationType.changeCardFrom;
    return AnimationType.none;
}

const getPlayCardStyle = (i: number, elapsed: number) => {
    const tween = easing.inOutCubic(elapsed);

    const left = playerPositions[i].left
        + (cardPositions[i].left - playerPositions[i].left) * tween;
    const top = playerPositions[i].top
        + (cardPositions[i].top - playerPositions[i].top) * tween;
    const rotation = getCardRotation(i);
    return {
        left: `${left}%`,
        top: `${top}%`,
        transform: `translate(-50%, -50%) rotate(${rotation}deg)`
    }
}

const getTakeBackStyle = (i: number, elapsed: number) => {
    const tween = easing.inOutCubic(elapsed);

    const left = cardPositions[i].left
        + (playerPositions[i].left - cardPositions[i].left) * tween;
    const top = cardPositions[i].top
        + (playerPositions[i].top - cardPositions[i].top) * tween;
    const rotation = getCardRotation(i);
    return {
        left: `${left}%`,
        top: `${top}%`,
        transform: `translate(-50%, -50%) rotate(${rotation}deg)`
    }
}

const clamp = (n: number, min: number, max: number): number => {
    return Math.min(max, Math.max(min, n));
}

const getChangeCardStyle = (i: number, elapsed: number) => {
    const part1Start = 0;
    const part1Length = 0.5;
    const part2Start = 0.5;
    const part2Length = 0.5;

    const elapsed1 = clamp((elapsed - part1Start) / part1Length, 0, 1);
    const elapsed2 = clamp((elapsed - part2Start) / part2Length, 0, 1);
    const tween1 = easing.inOutCubic(elapsed1)
    const tween2 = easing.inOutCubic(elapsed2)

    const left = cardPositions[i].left
        + (playerPositions[i].left - cardPositions[i].left) * tween1
        + (cardPositions[i].left - playerPositions[i].left) * tween2;
    const top = cardPositions[i].top
        + (playerPositions[i].top - cardPositions[i].top) * tween1
        + (cardPositions[i].top - playerPositions[i].top) * tween2;
    const rotation = getCardRotation(i);
    return {
        left: `${left}%`,
        top: `${top}%`,
        transform: `translate(-50%, -50%) rotate(${rotation}deg)`
    }
}

const getPlayCardFromStyle = (i: number, playedByIndex: number, elapsed: number) => {
    const part1Start = 0;
    const part1Length = 0.67;
    const part2Start = 0.67;
    const part2Length = 0.33;

    const elapsed1 = clamp((elapsed - part1Start) / part1Length, 0, 1);
    const elapsed2 = clamp((elapsed - part2Start) / part2Length, 0, 1);
    const tween1 = easing.elastic(elapsed1);
    const tween2 = easing.inOutCubic(elapsed2);

    const inProgress = elapsed > 0 && elapsed < 1;

    const left = cardPositions[playedByIndex].left
        + (cardPositions[i].left - cardPositions[playedByIndex].left) * tween2;
    const top = cardPositions[playedByIndex].top
        + (cardPositions[i].top - cardPositions[playedByIndex].top) * tween2;
    const rotation = getCardRotation(playedByIndex) - 180 + 180 * tween1 + (getCardRotation(i) - getCardRotation(playedByIndex)) * tween2;

    return {
        left: `${left}%`,
        top: `${top}%`,
        transform: `translate(-50%, -50%) rotate(${rotation}deg)`,
        zIndex: inProgress ? 1 : 0
    }
}

const getChangeCardFromStyle = (i: number, playedByIndex: number, elapsed: number) => {
    const part1Start = 0;
    const part1Length = 0.25;
    const part2Start = 0.25;
    const part2Length = 0.5;
    const part3Start = 0.75;
    const part3Length = 0.25;

    const elapsed1 = clamp((elapsed - part1Start) / part1Length, 0, 1);
    const elapsed2 = clamp((elapsed - part2Start) / part2Length, 0, 1);
    const elapsed3 = clamp((elapsed - part3Start) / part3Length, 0, 1);
    const tween1 = easing.inOutCubic(elapsed1);
    const tween2 = easing.elastic(elapsed2);
    const tween3 = easing.inOutCubic(elapsed3);

    const inProgress = elapsed > 0 && elapsed < 1;
    const phase1 = elapsed2 === 0;

    let left, top, rotation;
    if (phase1) {
        left = cardPositions[i].left
            + (playerPositions[i].left - cardPositions[i].left) * tween1;
        top = cardPositions[i].top
            + (playerPositions[i].top - cardPositions[i].top) * tween1;
        rotation = getCardRotation(i);
    } else {
        left = cardPositions[playedByIndex].left
            + (cardPositions[i].left - cardPositions[playedByIndex].left) * tween3;
        top = cardPositions[playedByIndex].top
            + (cardPositions[i].top - cardPositions[playedByIndex].top) * tween3;
        rotation = getCardRotation(playedByIndex) - 180 + 180 * tween2 + (getCardRotation(i) - getCardRotation(playedByIndex)) * tween3;
    }
    return {
        left: `${left}%`,
        top: `${top}%`,
        transform: `translate(-50%, -50%) rotate(${rotation}deg)`,
        zIndex: inProgress ? 1 : 0
    }
}

const getDefaultStyle = (i: number, hidden: boolean) => {
    return hidden ?
        {
            left: `${playerPositions[i].left}%`,
            top: `${playerPositions[i].top}%`,
            transform: `translate(-50%, -50%) rotate(${getCardRotation(i)}deg)`
        } : {
            left: `${cardPositions[i].left}%`,
            top: `${cardPositions[i].top}%`,
            transform: `translate(-50%, -50%) rotate(${getCardRotation(i)}deg)`
        }
}

const getAnimationDuration = (type: AnimationType) => {
    switch (type) {
        case AnimationType.playCard:
            return 500;
        case AnimationType.takeBack:
            return 500;
        case AnimationType.changeCard:
            return 1000;
        case AnimationType.playCardFrom:
            return 1500;
        case AnimationType.changeCardFrom:
            return 2000;
        default:
            return 0;
    }
}

const getHideDelay = (type: AnimationType) => {
    switch (type) {
        case AnimationType.takeBack:
            return 500;
        default:
            return 0;
    }
}

const getSetValueDelay = (type: AnimationType) => {
    switch (type) {
        case AnimationType.takeBack:
            return 500;
        case AnimationType.changeCard:
            return 500;
        case AnimationType.changeCardFrom:
            return 500;
        default:
            return 0;
    }
}

const useAnimation = (i: number, propValue: PokerCardValue | undefined, playedByIndex: number | null): [PokerCardValue | undefined, boolean, React.CSSProperties] => {
    const [value, setValue] = useState(propValue);
    const [animationDuration, setAnimationDuration] = useState(0);
    const [animationType, setAnimationType] = useState(AnimationType.none);
    const [hidden, setHidden] = useState(propValue === undefined);
    const elapsed = useRaf(animationDuration);

    useLayoutEffect(() => {
        const prevValue = value;
        const newValue = propValue;
        if (prevValue !== newValue) {
            const animationType = getAnimationType(playedByIndex, prevValue, newValue);
            // useRaf will only reset when duration is changed
            // to force a reset every time, flip the last bit of the duration
            // on every update
            setAnimationDuration(duration => duration % 2 + 1 + getAnimationDuration(animationType));
            // small timeout to wait for setAnimationDuration to take effect
            setTimeout(() => setAnimationType(animationType), 10);
            // for some animations value and hidden status dont get updated immediately
            // but at some point in time during the animation
            setTimeout(() => setHidden(newValue === undefined), getHideDelay(animationType));
            setTimeout(() => setValue(newValue), getSetValueDelay(animationType));
        }
    }, [propValue, value, playedByIndex])

    const style = (() => {
        switch (animationType) {
            case AnimationType.playCard:
                return getPlayCardStyle(i, elapsed);
            case AnimationType.takeBack:
                return getTakeBackStyle(i, elapsed);
            case AnimationType.changeCard:
                return getChangeCardStyle(i, elapsed);
            case AnimationType.playCardFrom:
                return playedByIndex !== null ? getPlayCardFromStyle(i, playedByIndex, elapsed) : getPlayCardStyle(i, elapsed);
            case AnimationType.changeCardFrom:
                return playedByIndex !== null ? getChangeCardFromStyle(i, playedByIndex, elapsed) : getChangeCardStyle(i, elapsed);
            default:
                return getDefaultStyle(i, hidden);
        }
    })();

    return [value, hidden, style];
}

interface CardStyleProps {
    factor: number,
    renderValueLength: number
    background: string,
    foreground: string,
    textColor: string
}

const useCardStyles = makeStyles<Theme, CardStyleProps>(() =>
    createStyles({
        clickable: {
            cursor: "pointer",
            "&:hover": {
                "box-shadow": "0px 0px 3px 3px red",
            }
        },
        container: {
            transformOrigin: "center center",
            position: "absolute",
            transform: "translate(-50%, -50%)"
        },
        cardRevealed: ({factor, renderValueLength, foreground, textColor}) => {
            // since we need to be pixel perfect use old css for
            // values with <= 2 chars
            if (renderValueLength <= 2) {
                return ({
                    width: factor * 56,
                    height: factor * 110,
                    borderRadius: factor * 16,
                    // margin: factor * 5,
                    padding: `${0}px ${factor * 4}px`,
                    display: "flex",
                    color: textColor,
                    border: "1px solid #b5b5b5",
                    boxShadow: "0 0 3px 0 rgba(0,0,0,0.3)",
                    backgroundColor: foreground,
                })
            } else {
                return ({
                    width: factor * 56,
                    height: factor * 110,
                    borderRadius: factor * 16,
                    // margin: factor * 5,
                    padding: `${0}px ${factor * 4}px`,
                    color: textColor,
                    display: "flex",
                    border: "1px solid #b5b5b5",
                    boxShadow: "0 0 3px 0 rgba(0,0,0,0.3)",
                    backgroundColor: foreground,
                    flexDirection: "column",
                    justifyContent: "space-between",
                })
            }
        },
        cardHidden: ({factor, background}) => {
            return ({
                width: factor * 56,
                height: factor * 110,
                borderRadius: factor * 16,
                // margin: factor * 5,
                padding: `${0}px ${factor * 4}px`,
                display: "flex",
                alignItems: "center",
                border: "1px solid #b5b5b5",
                boxShadow: "0 0 3px 0 rgba(0,0,0,0.3)",
                backgroundColor: background
            })
        },
        br: ({factor, renderValueLength}) => {
            // 4 chars fit on the card
            // so scale to that length,
            // but never more than 100%
            const fontFactor = Math.min(1, 4 / renderValueLength);
            if (renderValueLength <= 2) {
                return ({
                    fontSize: factor * 28,
                    display: "flex",
                    flexGrow: 1,
                    flexFlow: "column nowrap",
                    alignItems: "flex-start",
                    transform: "translateX(-3px) rotate(-180deg)"
                })
            } else {
                return ({
                    alignSelf: "flex-end",
                    fontSize: factor * 28 * fontFactor,
                    transform: "rotate(-180deg)"
                })
            }
        },
        tl: ({factor, renderValueLength}) => {
            const fontFactor = Math.min(1, 4 / renderValueLength);
            if (renderValueLength <= 2) {
                return ({
                    fontSize: factor * 28,
                    display: "flex",
                    flexGrow: 1,
                    flexFlow: "column nowrap",
                    alignItems: "flex-start",
                })
            } else {
                return ({
                    alignSelf: "flex-start",
                    fontSize: factor * 28 * fontFactor,
                })
            }
        }
    })
);

export interface PlayerCardProps {
    factor: number;
    value: PokerCardValue | undefined;
    onClick: () => void;
    revealed: boolean;
    id: string;
    clickable: boolean;
    index: number;
    playedByIndex: number | null;
    pokerTheme: PokerTheme;
}

export const PlayerCard: React.FC<PlayerCardProps> = (props) => {
    const {
        factor,
        value: propValue,
        onClick,
        revealed,
        clickable,
        index,
        playedByIndex,
        pokerTheme
    } = props;

    const [value, hidden, style] = useAnimation(index, propValue, playedByIndex);

    const classes = useCardStyles({
        factor: factor * 0.8,
        renderValueLength: cardValueToString(value).length,
        foreground: asCssRGBAString(pokerTheme.card.backgroundFront),
        background: asCssRGBAString(pokerTheme.card.backgroundBack),
        textColor: asCssRGBAString(pokerTheme.card.text)
    });

    const hiddenCard = useMemo(() => {
        return (
            <div
                className={`${classes.cardHidden}`}
            />)
    }, [classes])

    const openedCard = useMemo(() => {
        return (
            <div
                className={clickable ? `${classes.cardRevealed} ${classes.clickable}` : `${classes.cardRevealed}`}
                onClick={onClick}
                id={`cards-player_card-${index}`}
            >
                <div className={classes.tl}>
                    <div className={"card-value"} id={`card-text-top`}>{revealed ? cardValueToString(value) : ""}</div>
                </div>
                <div className={classes.br}>
                    <div className={"card-value"}

                         id={`card-text-bottom`}>{revealed ? cardValueToString(value) : ""}</div>
                </div>
            </div>
        )
    }, [value, onClick, classes, clickable, index,revealed])

    const flipCard = useMemo(() => {
        return (
            <FlipCard isFlipped={revealed}
                      front={hiddenCard}
                      back={openedCard}
                      speed={0.8}
            />
        )
    }, [hiddenCard, openedCard, revealed])

    return (
        <div className={classes.container} style={style} hidden={hidden}>
            {flipCard}
        </div>
    )
}
