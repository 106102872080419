import PokerTable from "../../model/PokerTable";
import {asCssRGBAString, PokerTheme} from "../../model/PokerTheme";
import React, {useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {PokerPanel} from "./PokerPanel";
import {TextField} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import MainController from "../../controller/control/MainController";

interface Props {
    table: PokerTable
    pokerTheme: PokerTheme
    title: string
    votingTitleHandler: (s: string) => void
    controller: MainController
}

export const TitlePanel: React.FC<Props> = (props) => {
    const {table, pokerTheme, votingTitleHandler, controller} = props
    const [votingTitle, setVotingTitle] = useState<string>("");
    const intl = useIntl();
    const textFieldTitleRef = useRef<HTMLInputElement>(null);
    const TitleTextField = withStyles({
        root: {
            marginTop: "5px",
            '& label.Mui-focused': {
                color: asCssRGBAString(pokerTheme.panel.text),
                fontSize: 15,
                top: "-3px"
            },
            '& label': {
                fontSize: 15,
                color: asCssRGBAString(pokerTheme.panel.text),
            },
            '& .MuiOutlinedInput-root': {
                color: asCssRGBAString(pokerTheme.panel.text),
                '& label': {
                    fontSize: "10px",

                },
                '& fieldset': {
                    borderColor: asCssRGBAString(pokerTheme.panel.text),
                    marginBottom: "5px"
                },
                '&.Mui-focused fieldset': {
                    borderColor: asCssRGBAString(pokerTheme.panel.text)
                },
            },
        },
    })(TextField);
    useEffect(() => {
        if (textFieldTitleRef && textFieldTitleRef.current) {
            textFieldTitleRef.current.value !== "" && textFieldTitleRef.current.focus();
        }

    }, [votingTitle])
    useEffect(() => {
        const localVotingTitle = JSON.parse(localStorage.getItem("localVotingTitleSettings") as string )
        localVotingTitle!=null?
            setVotingTitle(localVotingTitle.localVotingTitle):
            setVotingTitle("")
    }, [table])
    return (
        <PokerPanel content={
            [
                <TitleTextField
                    inputRef={textFieldTitleRef}
                    label={intl.formatMessage({id: "label-text-field-voting-title"})}
                    fullWidth={true}
                    variant={"outlined"}
                    value={votingTitle}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setVotingTitle(e.target.value)
                        votingTitleHandler(e.target.value)
                    }}
                    onKeyPress={async (click: React.KeyboardEvent) => {
                        if (click.charCode === 13 && table.state === 1) {
                            await controller.updateVotingTitle(table.id, votingTitle)
                        }
                    }}
                    onBlur={async () => {
                        if (table.state === 1) {
                            await controller.updateVotingTitle(table.id, votingTitle)
                        }
                    }
                    }
                />
            ]
        } pokerTheme={pokerTheme}/>

    )
}