import React, {useMemo} from "react";
import {FormControl, NativeSelect} from "@material-ui/core";
// @ts-ignore
import {withStyles} from "@material-ui/core/styles";
import {useIntl} from "react-intl";
import LanguageService from "../../service/LanguageService";

interface Props {
    selectedLanguage: string,
    setSelectedLanguage: (selectedLanguage: string) => void
}

const DropdownMenu = withStyles({
    root: {
        "& .MuiInput-underline:after": {
            borderBottomColor: "black"
        }
    }
})(FormControl)

const LanguageSelection: React.FC<Props> = (props: Props) => {
    const intl = useIntl();
    const getOptions = useMemo(() => {
        const getLocalizedLanguage = (languageId: string) => {
            return intl.formatMessage({id: `language_selection_component-text-${languageId}`})
        }
        let languageList = LanguageService.getSupportedLanguages()
        let orderedLanguages = [
            ...languageList.filter((language) => language === props.selectedLanguage),
            ...languageList.filter((language) => language !== props.selectedLanguage)
        ]
        return (
            orderedLanguages.map((language) => {
                return (
                    <option key={language} value={language} disabled={language === props.selectedLanguage}>
                        {getLocalizedLanguage(language)}
                    </option>
            )})
        )
    }, [props.selectedLanguage, intl])

    const handleSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
        props.setSelectedLanguage(event.target.value as string)
    }

    return (
        <DropdownMenu fullWidth={true}>
            <NativeSelect
                id={"language_selection_component-dropdown-select_language"}
                value={props.selectedLanguage}
                onChange={handleSelection}>
                {getOptions}
            </NativeSelect>
        </DropdownMenu>
    )
}

export default LanguageSelection