import NetworkInterface from "./NetworkInterface";
import {TableState} from "../../model/PokerTable";
import PokerPlayer from "../../model/PokerPlayer";
import ServerNetworkInterface from "./serverbackend/ServerNetworkInterface";
import {NetworkEventListener} from "./NetworkEventListener";
import PlayerManager from "../control/PlayerManager";
import {PokerCardValue} from "../../model/PokerCard";
import {ThemeDefinition, ThemeList, ThemeSource} from "../../service/ThemeService";
import ThemeStorageInterface from "./themeStorage/ThemeStorageInterface";
import S3ThemeStorageInterface from "./themeStorage/S3ThemeStorageInterface";
import StorageManager, {ProfileEntry} from "../control/StorageManager";

export default class NetworkAdapter extends NetworkInterface {

    private networkInterface: NetworkInterface
    private readonly themeStorage: ThemeStorageInterface

    constructor(playerManager: PlayerManager, storageManager: StorageManager) {
        super(playerManager);
        this.networkInterface = new ServerNetworkInterface(playerManager);
        this.themeStorage = new S3ThemeStorageInterface(storageManager);
    }

    createTable(tableName: string): Promise<string> {
        return this.networkInterface.createTable(tableName);
    }

    async checkLogin(tableID: string | undefined): Promise<void> {
        return this.networkInterface.checkLogin(tableID);
    }

    setNetworkEventListener(listener: NetworkEventListener) {
        this.networkInterface.setNetworkEventListener(listener);
    }

    joinTable(user: PokerPlayer, tableID: string): Promise<void> {
        return this.networkInterface.joinTable(user, tableID);
    }

    playCard(tableID: string, value: PokerCardValue, playedBy: string | null): Promise<void> {
        return this.networkInterface.playCard(tableID, value, playedBy);
    }

    updateTableState(tableID: string, state: TableState): Promise<void> {
        return this.networkInterface.updateTableState(tableID, state);
    }

    deleteVoteFromHistory(tableID: string, votingID: string): Promise<void> {
        return this.networkInterface.deleteVoteFromHistory(tableID, votingID);
    }

    revealCards(tableID: string): Promise<void> {
        return this.networkInterface.revealCards(tableID);
    }

    playReminderSound(tableID: string): Promise<void> {
        return this.networkInterface.playReminderSound(tableID);
    }

    switchPlayerRole(tableId: string, player: PokerPlayer): Promise<void> {
        return this.networkInterface.switchPlayerRole(tableId, player);
    }

    switchPlayerRoleByAdmin(tableId: string, player: PokerPlayer): Promise<void> {
        return this.networkInterface.switchPlayerRoleByAdmin(tableId, player);
    }

    startFreeVote(tableID: string, votingOptions: string[], indices: string[]): Promise<void> {
        return this.networkInterface.startFreeVote(tableID, votingOptions, indices);
    }

    startFreeNumberVote(tableId: string) {
        return this.networkInterface.startFreeNumberVote(tableId);
    }

    toggleChangeOpenCard(tableID: string): Promise<void> {
        return this.networkInterface.toggleChangeOpenCard(tableID);
    }

    toggleChangeRandomizeFreeVote(tableID: string) {
        return this.networkInterface.toggleChangeRandomizeFreeVote(tableID);
    }

    toggleShowHistory(tableID: string): Promise<void> {
        return this.networkInterface.toggleShowHistory(tableID);
    }

    async uploadTheme(themeSource: ThemeSource, file: File): Promise<void> {
        return this.themeStorage.uploadTheme(themeSource, file)
    }

    async deleteTheme(themeSource: ThemeSource): Promise<void> {
        return this.themeStorage.deleteTheme(themeSource)
    }

    async loadCustomThemes(tableID: string, playerID: string): Promise<Map<string, ThemeDefinition>> {
        return this.themeStorage.loadCustomThemes(tableID, playerID);
    }

    async loadUserThemes(cognitoID: string): Promise<Map<string, ThemeDefinition>> {
        return this.themeStorage.loadUserThemes(cognitoID);
    }

    async loadCustomAndUserThemes(tableID: string, playerID: string, cognitoID: string): Promise<Map<string, ThemeDefinition>> {
        return this.themeStorage.loadCustomAndUserThemes(tableID, playerID, cognitoID);
    }

    async getThemeDefinition(source: ThemeSource): Promise<ThemeDefinition> {
        return this.themeStorage.getThemeDefinition(source);
    }

    async loadPublicThemes(themeListJson: string): Promise<ThemeList> {
        return this.themeStorage.loadPublicThemes(themeListJson);
    }

    async uploadAvatar(tableID: string, playerID: string, cognitoID: string | undefined, file: File): Promise<void> {
        return this.networkInterface.uploadAvatar(tableID, playerID, cognitoID, file)
    }

    async deleteAvatar(tableID: string, playerID: string, cognitoID: string | undefined) {
        return this.networkInterface.deleteAvatar(tableID, playerID, cognitoID);
    }

    updateAvatar(tableId: string, playerId: string) {
        return this.networkInterface.updateAvatar(tableId, playerId)
    }

    async updateVotingTitle(tableID: string, title: string): Promise<void> {
        return this.networkInterface.updateVotingTitle(tableID, title)

    }

    updatePlayerProfileToDB(profile: ProfileEntry, cognitoID: string, cognitoIdToken: string): Promise<void> {
        return this.networkInterface.updatePlayerProfileToDB(profile, cognitoID, cognitoIdToken);
    }

    getPlayerProfileFromDB(cognitoId: string, cognitoIdToken: string) {
        return this.networkInterface.getPlayerProfileFromDB(cognitoId, cognitoIdToken);
    }


}
