import React from "react";
import PokerTable from "../../model/PokerTable";
import {Typography} from "@material-ui/core";
import {PokerTheme} from "../../model/PokerTheme";
import {PokerPanel} from "./PokerPanel";
import {cardValueAsNumber} from "../Cards/Card";
import {FormattedMessage} from "react-intl";

interface Props {
    table: PokerTable,
    pokerTheme: PokerTheme
}

function convertNumberToString(num: number) {
    if (isNaN(num) || num < 0 || num > 1000) {
        return "-"
    }
    return num.toString().replace(".", ",")
}

export const FreeNumberVoteResultsPanel: React.FC<Props> = (props) => {
    const {table, pokerTheme} = props;
    let calcInterquartileRange = require('compute-iqr')

    let allPlayedCards = table.getCardValuesWithCount()
    let playedCardList:number[] = []
    for (let key of allPlayedCards.keys()) {
        for (let i = 0; i < allPlayedCards.get(key)!!; i++) {
            playedCardList.push(cardValueAsNumber(key))
        }
    }
    playedCardList.sort((a, b) => a - b)
    let median
    let medianIndex = playedCardList.length / 2
    if (playedCardList.length % 2 === 0) {
        let secondMedianIndex = playedCardList.length / 2 - 1
        median = Math.round(1000 * (playedCardList[medianIndex] + playedCardList[secondMedianIndex]) / 2) / 1000
    } else {
        median = playedCardList[Math.floor(medianIndex)]
    }
    let arithmeticMean = playedCardList.reduce((a, b) => a + b, 0) / playedCardList.length

    const roundAndToString = (value: number):string => {
        let roundedValue = value.toFixed(2).replace(".", ",")

        if(roundedValue.endsWith("00")) roundedValue = roundedValue.substring(0, roundedValue.indexOf(","))
        else if( roundedValue.endsWith("0")) roundedValue = roundedValue.substring(0, roundedValue.indexOf(",")+2)
        return roundedValue
    }

    let smallestNumberPlayed = Math.min(...Array.from(allPlayedCards.keys()).map(cardValueAsNumber))
    let biggestNumberPlayed = Math.max(...Array.from(allPlayedCards.keys()).map(cardValueAsNumber))

    const calcMeanAbsoluteDeviation = ():number => {
        let helper = 0
        playedCardList.forEach(value => helper += Math.abs(arithmeticMean-value))
        helper /= playedCardList.length
        return helper/100
    }

    const stdUncorrected = (array: number[]): number => {
        const n = array.length;
        const mean = array.reduce((a, b) => a + b) / n;

        return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n);
    }

    return (
        <PokerPanel content={[
            <>
                <h2>
                    <Typography variant={"h6"}><b><FormattedMessage id={"free_number_results_title"}/></b></Typography>
                </h2>
                <FormattedMessage id={"free_number_results_median"}/>: {playedCardList.length !== 0? roundAndToString(median / 100): "-"} <br/>
                <FormattedMessage id={"free_number_results_arithmetic_mean"}/>: {playedCardList.length !== 0? roundAndToString(arithmeticMean / 100): "-"} <br/>
                <FormattedMessage id={"free_number_results_smallest_number"}/>: {convertNumberToString(smallestNumberPlayed / 100)} <br/>
                <FormattedMessage id={"free_number_results_biggest_number"}/>: {convertNumberToString(biggestNumberPlayed / 100)} <br/>
                <FormattedMessage id={"free_number_results_interquartile_range"}/>: {playedCardList.length !== 0? roundAndToString(calcInterquartileRange(playedCardList)/100): "-"} <br/>
                <FormattedMessage id={"free_number_results_mean_absolute_deviation"}/>: {playedCardList.length !== 0? roundAndToString(calcMeanAbsoluteDeviation()): "-"} <br/>
                <FormattedMessage id={"free_number_results_standard_deviation"}/>: {playedCardList.length > 0? roundAndToString(stdUncorrected(playedCardList)/100): "-"} <br/>
            </>
        ]} pokerTheme={pokerTheme}/>
    )
}

export default FreeNumberVoteResultsPanel