import {useQuery} from "@apollo/client";
import {GET_COMPLETE_HISTORY} from "../graphql/queries";
import {useEffect} from "react";
import {SUB_TO_DELETE_VOTES, SUB_TO_NEW_VOTES} from "../graphql/subscriptions";

export function useHistory(tableId: string): any {
    const {data, subscribeToMore} = useQuery(
        GET_COMPLETE_HISTORY,
        {variables: {tableId: tableId}}
    )

    useEffect(() => {
        subscribeToMore({
            document: SUB_TO_NEW_VOTES,
            variables: {tableId: tableId},
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const newHistory = subscriptionData.data.onAddVotingToTableHistory;
                return Object.assign({}, prev, {
                    listP2GHistories: {
                        items: [...prev.listP2GHistories.items, newHistory],
                        nextToken: null
                    }
                })
            },
            onError: (error: any) => console.error(error)
        });
        subscribeToMore({
            document: SUB_TO_DELETE_VOTES,
            variables: {tableId: tableId},
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const deleteVotingId = subscriptionData.data.onDeleteP2GHistory.votingId;
                return Object.assign({}, prev, {
                    listP2GHistories: {
                        items: prev.listP2GHistories.items.filter((item: any) => item.votingId !== deleteVotingId),
                        nextToken: null
                    }
                })
            },
            onError: (error: any) => console.error(error)
        });
    }, []);

    if (data) {
        return data;
    } else {
        return undefined;
    }
}